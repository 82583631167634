import { createSlice } from "@reduxjs/toolkit";

const noticeSlice = createSlice({
	name: 'notice',
	initialState: {
		noticeType: null,
		noticeHeader: null,
		noticeMessage: null,
		noticeConfirmBtn: 'Got it',
		notice: null,
		cancelBtn: null,
		sentFromNotice: false
	},
	reducers: {
		setNotice: (state, action) => {
			state.noticeType = action.payload.noticeType;
			state.noticeHeader = action.payload.noticeHeader;
			state.noticeMessage = action.payload.noticeMessage;
			state.noticeConfirmBtn = action.payload.noticeConfirmBtn;
			state.notice = action.payload.notice;
			state.cancelBtn = action.payload.cancelBtn || null;
		},
		cleanNotice: (state) => {
			state.noticeType = null;
			state.noticeHeader = null;
			state.noticeMessage = null;
			state.noticeConfirmBtn = 'Got it';
			state.notice = null;
		},
		setSentFromNotice: (state, action) => {
			state.sentFromNotice = action.payload;
		}
	}
});

export const { setNotice, cleanNotice, setSentFromNotice } = noticeSlice.actions;
export default noticeSlice.reducer;