import { toast } from "react-toastify";

export class VideoDB {
	static async addVideo(db, videoID, blob) {
		try {
			const tx = db.transaction('videos', 'readwrite');
			const store = tx.objectStore('videos');
			await store.put(blob, videoID);
			await tx.done;
		} catch (e) {
			toast.error(e.message);
			throw Error(e.message);
		}
	}

	static async getVideo(db, videoID) {
		try {
			const video = await db.get('videos', videoID);
			if (!video) {
				throw new Error(`Video with id ${videoID} not found`);
			}
			return video;
		} catch (e) {
			toast.error(e.message);
			throw Error(e.message);
		}
	}

	static async getAllVideos(db) {
		try {
			return await db.getAll('videos');
		} catch (e) {
			toast.error(e.message);
			throw Error(e.message);
		}
	}

	static async getListVideos(db) {
		try {
			const keys = await db.getAllKeys('videos');
			return keys.join(',');
		} catch (e) {
			toast.error(e.message);
			throw Error(e.message);
		}
	}

	static async removeVideo(db, id) {
		try {
			const tx = db.transaction('videos', 'readwrite');
			const store = tx.objectStore('videos');
			await store.delete(id);
			await tx.done;
			return 'success';
		} catch (e) {
			toast.error(e.message);
			throw Error(e.message);
		}
	}
}

//export class VideoDB {
//	static async addVideo(db, videoID, blob) {
//		try {
//			const tx = await db.transaction('videos', 'readwrite');
//			const store = await tx.objectStore('videos');
//			await store.put(blob, videoID);
//			await tx.done;
//		} catch (e) {
//			toast.error(e.message)
//			throw Error(e.message)
//		}

//	}
//	static async getVideo(db, videoID) {
//		let res
//		try {
//			res = await db.get('videos', videoID).then(data => data)
//		} catch (e) {
//			toast.error(e.message)
//			throw Error(e.message)
//			//res = new Error(e)
//		}
//		return res
//	}
//	static async getAllVideos(db) {
//		let res
//		try {
//			res = await db.getAll('videos')
//		} catch (e) {
//			toast.error(e.message)
//			throw Error(e.message)
//			//res = new Error(e)
//		}
//		return res
//	}
//	static async getListVideos(db) {
//		let res
//		try {
//			res = await db.getAllKeys('videos').then(data => data.join(','))
//		} catch (e) {
//			toast.error(e.message)
//			//res = new Error(e)
//		}
//		return res
//	}
//	static async removeVideo(db, id) {
//		try {
//			const tx = await db.transaction('videos', 'readwrite');
//			const store = await tx.objectStore('videos');
//			await store.delete(id);
//			await tx.done;
//			return 'success'
//		} catch (e) {
//			toast.error(e.message)
//			throw Error(e.message)
//		}
//	}
//}