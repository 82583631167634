
import React, { useEffect, useState } from 'react';
import Joyride, { CallBackProps, ACTIONS, EVENTS, ORIGIN, STATUS } from 'react-joyride';
import TourTooltipe from './guidedTour/TourTooltipe';

const steps = [
	{
		target: '#library',
		title: 'Content library',
		content: 'Begin training with our VR modules	or manage your own 360° videos.',
		placement: 'right',
		disableBeacon: true
	},
	{
		target: '#team',
		title: 'Team management',
		content: 'Easily handle users, teams, and devices from one centralized location',
		placement: 'right',
	},
	{
		target: '#insights',
		title: 'Insights',
		content: 'Explore results, boost employee productivity, and track/report seamlessly.',
		placement: 'right',
	},
	{
		target: '#platform',
		title: 'Download platform VR app',
		content: 'Get started with our free VR platform app to begin your training.',
		placement: 'right',
	}
	// Добавьте другие шаги...
];

const GuidedTour = () => {
	const [run, setRun] = useState(false);
	const [stepIndex, setStepIndex] = useState(0);

	const onSkipGuide = () => {
		// You need to set our running state to false, so we can restart if we click start again.
		const urlString = window.location.href;
		const url = new URL(urlString);

		// Получаем объект с параметрами
		const params = new URLSearchParams(url.search);

		// Удаляем параметр по его имени
		const paramNameToRemove = "tour";
		params.delete(paramNameToRemove);

		// Обновляем URL с новыми параметрами
		const newUrl = `${url.origin}${url.pathname}${params.toString() ? '?' + params.toString() : ''}`;

		// Используем новый URL или перенаправляем на него
		window.history.replaceState({}, "", newUrl)
	}

	const handleJoyrideCallback = (data) => {
		const { action, index, origin, status, type } = data;
		
		if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
			onSkipGuide()
		}

		if(type === 'beacon') {
			onSkipGuide()
		}

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
		} else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			onSkipGuide()
			setRun(false);
		}
	};

	const startTour = () => {
		setRun(true);
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		startTour();
		return () => {
			document.body.style.overflow = 'visible'
			setRun(false);
		}
	}, [])


	return (
		<>
			<Joyride
				callback={handleJoyrideCallback}
				run={run}
				stepIndex={stepIndex}
				steps={steps}
				tooltipComponent={TourTooltipe}
				continuous={true}
				scrollToFirstStep={true}
				showProgress={true}
				showSkipButton={true}
				styles={{
					options: {
						zIndex: 10000,
					},
				}}
				scrollDuration={2000}
			/>
		</>
	);
};

export default GuidedTour