import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userInfoReducer from './slices/UserInfoSlice'
import loginReducer, { logout } from './slices/LoginSlice'
import invitesReducer from './slices/InvitesSlice'
import userSettingsReducer from './slices/UserSettingsSlice'
import usersReducer from './slices/UsersSlice'
import companyInfoReducer from './slices/CompanyInfoSlice'
import teamsReducer from './slices/TeamsSlice'
import teamSettingsReducer from './slices/TeamSettingsSlice'
import videosReducer from './slices/VideosSlice'
import downloadedVideosReducer from './slices/DownloadedVideosSlice'
import socketRoomVideosReducer from './slices/SocketRoomVideosSlice'
import socketRoomUsersReducer from './slices/SocketRoomUsersSlice'
import classroomsReducer from './slices/ClassroomsSlice'
import vrReducer from './slices/vrSlice'
import contentAccessReducer from './slices/AccessContentSlice'
import devicesReducer from './slices/DevicesSlice'
import uploadVideos from './slices/UploadVideosSlice'
import statisticUsers from './slices/StatisticUsersSlice'
import statisticVideos from './slices/StatisticVideosSlice'
import statisticVR from './slices/StatisticVRSlice'
import statisticOverview from './slices/StatisticOverviewSlice'
import statisticPersonal from './slices/StatisticPersonalOverviewSlice'
import statisticPersonalTraining from './slices/StatisticPersonalTrainingSlice'
import sessionDetails from './slices/SessionTrainingDetailsSlice'
import subscription from './slices/SubscriptionSlice'
import notice from './slices/NoticeSlice'
import purchaseNotice from './slices/PurchaseNoticeSlice'
import statisticVRsessions from './slices/StatisticVRSessionsSlice'
import personalVRStatistic from './slices/PersonalVRStatisticSlice'
import personalVRSessionsStatistic from './slices/PersonalVRSessionsStatisticSlice'
import vrSessionsStatisticByUserSlice from './slices/VRSessionsStatisticByUserSlice'


const rootReducer = (state, action) => {
	if (action.type === logout.type) {
		// Return the initial state for all slices when logout action is dispatched
		return combinedReducer(undefined, action);
	}
	// For all other actions, use the regular reducer
	return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
	userInfo: userInfoReducer,
	login: loginReducer,
	settings: userSettingsReducer,
	invites: invitesReducer,
	users: usersReducer,
	company: companyInfoReducer,
	teams: teamsReducer,
	teamSettings: teamSettingsReducer,
	videos: videosReducer,
	downloadedVideos: downloadedVideosReducer,
	socketRoomVideos: socketRoomVideosReducer,
	socketRoomUsers: socketRoomUsersReducer,
	classrooms: classroomsReducer,
	vr: vrReducer,
	contentAccess: contentAccessReducer,
	devices: devicesReducer,
	uploadVideos: uploadVideos,
	statisticUsers: statisticUsers,
	statisticVideos: statisticVideos,
	statisticVR: statisticVR,
	statisticOverview,
	statisticPersonal,
	statisticPersonalTraining,
	sessionDetails,
	subscription,
	notice,
	purchaseNotice,
	statisticVRsessions,
	personalVRStatistic,
	personalVRSessionsStatistic,
	vrSessionsStatisticByUserSlice
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== 'production',
})
