import axios from "axios";


export class StatisticVRService {
	//острова
	static getAvgDurationOfTrainingSession(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/average/vr/training/time/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getPercentCompletedToUncompletedTrainings(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/completion/rate/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getTotalVRTrainingSessions(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/total/vr/training/sessions/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	//new chart 
	static getTrainingSessions365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/training/session/count/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	//table 

	static getTrainingSessionsTable(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/vr/training/data/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	// session details 

	static getTrainingSessionDetails(token, trainingSessionId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/training/session/details/${trainingSessionId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
			}
}