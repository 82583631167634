import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { StatisticVideosService } from "../../services/StatisticVideosServices";
import { newPrepareDataForChartByDays, prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from "../../utils/DataForStatistic";


export const getAvgViewingTimeVideo = createAsyncThunk(
	'statisticVideos/getAvgViewingTimeVideo',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVideosService.getAvgViewingTimeVideo(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getComplitionViewingVideoRate = createAsyncThunk(
	'statisticVideos/getComplitionViewingVideoRate',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVideosService.getComplitionViewingVideoRate(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTotalCountVideoViewed = createAsyncThunk(
	'statisticVideos/getTotalCountVideoViewed',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVideosService.getTotalCountVideoViewed(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)


export const getVideosStatistic365 = createAsyncThunk(
	'statisticVideos/getVideosStatistic365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVideosService.getVideosStatistic365(token, comID);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVideosTable = createAsyncThunk(
	'statisticVideos/getVideosTable',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVideosService.getVideosData(token, comID);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendVideoStart = createAsyncThunk(
	'statisticVideos/sendVideoStart',
	async (videoId, { rejectWithValue, getState }) => {
		const token = localStorage.getItem('token');
		const userId = localStorage.getItem('id');
		try {
			const response = await StatisticVideosService.sendVideoStart(token, userId, videoId);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendVideoEnd = createAsyncThunk(
	'statisticVideos/sendVideoEnd',
	async (data, { rejectWithValue, getState }) => {
		const token = localStorage.getItem('token');
		const userId = localStorage.getItem('id');
		const { videoId, watchedVideo } = data;
		let videoSessionId = getState().statisticVideos.currentSessions[videoId];
		if (!videoSessionId) return;
		try {
			const response = await StatisticVideosService.sendVideoEnd(token, userId, videoSessionId, watchedVideo);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)



const statisticVideosAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const statisticVideosSlice = createSlice({
	name: 'statisticVideos',
	initialState: statisticVideosAdapter.getInitialState({
		loading: false,
		error: null,
		avgViewingTimeVideo: null,
		totalCountVideoViewed: null,
		viewingVideoRate: null,
		videosTable: null,
		currentSessions: {},
		videoSessions: {
			title: 'Video views',
			color: "#86E34F",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
	}),
	reducers: {
		getCurrentSessions: (state, action) => {
			state.currentSessions = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAvgViewingTimeVideo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAvgViewingTimeVideo.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.avgViewingTimeVideo = payload.data;
			})
			.addCase(getAvgViewingTimeVideo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getComplitionViewingVideoRate.pending, (state) => {
				state.loading = true;
			})
			.addCase(getComplitionViewingVideoRate.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.viewingVideoRate = payload.data;
			})
			.addCase(getComplitionViewingVideoRate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTotalCountVideoViewed.pending, (state) => {
				state.loading = true;
			})
			.addCase(getTotalCountVideoViewed.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.totalCountVideoViewed = payload.data;
			})
			.addCase(getTotalCountVideoViewed.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getVideosTable.pending, (state) => {
				state.loading = true;
			})
			.addCase(getVideosTable.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.videosTable = payload.data;
			})
			.addCase(getVideosTable.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(sendVideoStart.pending, (state) => {
				state.loading = true;
			})
			.addCase(sendVideoStart.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				;
				state.currentSessions = { ...state.currentSessions, [meta.arg]: payload.data };
			})
			.addCase(sendVideoStart.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(sendVideoEnd.pending, (state) => {
				state.loading = true;
			})
			.addCase(sendVideoEnd.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				const newCurrent = Object.fromEntries(
					Object.entries(state.currentSessions).filter(([key, value]) => key !== meta.arg.videoId)
				);
				state.currentSessions = newCurrent
			})
			.addCase(sendVideoEnd.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getVideosStatistic365.pending, (state) => {
				state.videoSessions.loading = true;
			})
			.addCase(getVideosStatistic365.fulfilled, (state, action) => {
				state.videoSessions.loading = false;
				let obj = newPrepareDataForChartByDays(action.payload)
				if (!obj) return
				state.videoSessions[365] = obj[365]
				state.videoSessions[90] = obj[90]
				state.videoSessions[30] = obj[30]
				state.videoSessions[7] = obj[7]
				state.videoSessions.ticks = obj.ticks
				state.videoSessions.ticksMob = obj.ticksMob
				state.videoSessions.error = null
			})
			.addCase(getVideosStatistic365.rejected, (state, action) => {
				state.videoSessions.loading = false
				state.videoSessions.error = action.payload
			})
	}
})

export default statisticVideosSlice.reducer;