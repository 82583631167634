import axios from "axios";

export class VRService {
	static getTrainings(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/vr/get/training/byUser/${comID}/${userId}/quest`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				"company": comID,
				"user": userId
			}
		});
	}

	static getTrainingsAvailableForCompany(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/all/trainings/by/company/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				"company": comID
			}
		});
	}

	static getPersonalTrainings(token, company, user) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/vr/get/user/training/${company}/${user}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				company,
				user
			}
		});
	}

	static getTrainingsListByGroup(token, comID, groupName) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/training/byGroup/${comID}/${groupName}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				"company": comID,
				groupName
			}
		});
	}

	static addTrainingsToUser(token, company, user, vrTrainingArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/vr/add/training/toUser`, {
			company,
			user,
			vrTrainingArr
		},
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
	}

	static addTrainingsToGroup(token, userId, groupName, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addVrContentToGroup`, {
			userId,
			groupName,
			contentIdArr
		},
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
	}

	static deleteTrainingFromUser(token, company, user, vrTrainingArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/vr/delete/training/fromUser`,
			{
				company,
				user,
				vrTrainingArr
			}, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
		}
		);
	}

	static deleteTrainingsFromGroup(token, userId, groupName, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteVrContentFromGroup`,
			{
				userId,
				groupName,
				contentIdArr
			}, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
		}
		);
	}

	static getTrainingsByPlans() {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/trainings/via/plans`);
	}

	static getTrainingInfo(token, trainingId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/vr/get/training/metaenga/${trainingId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	}

}