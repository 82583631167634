import React from 'react';
import { Caption } from '../Typography/Typography';
import cl from './PlanChips.module.css';

interface PlanChipsProps extends React.HTMLAttributes<HTMLDivElement> {
  plan?: 'Free' | 'Standard' | 'Exclusive' | 'Flex' | string;
  green?: boolean;
  light?: boolean;
  children?: React.ReactNode;
}

const PlanChips: React.FC<PlanChipsProps> = ({
  plan = 'Free',
  green = false,
  light = false,
  children,
  ...props
}) => {
  const styleChips = (light && green || light && plan.toLowerCase() === 'standard')
    ? { borderColor: 'transparent', backgroundColor: '#DAFFF6' }
    : (light && plan.toLowerCase() === 'free')
    ? { borderColor: 'transparent', backgroundColor: '#D5EBFE' }
    : plan.toLowerCase() === 'standard'
    ? { backgroundColor: '#EAFFEC', borderColor: '#1FB155' }
    : plan.toLowerCase() === 'exclusive'
    ? { backgroundColor: '#FFCE3C', borderColor: '#E2A900' }
    : plan.toLowerCase() === 'flex'
    ? { backgroundColor: '#E0D8F9', borderColor: '#E0D8F9' }
    : { backgroundColor: '#F2F2F2', borderColor: '#2977FF' };

  const styleText = (light && green || light && plan.toLowerCase() === 'standard')
    ? { color: '#4BC0A3' }
    : (light && plan.toLowerCase() === 'free')
    ? { color: '#0086FF' }
    : plan.toLowerCase() === 'standard'
    ? { color: '#1FB155' }
    : plan.toLowerCase() === 'exclusive'
    ? { color: '#2E3A4BB2' }
    : plan.toLowerCase() === 'flex'
    ? { color: '#8447FF' }
    : { color: '#2977FF' };

  return (
    <div className={cl.chips} style={{ ...styleChips, ...props.style }} {...props}>
      <Caption style={styleText}>{children || plan}</Caption>
    </div>
  );
};

export default PlanChips;
