function handleOrientation(event) {
	const alpha = event.alpha;
	const beta = event.beta;
	const gamma = event.gamma;
}


export class PermissionService {
	static getPermission() {
		let res 
		if (typeof DeviceMotionEvent.requestPermission === 'function') {
			// Handle iOS 13+ devices.
			DeviceMotionEvent.requestPermission()
				.then((state) => {
					if (state === 'granted') {
						window.addEventListener('devicemotion', handleOrientation);
						return res = true
					} else {
						alert('Rejected')
						return res = false
					}
				})
				.catch(e => {
					alert(e)
					res = false
					throw Error (e)
				});
		} else {
			// Handle regular non iOS 13+ devices.
			window.addEventListener('devicemotion', handleOrientation);
			return res = true
		}
		return res
	}

	static getPersistStore() {
		if (navigator.storage && navigator.storage.persist) {
			navigator.storage.persist().then((persistent) => {
				if (persistent) {
					alert('Granted store');
				} else {
					alert('Rejected store');
				}
			});
		} else {
			alert('Not supported');
		}
	}
}