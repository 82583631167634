import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

import TableControl from '../../../components/table/tableControl/TableControl';
import UserItemPR from '../../../components/table/tableItem/UserItemPR';

import EmptyTable from '../../../UI/emptyTable/EmptyTable';
import RoleChips from '../../../UI/roleChips/RoleChips';
import Spinner from '../../../UI/spinner/Spinner';
import UserColumn from '../../../UI/table/UserColumn';
import SpinnerOverlay from '../../../UI/spinner/SpinnerOverlay';
import InviteUserColumn from '../../../UI/table/InviteUserColumn';

function UsersTablePR({ data, checked, setChecked, selectFilter, searchFilter, multipleFilter = [], activeActions, setSelectAction, type = 'users', loading }) {
	const winWidth = useWindowWidth();
	const navigate = useNavigate()
	const iRole = useSelector(state => state.userInfo.info?.role)
	const verification = useSelector((state) => state.company?.info?.confirmed_email)

	const sortedUsers = useMemo(() => {
		if (!data) return null
		let sortedUsers = [...data]

		sortedUsers = sortedUsers.filter((item) => {
			if ((searchFilter && !item?.name?.toUpperCase().includes(searchFilter.toUpperCase()) && !item?.email?.toUpperCase().includes(searchFilter.toUpperCase())) || (selectFilter !== 'all' && item?.role?.toUpperCase() !== selectFilter)) {
				return false;
			}

			if (multipleFilter.length > 0 && !multipleFilter.includes(item?.role.toUpperCase())) {
				return false;
			}

			if (selectFilter !== 'all' && item?.role?.toUpperCase() !== selectFilter) {
				return false;
			}

			return true;
		});

		return sortedUsers
	}, [data, selectFilter, searchFilter, multipleFilter])


	const nameTemplate = (rowData) => {
		return <UserColumn rowData={rowData} />
	};

	const nameInviteTemplate = (rowData) => {
		return <InviteUserColumn rowData={rowData}/>
	};

	const roleTemplate = (rowData) => {
		return (
			<RoleChips role={rowData.role} />
		);
	}

	const teamsTemplate = (rowData) => {
		return (
			rowData.groups.length
		);
	}

	const activityTemplate = (rowData) => {
		return (
			rowData.lastActivity && moment(rowData.lastActivity).format('ll')
		);
	}

	const checkChecked = (item) => {
		return checked.some(checkedItem => checkedItem.id === item.id)
	}

	const [action, setAction] = useState();
	const timerRef = useRef();
	const isLongPress = useRef(false);
	const checking = useRef(false)



	const setCheckedId = (item, checked) => {
		if (checked === 'uncheck') {
			setChecked([])
			return
		}
		if (checked === undefined || checked.length === 0) {
			setChecked([item])
			return
		}

		const isChecked = checked.some(checkedItem => checkedItem.id === item.id);

		if (isChecked) {
			setChecked(checked.filter(checkedItem => checkedItem.id !== item.id));
		} else {
			setChecked([...checked, item]);
		}
	}

	useEffect(() => {
		return () => {
			// Очистка таймера при размонтировании компонента
			clearTimeout(timerRef.current);
		};
	}, []);

	function startPressTimer(data) {
		isLongPress.current = false;
		timerRef.current = setTimeout(() => {
			isLongPress.current = true;
			setAction('longpress');
			checking.current = !checking.current
			if (!checking.current) {
				setCheckedId('', 'uncheck')
				isLongPress.current = false
				return
			}
			handleOnClick(data) // эмулячия клика на тач скрин устройствах
			//setChecked(data.id, checked)
		}, 750);
	}

	function handleOnClick(data) {
		if (isLongPress.current) {
			if (checked.length > 0) {
				setCheckedId('', 'uncheck')
				setAction('')
				return
			}
			setCheckedId(data, checked)
			return;
		}
		if (checking.current) {
			//if (checked.length === 1 && checked.includes(data.id)) {
			//	setCheckedId('', 'uncheck')
			//	setAction('click');
			//	checking.current = false
			//	return
			//}
			setCheckedId(data, checked)
			setAction('click');
		} else {
			setCheckedId('', 'uncheck')
			setAction('click');
			resetAction(); // Сброс состояния после клика
			if(type === 'invites') return
			navigate(`user-${data.id}`, {replace: true})
		}
	}

	function handleOnMouseUp(e) {
		clearTimeout(timerRef.current);
		if (isLongPress.current) {
			resetAction(); // Сброс состояния после долгого нажатия
		}
		e.target.removeEventListener('contextmenu', preventContextMenu);
	}

	function handleOnTouchStart(e, data) {
		startPressTimer(data);
		e.target.removeEventListener('contextmenu', preventContextMenu);
	}

	function preventContextMenu(e) {
		e.preventDefault();
	}

	function resetAction() {
		setTimeout(() => setAction(null), 100); // Небольшая задержка перед сбросом состояния
	}

	useEffect(() => {
		if (checked.length === 0) {
			checking.current = false
			isLongPress.current = false

		}
	}, [checked])



	const mobTable = (data) => {
		return (
			<div>
				<div style={{ position: 'relative' }}>
					<TableControl
						checked={checked}
						setChecked={setChecked}
						icons={activeActions}
						setSelectAction={setSelectAction}
						data={data}
						verification={verification} />
					{
						(sortedUsers && sortedUsers.length > 0)
						&& sortedUsers.map(user => <UserItemPR
							iRole={iRole}
							key={user.id}
							type={type}
							checked={checkChecked(user)}
							user={user}
							onClick={() => handleOnClick(user)}
							onMouseDown={(e) => handleOnTouchStart(e, user)}
							onMouseUp={handleOnMouseUp}
							onTouchStart={(e) => handleOnTouchStart(e, user)}
							onTouchEnd={handleOnMouseUp} />
						)

					}
					{(!sortedUsers || sortedUsers.length === 0) && !loading && <div key={Math.random()}><EmptyTable icon='users' /></div>}

					{loading && sortedUsers && <SpinnerOverlay />}
				</div>

				{loading && !sortedUsers && <div style={{ padding: '20px 0' }}>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
				</div>}

			</div>
		);
	};

	const onSelectionChange = (e) => {
		setChecked(e.value);
	}

	return (

		winWidth > TABLET_WIDTH ?
			<div style={{ overflowX: 'hidden' }}>
				<TableControl
					checked={checked}
					setChecked={setChecked}
					icons={activeActions}
					setSelectAction={setSelectAction}
					data={data}
					verification={verification} />

				{type === 'invites' && sortedUsers ? <DataTable
					value={sortedUsers}
					selectionMode={true}
					selection={checked}
					onSelectionChange={onSelectionChange}
					dataKey="id" tableStyle={{ minWidth: '45rem' }}
					selectionPageOnly
					emptyMessage={<EmptyTable icon='users' />}
					loading={loading}
					loadingIcon={<Spinner />}
				>
					<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
					<Column field="email" header="Email" sortable sortField='email' body={nameInviteTemplate}></Column>
					<Column field="role" header="Role" headerTooltip="User role" headerTooltipOptions={{ position: 'top' }} sortable body={roleTemplate}></Column>
					<Column field="lastActivity" sortable header="Invite date" body={activityTemplate}></Column>
				</DataTable>
					: type === 'invites' && !sortedUsers && loading ?
						<DataTable
							value={[{ name: '', id: '' }]}
							selectionMode={true}
							selection={checked}
							onSelectionChange={onSelectionChange}
							dataKey="id" tableStyle={{ minWidth: '45rem' }}
							selectionPageOnly
							emptyMessage={<EmptyTable icon='users' />}
						>
							<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
							<Column field="email" header="Email" sortable sortField='email' body={<Skeleton />}></Column>
							<Column field="role" header="Role" headerTooltip="User role" headerTooltipOptions={{ position: 'top' }} sortable body={<Skeleton />}></Column>
							<Column field="lastActivity" sortable header="Invite date" body={<Skeleton />}></Column>
						</DataTable> : null
				}
				{type !== 'invites' && sortedUsers ?
					<DataTable
						value={sortedUsers}
						selectionMode={true}
						selection={checked}
						onSelectionChange={onSelectionChange}
						dataKey="id"
						headerStyle={{ whiteSpace: 'nowrap' }}
						tableStyle={{ minWidth: '45rem', width: '100%', maxWidth: '100%' }}
						selectionPageOnly
						emptyMessage={<EmptyTable icon='users' />}
						loading={loading}
						loadingIcon={<Spinner />}
					>

						<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
						<Column field="name" header="Name" sortable sortField='name' body={nameTemplate}></Column>
						<Column field="role" header="Role" headerTooltip="User role" headerTooltipOptions={{ position: 'top' }} sortable body={roleTemplate}></Column>
						<Column field="groups" header="Teams" body={teamsTemplate}></Column>
						<Column field="lastActivity" sortable header="Last activity" body={activityTemplate}></Column>
					</DataTable>
					: type !== 'invites' && !sortedUsers && loading ?
						<DataTable
							value={[{
								"name": "",
								"email": "",
								"phone": null,
								"id": "",
								"role": "",
								"status": "",
								"token": null,
								"avatar": "",
								"workerid": null,
								"job": null,
								"location": null,
								"lastActivity": "",
								"groups": []
							},]}
							selectionMode={true}
							selection={checked}
							onSelectionChange={onSelectionChange}
							dataKey="id"
							tableStyle={{ minWidth: '45rem' }}
							selectionPageOnly
							emptyMessage={<EmptyTable icon='users' />}
						>

							<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
							<Column field="name" header="Name" sortable sortField='name' body={<Skeleton />}></Column>
							<Column field="role" header="Role" headerTooltip="User role" headerTooltipOptions={{ position: 'top' }} sortable body={<Skeleton />}></Column>
							<Column field="groups" header="Teams" body={<Skeleton />}></Column>
							<Column field="lastActivity" sortable header="Last activity" body={<Skeleton />}></Column>

						</DataTable> : null}

			</div>
			: <>
				{mobTable(data)}
			</>
	)
}

export default UsersTablePR