import axios from 'axios';

export class SubscriptionService {
	static getSubscriptionInfo(token, userId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/company/via/subscription/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static getPaymentToken(token, userId, licenseCount, mail, ammount, description, newPlan) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/order`, {
			userId,
			licenseCount,
			mail,
			ammount,
			description,
			newPlan
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getPaymentTokenFlex(token, userId, headsets, items, video, amount) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/order/flex`, {
			userId,
			headsets,
			items,
			video,
			amount: amount * 100
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static cancelSubscription(token, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/order/cancel/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static cancelFlexSubscription(token, comID, moduleId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/flex/cancel`, {
			companyId: comID,
			trainingId: moduleId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static cancelFlexSubscription360(token, companyId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/flex/360/cancel`, {
			companyId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static cancelSubscriptionYear(token, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/subscription/year/cancel/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendBillingInfo(token, comID, ammount) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/get/monthly/payment/${ammount}/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getSubscriptionHistory(token, comID) {

		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/payment/history/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static createPdfInvoice(token, companyName, billingAddress, contactName, email, companyId, typeSubscription, total, unitPrice, numberLicenses) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/create/pdf/invoice`,
			{
				companyName, billingAddress, contactName, email, companyId, typeSubscription, total, unitPrice, numberLicenses
			},
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			}
		)
	}

	static getInvoicePdf(token, invoiceId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/progress/pdf/${invoiceId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getRecieptPdf(token, orderId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/progress/pdf/receipt/${orderId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getPaymentReceipt(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/payment/receipt/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getPaidModuls(token, userId) {
		//return {
		//	"products": [
		//		{
		//			"id": "prod_Q9VzYPK2MkIy1G",
		//			"object": "product",
		//			"active": true,
		//			"attributes": [],
		//			"created": 1716375851,
		//			"default_price": "price_1PJCxrACtPVEAgimoH2P3OoN",
		//			"description": null,
		//			"features": [],
		//			"images": [],
		//			"livemode": false,
		//			"marketing_features": [],
		//			"metadata": {
		//				"id": "1001"
		//			},
		//			"name": "High Voltage Electrical Substation Tour",
		//			"package_dimensions": null,
		//			"shippable": null,
		//			"statement_descriptor": null,
		//			"tax_code": "txcd_10000000",
		//			"type": "service",
		//			"unit_label": null,
		//			"updated": 1719408727,
		//			"url": null
		//		},
		//		{
		//			"id": "prod_Q9W0Oz9gMLzJHG",
		//			"object": "product",
		//			"active": true,
		//			"attributes": [],
		//			"created": 1716375876,
		//			"default_price": "price_1PJCyGACtPVEAgimovKFAbai",
		//			"description": null,
		//			"features": [],
		//			"images": [],
		//			"livemode": false,
		//			"marketing_features": [],
		//			"metadata": {
		//				"id": "1003"
		//			},
		//			"name": "Transform Oil Sampling",
		//			"package_dimensions": null,
		//			"shippable": null,
		//			"statement_descriptor": null,
		//			"tax_code": "txcd_10000000",
		//			"type": "service",
		//			"unit_label": null,
		//			"updated": 1719408891,
		//			"url": null
		//		},
		//		{
		//			"id": "prod_Q9WCfpQTB7c2h0",
		//			"object": "product",
		//			"active": true,
		//			"attributes": [],
		//			"created": 1716376630,
		//			"default_price": "price_1PJDARACtPVEAgimMhLD9rs1",
		//			"description": null,
		//			"features": [],
		//			"images": [],
		//			"livemode": false,
		//			"marketing_features": [],
		//			"metadata": {
		//				"id": "1008"
		//			},
		//			"name": "Heat Shrink Cable Joint",
		//			"package_dimensions": null,
		//			"shippable": null,
		//			"statement_descriptor": null,
		//			"tax_code": "txcd_10000000",
		//			"type": "service",
		//			"unit_label": null,
		//			"updated": 1719840101,
		//			"url": null
		//		}
		//	]
		//}
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/subscriptions/products/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	static sendUpdateFlexPlan(token, products, userId) {
		return {}
	}

	static sendUpdateFlexModuleDecrease(token, items, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/update/flex/subscription`, {
			userId,
			items,
			video: false,
			proration_behavior: 'none'
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendUpdateFlexModuleIncrease(token, items, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/update/flex/subscription`, {
			userId,
			items,
			video: false,
			proration_behavior: 'always_invoice'
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendUpdateFlexSubscr(token, items, userId, video) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/update/flex/subscription`, {
			userId,
			items,
			video,
			proration_behavior: 'always_invoice'
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}