import axios from 'axios';

export class InvitesService {

	static sendInvite(token, id, userEmail, role, companyName) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/register/user`, {
			"creater": id,
			role,
			userEmail,
			companyName
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getInvites(token, id) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getRegLinks/user/${id}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static deleteInvite(token, id, regtoken, role) {
		return role === 'ADMIN' ? axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteInvite/admin`, {
			"admin": id,
			"token": regtoken
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
		: axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteInvite/owner`, {
			"owner": id,
			"token": regtoken
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}