import SearchInput from "./SearchInput"

import cl from './MobileSearchInputWithClose.module.css'
import { useEffect, useRef } from "react";

function MobileSearchInputWithClose({ onClose, value, setValue, onSearchChange, placeholder = 'Search by name or email' }) {
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	return (
		<div className={cl.search__row}>
			<i className='icon-arrow-left' onClick={onClose} />
			<SearchInput value={value} setValue={setValue} onChange={onSearchChange} placeholder={placeholder} ref={inputRef} />
		</div>
	)
}

export default MobileSearchInputWithClose