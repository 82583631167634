import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { StatisticVRService } from "../../services/StatisticVRServices";

function calculateRating(finalScore, maxScore) {
  const percentage = (finalScore / maxScore) * 100;
  const rating = (percentage / 100) * 5;
  return rating.toFixed(1);
}

export const getSessionTrainingDetails = createAsyncThunk(
  "sessionTrainingDetails/getSessionTrainingDetails",
  async (trainingSessionId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await StatisticVRService.getTrainingSessionDetails(
        token,
        trainingSessionId
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const sessionTrainingDetailsAdapter = createEntityAdapter({
  selectId: (session) => session.trainingSessionId,
});

const sessionTrainingDetailsSlice = createSlice({
  name: "sessionTrainingDetails",
  initialState: sessionTrainingDetailsAdapter.getInitialState({
    loading: false,
    error: null,
    sessions: null,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getSessionTrainingDetails.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSessionTrainingDetails.fulfilled, (state, action) => {
        state.loading = false;

        let session = {
          ...action.payload.data,
          rating:
            action.payload.data.finalScore === "n/a"
              ? "n/a"
              : calculateRating(
                  action.payload.data.finalScore,
                  action.payload.data.maxScore
                ),
        };
       
        state.sessions = state.sessions || {};
        state.sessions[action.meta.arg] = session;
      })
      .addCase(getSessionTrainingDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default sessionTrainingDetailsSlice.reducer;
