import axios from "axios"


export class ClassroomsService {
	static getClassrooms(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/rooms/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static createClassroom(token, roomName, companyId, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/create/room`, {
			roomName,
			companyId,
			userId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static deleteClassroom(token, userId, classId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/delete/room`, {
			userId,
			classId
			
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}