import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TeamsService } from '../../services/TeamsServices';
import { getShortName } from '../../utils/GetShortName';


export const createTeam = createAsyncThunk(
	'teams/createTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamsService.createTeam(token, userId, data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addToTeam = createAsyncThunk(
	'teams/addToTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamsService.addToTeam(token, userId, data.groupName, data.userArr)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTeams = createAsyncThunk(
	'teams/getTeams',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const res = await TeamsService.getTeams(token, data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteTeam = createAsyncThunk(
	'teams/deleteTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamsService.deleteTeam(token, userId, data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addContentToTeam = createAsyncThunk(
	'teams/addContentToTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamsService.addContentToTeam(token, userId, data.groupName, data.contentArr)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addMetaengaContentToTeam = createAsyncThunk(
	'teams/addMetaengaContentToTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamsService.addMetaengaContentToTeam(token, userId, data.groupName, data.contentArr)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)



const teamsAdapter = createEntityAdapter({
	selectId: (team) => team.groupName
})

const teamsSlice = createSlice({
	name: 'teams',
	initialState: teamsAdapter.getInitialState({
		loading: true,
		error: null,
		teams: null
	}),
	extraReducers: (builder) => {
		builder
			.addCase(createTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(createTeam.fulfilled, (state, action) => {
				state.loading = false
				if (!state.teams) {
					state.teams = [{
						groupName: action.meta.arg,
						Name: action.meta.arg,
						shortName: getShortName(action.meta.arg),
						Users: [],
						usersCount: 1
					}]
				} else {
					state.teams = [...state.teams, {
						groupName: action.meta.arg,
						Name: action.meta.arg,
						shortName: getShortName(action.meta.arg),
						Users: [],
						usersCount: 1
					}]
				}
			})
			.addCase(createTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(addToTeam.pending, (state, action) => {
				state.loading = true
			}
			)
			.addCase(addToTeam.fulfilled, (state, action) => {
				state.loading = false
				state.teams = state.teams.map(team => {
					if (team.groupName === action.meta.arg.groupName) {
						team.Users = [...team.Users, ...action.meta.arg.userArr]
						team.usersCount = + action.meta.arg.userArr.length
					}
					return team
				})
			})
			.addCase(addToTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getTeams.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getTeams.fulfilled, (state, action) => {
				state.loading = false
				state.teams = action.payload?.groupUsers?.map((team) => (
					{ ...team, groupName: team.Name, shortName: getShortName(team.Name), usersCount: team.Users.length }
				))
			})
			.addCase(getTeams.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				if (action.payload === 404) {
					state.teams = []
				}
			})
			.addCase(deleteTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(deleteTeam.fulfilled, (state, action) => {
				state.loading = false
				state.teams = state.teams.filter((team) => team.groupName !== action.meta.arg)
			})
			.addCase(deleteTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(addContentToTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(addContentToTeam.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(addContentToTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(addMetaengaContentToTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(addMetaengaContentToTeam.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(addMetaengaContentToTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export default teamsSlice.reducer

