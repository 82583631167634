import React from 'react';
import classNames from 'classnames';
import cl from './ScrollBlock.module.css';

interface ScrollBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  height?: number;
  maxHeight?: number | null;
}

const ScrollBlock: React.FC<ScrollBlockProps> = ({ children, height = 50, maxHeight = null, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(cl.scroll, props.className)}
      style={{ height, maxHeight: maxHeight ?? undefined, ...props.style }}
    >
      {children}
    </div>
  );
};

export default ScrollBlock;
