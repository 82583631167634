import { Paragraph } from "../Typography/Typography";
import only_pc from "../../assets/png/only_pc.png";
import cl from './DesktopOnly.module.css';

interface DesktopOnlyProps {} // порожній інтерфейс, оскільки пропсів немає

const DesktopOnly: React.FC<DesktopOnlyProps> = () => {
  return (
    <div className={cl.container__row}>
      <div className={cl.img__wrapper}>
        <img src={only_pc} alt="desktop only" />
      </div>
      <div className={cl.title__wrapper}>
        <Paragraph>Desktop-only access required</Paragraph>
      </div>
      <div className={cl.text__wrapper}>
        <Paragraph>
          This page is only available on desktop. Please use a desktop device.
        </Paragraph>
      </div>
    </div>
  );
}

export default DesktopOnly;
