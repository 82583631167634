import React, { useEffect, useState } from 'react'
import { Paragraph, TextError } from '../../UI/Typography/Typography'
import ContentModal from '../../UI/modal/ContentModal.tsx'

import cl from './AddToTeamsModal.module.css'
import Checkbox from '../../UI/checkbox/Checkbox'
function AddToTeamsModal({ teams, modalIsOpen, setModalIsOpen, onConfirm, onClose, loading, error, ...props }) {

	const [checkedTeams, setCheckedTeams] = useState([])

	const onCheckboxChange = (groupName) => {
		if (!checkedTeams.includes(groupName)) {
			setCheckedTeams([...checkedTeams, groupName])
		} else {
			setCheckedTeams(old => old.filter(team => team !== groupName))
		}
	}

	const onClean = () => {
		setCheckedTeams([])
	}

	const [load, setLoad] = useState(false)

	const onSend = async () => {
		setLoad(true)
		await onConfirm(checkedTeams, onClean)
		setLoad(false)
	}


	return (
		<ContentModal
			header='Add to team'
			textButton='Add'
			modalIsOpen={modalIsOpen}
			setModalIsOpen={setModalIsOpen}
			onConfirm={onSend}
			closeOnConfirm={false}
			onClose={onClose}
			loading={load}
			disabled={checkedTeams.length === 0 || loading}
		>
			<div className={cl.container}>
				<Paragraph>Teams {checkedTeams.length > 0 && `(${checkedTeams.length})`}</Paragraph>
				<div className={cl.scroll}>
					{teams?.map((team) => (
						<div className={cl.checkbox} key={team.groupName}>
							<Checkbox
								checked={checkedTeams.includes(team.groupName)}
								onChange={() => onCheckboxChange(team.groupName)}
							>{team.groupName}</Checkbox>
						</div>
					))}
				</div>
				{error && <TextError style={{ textAlign: 'center' }}>{error}</TextError>}
			</div>
		</ContentModal>
	)
}

export default AddToTeamsModal