import { forwardRef } from 'react'
import classNames from 'classnames'

import Input from '../input/Input'

import cl from './SearchInput.module.css'

const SearchInput = forwardRef(({ placeholder, value, setValue, onChange, loading, label, disabled, ...props }, ref) => {
	const cleanInput = () => {
		setValue('')
	}
	return (
		<div className={cl.wrap}>
			<Input className={classNames(cl.search, 'icon-search')}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				loading={loading}
				label={label}
				disabled={disabled}
				ref={ref}
				{...props}
			/>
			{value && <i className='icon-close' onClick={cleanInput} />}
		</div>
	)
})

export default SearchInput