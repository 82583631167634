import axios from "axios";


export class VRStatisticByUserService {

	//table training sessions
	static getTrainingSessionsTable(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/all/sessions/by/user/${comID}/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


}