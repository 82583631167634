import { useEffect, useState } from "react";


export const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowWidth;
}

export const MOBILE_WIDTH = 575.5;
export const TABLET_WIDTH = 991.5;