import axios from "axios"

export class AccessContentService {

	static getPersonalVRAccess(token, userId, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/vr/get/training/byUser/${comID}/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				"company": comID,
				"user": userId
			}
		});
	}

	static getPersonalTrainings(token, user, company) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/vr/get/user/training/${company}/${user}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				company,
				user
			}
		});
	}

	static getTeamVRAccess(token, company, groupName) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/training/byGroup/${company}/${groupName}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			data: {
				company,
				groupName
			}
		});
	}

}