import React, { useEffect, ReactNode } from 'react';
import classNames from 'classnames';

import cl from './Overlay.module.css';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  callback?: () => void | null; // Дозволяємо функцію або null
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Overlay: React.FC<OverlayProps> = ({ children, callback = null, modalIsOpen, setIsOpen, ...props }) => {
  const overlay = classNames(cl.overlay, modalIsOpen && cl.active);

  useEffect(() => {
    document.body.style.overflow = modalIsOpen ? 'hidden' : 'visible';
  }, [modalIsOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const onOverlayClick = () => {
    setIsOpen(false);
    if (callback) callback();
  };

  return (
    <div className={overlay} onClick={onOverlayClick} {...props}>
      {children}
    </div>
  );
};

export default Overlay;
