import React from 'react';
import RandomAvatar from '../randomAvatar/RandomAvatar';
import cl from './TeamNameColumn.module.css';
import { Link } from 'react-router-dom';
import { Paragraph } from '../Typography/Typography';

interface TeamNameColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  info: {
    shortName?: string;
    groupName?: string;
  };
}

const TeamNameColumn: React.FC<TeamNameColumnProps> = ({ info }) => {
  return (
    <div className={cl.column}>
      <div className={cl.avatar__wrap}>
        {info?.shortName && <RandomAvatar>{info.shortName}</RandomAvatar>}
      </div>

      <div className={cl.team__info}>
        <Link
          to={`${info.groupName}`}
          className="link-dark"
          style={{ position: 'relative' }}
        >
          {info.groupName}{' '}
          <i
            className="icon-chevron-right"
            style={{
              fontSize: 22,
              position: 'absolute',
              bottom: '-3px',
              right: '-20px',
              color: 'var(--field-color)',
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default TeamNameColumn;
