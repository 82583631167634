import React from 'react'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from '../VideosUploadOverlay.module.css'

function VideoItem({video, index, progress, handleRemoveVideo, ...props}) {

	return (
		<div className={cl.video} {...props}>
			<div className={cl.info}>
				<Paragraph><span>Uploading:</span> {video?.label}</Paragraph>
				<div className={cl.progressbar}>
					<div className={cl.bar}>
						<div className={cl.progress} style={{ width: `${progress}%` }}></div>
					</div>
					<Paragraph>{progress}%</Paragraph>
				</div>
			</div>
			<div className={cl.action}>
				<i className='icon-delete' onClick={() => handleRemoveVideo(index)} />
			</div>
		</div>
	)
}

export default VideoItem