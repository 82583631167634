import classNames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { uid } from 'uid';

import cl from './Checkbox.module.css';

interface CheckboxProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  checked: boolean; 
  onChange: (e: ChangeEvent<HTMLInputElement>) => void; 
  disabled?: boolean; 
  error?: boolean; 
  children?: React.ReactNode; 
  minus?: boolean; 
  name?: string; 
  className?: string; 
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  disabled = false,
  error = false,
  children,
  minus = false,
  name = '',
  className,
  ...props
}) => {
  const id = uid(8); 

  return (
    <div className={classNames(cl.row, className)} {...props}>
      <div className={cl.box}>
        <input
          className={classNames(cl.checkbox, minus && cl.minus, error && cl.error)}
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
        />
      </div>
      <label
        className={classNames(cl.label, disabled && cl.disabled, checked && cl.checked)}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
