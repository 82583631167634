import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { VRStatisticByUserService } from "../../services/VRStatisticByUserService";



function calculateRating(finalScore, maxScore) {
	if (finalScore === 'n/a') return 'n/a'
	const percentage = (finalScore / maxScore) * 100;
	const rating = (percentage / 100) * 5;
	return rating.toFixed(1) || 0;
}

const vrSessionsStatisticByUserAdapter = createEntityAdapter({
	selectId: (entity) => uid(8),
});

export const getTrainingSessionsTableByUser = createAsyncThunk(
	"vrSessionsStatisticByUserSlice/getTrainingSessionsTablePersonal",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("token");
		const { comID, userId } = data;
		try {
			const response = await VRStatisticByUserService.getTrainingSessionsTable(
				token,
				comID,
				userId
			);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const vrSessionsStatisticByUserSlice = createSlice({
	name: "vrSessionsStatisticByUserSlice",
	initialState: vrSessionsStatisticByUserAdapter.getInitialState({
		loading: true,
		error: "",
		sessions: null,
	}),
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setSessions: (state, action) => {
			state.sessions = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getTrainingSessionsTableByUser.pending, (state) => {
			state.loading = true;
			state.error = "";
		})
			.addCase(getTrainingSessionsTableByUser.fulfilled, (state, action) => {
				state.loading = false;
				
				let sessions = action.payload.data.sessions.map(session => {
					if (session.finalScore === 'n/a') {
						return {
							...session,
							rating: 'n/a'
						}
					} else {
						return {
							...session,
							rating: calculateRating(session.finalScore, session.maxScore)
						}

					}
				})


				let sessionsData = { ...action.payload.data, sessions }

				state.sessions = {
					...state.sessions,
					[action.meta.arg.userId]: sessionsData
				};

			})
			.addCase(getTrainingSessionsTableByUser.rejected, (state, action) => {
				state.error = "Session not found";
				state.loading = false;
			});
	},
});

export const { setError, setLoading, setSessions } = vrSessionsStatisticByUserSlice.actions;
export default vrSessionsStatisticByUserSlice.reducer;
