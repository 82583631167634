import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AccessContentService } from '../../services/AccessContentServices';
import { VideosService } from '../../services/VideosServices';

export const getPersonalVRAccess = createAsyncThunk(
	'accessContent/getPersonalVRAccess',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { userId, comID } = data
		;
		try {
			const res = await AccessContentService.getPersonalTrainings(token, userId, comID)
			;
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTeamVRAccess = createAsyncThunk(
	'accessContent/getTeamVRAccess',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, groupName } = data
		try {
			const res = await AccessContentService.getTeamVRAccess(token, comID, groupName)
			return { data: res.data, groupName }
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getPersonalVideoAccess = createAsyncThunk(
	'accessContent/getPersonalVideoAccess',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { userId, comID } = data
		try {
			const res = await VideosService.getVideosByUser(token, comID, userId)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTeamVideoAccess = createAsyncThunk(
	'accessContent/getTeamVideoAccess',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, groupName } = data
		try {
			const res = await VideosService.getVideosByGroup(token, comID, groupName)
			return { data: res.data, groupName }
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const accessContentAdapter = createEntityAdapter({
	selectId: (accessContent) => accessContent.id,
})

const accessContentSlice = createSlice({
	name: 'accessContent',
	initialState: accessContentAdapter.getInitialState({
		loading: false,
		error: null,
		personalVideoAccess: [],
		teamVideoAccess: [],
		personalVRAccess: [],
		teamVRAccess: [],
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getPersonalVRAccess.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getPersonalVRAccess.fulfilled, (state, action) => {
				state.loading = false
				state.personalVRAccess = action.payload.data
			})
			.addCase(getPersonalVRAccess.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getTeamVRAccess.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getTeamVRAccess.fulfilled, (state, action) => {
				state.loading = false
				const VRs = action.payload.data.data.map(vr => ({
					...vr,
					uId: action.payload.groupName + vr.id,
					team: action.payload.groupName
				}))
				const filteredVR = VRs.filter(vr => !state.teamVRAccess.some(vr2 => vr2.uId === vr.uId))
				state.teamVRAccess = [
					...state.teamVRAccess,
					...filteredVR
				]
			})
			.addCase(getTeamVRAccess.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getPersonalVideoAccess.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getPersonalVideoAccess.fulfilled, (state, action) => {
				state.loading = false
				state.personalVideoAccess = action.payload.data.data
			})
			.addCase(getPersonalVideoAccess.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getTeamVideoAccess.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getTeamVideoAccess.fulfilled, (state, action) => {
				state.loading = false
				;
				//const videos = action.payload.data.data.map(video => ({
				//	...video,
				//	uId: action.payload.groupName + video.id,
				//	team: action.payload.groupName
				//}))
				//state.teamVideoAccess = {
				//	...state.teamVideoAccess,
				//	...videos
				//}
			})
	}
})

export default accessContentSlice.reducer