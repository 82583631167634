import React from 'react';
import Spinner from './Spinner';
import cl from './SpinnerOverlay.module.css';

interface SpinnerOverlayProps extends React.ComponentProps<typeof Spinner> {}

const SpinnerOverlay: React.FC<SpinnerOverlayProps> = (props) => {
  return (
    <div className={cl.overlay}>
      <Spinner {...props} />
    </div>
  );
};

export default SpinnerOverlay;
