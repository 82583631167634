import { toast, ToastContainer } from 'react-toastify'

import { useGoogleLogin } from '@react-oauth/google';

import LoginForm from '../components/forms/LoginForm'
import LoginHeader from '../components/login/LoginHeader'
import Button from '../UI/Button/Button';
import axios from 'axios';
import GoogleBtn from '../UI/googleBtn/GoogleBtn.tsx';

import cl from '../styles/components/forms/LoginForm.module.css'
import { Paragraph, TextError } from '../UI/Typography/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onLoginByGoogle } from '../redux/slices/LoginSlice';
import { useState } from 'react';
import { setPurchaseNotice } from '../redux/slices/PurchaseNoticeSlice';

function Login() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const location = useLocation();

	function getQueryParam(param) {
		return new URLSearchParams(location.search).get(param);
	}

	const selectedModuls = getQueryParam('selectedmoduls');
	const quantityModuls = getQueryParam('quantitymoduls');
	const videoModule = getQueryParam('videomodule');

	const [loginWithoutGoogleError, setLoginWithoutGoogleError] = useState('')

	const sendGoogleToken = async (response) => {
		const res = await dispatch(onLoginByGoogle(response.access_token))
		if (res.payload.status === 200 || res.payload.status === 'success') {
			localStorage.setItem('token', res.payload.token)
			localStorage.setItem('email', res.payload.email)
			localStorage.setItem('id', res.payload.id)

			if (selectedModuls && quantityModuls) {
				dispatch(setPurchaseNotice({ comID: res.payload.companyId, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
			}
			
			if (res.payload.type === 'login') {
				navigate('/home/vr')
			} else {
				navigate('/home/vr?hello=true')
			}
		} else {
			toast.error('Failed to login. Try again later')
		}
	}

	const googleLogin = useGoogleLogin({
		onSuccess: async tokenResponse => {
			await sendGoogleToken(tokenResponse)
		},
		onError: (err) => {
			toast.error('Failed to login')
		},
	});


	return (
		<LoginHeader header='Log in'>
			{loginWithoutGoogleError && <TextError style={{marginTop: 8}}>{loginWithoutGoogleError}</TextError>}
			<LoginForm setBigError={setLoginWithoutGoogleError}/>
			<GoogleBtn onClick={googleLogin} />
			<div className={cl.signin}>
				<Paragraph>Log in with</Paragraph>
				<Button linkbtn onClick={() => navigate('/login-with-code')} >Verification Code</Button>
			</div>
			<div className={cl.signup}>
				<Paragraph>Don't have an account?</Paragraph>
				<Button linkbtn onClick={() => navigate('/self-registration')} >Sign up</Button>
			</div>
			<ToastContainer
				theme='colored'
				position="bottom-right"
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</LoginHeader>
	)
}

export default Login