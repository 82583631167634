import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { VideosService } from '../../services/VideosServices';

export const uploadVideo = createAsyncThunk(
	'uploadVideos/uploadVideo',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, formData, onProgress  } = data
		try {
			const res = await VideosService.uploadVideo(token, comID, formData, onProgress)
			;
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const uploadVideosAdapter = createEntityAdapter({
	selectId: (uploadVideo) => uid(16),
});

const uploadVideosSlice = createSlice({
	name: 'uploadVideos',
	initialState: uploadVideosAdapter.getInitialState( {
		loading: false,
		error: null,
		files: [],
		progress: 0,
		onUpload: 0,
	}),
	reducers: {
		addFile: (state, action) => {
			state.files = [...state.files, action.payload]
		},
		removeFile: (state, action) => {
			state.files = state.files.filter(file => file.get('label') !== action.payload)
		},
		updateProgress: (state, action) => {
			state.progress = action.payload
		},
		setOnUpload: (state, action) => {
			state.onUpload = action.payload
		}

	},
	extraReducers: (builder) => {
		builder
			.addCase(uploadVideo.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadVideo.fulfilled, (state, action) => {
				state.loading = false;
				state.uploadVideo = action.payload;
			})
			.addCase(uploadVideo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
	},
})

export const { addFile, removeFile, updateProgress, setOnUpload } = uploadVideosSlice.actions

export default uploadVideosSlice.reducer
