import { useMemo } from 'react'
import { Skeleton } from 'primereact/skeleton'
import { Column } from 'primereact/column'
import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'
import { DataTable } from 'primereact/datatable'
import { useNavigate } from 'react-router-dom'
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import TooltipIcon from '../../../UI/tooltipIcon/TooltipIcon'
import EmptyTable from '../../../UI/emptyTable/EmptyTable'
import Button from '../../../UI/Button/Button'
import Spinner from '../../../UI/spinner/Spinner'

import cl from './PersonalVrSessionsStatisticTablePR.module.css'

function VrSessionsStatisticTableByUserPR({ data, loading, searchFilter, ...props }) {
	const winWidth = useWindowWidth();
	const navigate = useNavigate();

	const sorted = useMemo(() => {
		if (!data) return null
		let sorted = [...data]
		
		sorted = sorted.filter((item) => {
			if ((searchFilter && !(`${item?.trainingName}`?.toUpperCase().includes(searchFilter.toUpperCase())))) {
				return false;
			}

			return true;
		});

		return sorted
	}, [data, searchFilter])

	const sessionIdTemplate = (dataRow) => {
		return <div className={cl.column__id}>
			{dataRow.trainingSessionId}
			<TooltipIcon tooltip={dataRow.scenarioName} place='top' />
		</div>
	}

	const durationTemplate = (dataRow) => {
		return <>				{dataRow.sessionTime ? (
			<>
				{prettyMilliseconds(+dataRow.sessionTime, {
					unitCount: 2,
					keepDecimalsOnWholeSeconds: false,
					secondsDecimalDigits: 0,
				})}
			</>
		) : "n/a"}</>
	}

	const activityTemplate = (dataRow) => {
		return <>{(dataRow.activity && moment(dataRow.activity).format("MMM DD, YYYY")) || "n/a"}</>
	}

	const scoreTemplate = (dataRow) => {
		return <>{dataRow.rating === 'n/a' ? "Not available" : dataRow.rating}</>
	}

	const complitionTemplate = (dataRow) => {
		return <>{dataRow.maxScore === 'n/a' ? '-' : Math.round(dataRow.totalPercent) + '%'}</>
	}

	const buttonTemplate = (dataRow) => {
		return <Button
			disabled={dataRow.rating === "n/a" || dataRow.rating === '0.0'}
			linkbtn
			icon="right"
			right
			onClick={() => navigate(`${dataRow.trainingSessionId}`)}
			className={cl.button}
		>
			Details
		</Button>
	}

	return (
		<div style={{ width: '100%' }}>
			{winWidth > TABLET_WIDTH ?
				<>
					{sorted ? <DataTable
						value={sorted}
						dataKey="trainingSessionId"
						tableStyle={{ minWidth: '45rem' }}
						selectionPageOnly
						emptyMessage={<EmptyTable icon='statistics' />}
						sortField='trainingSessionId'
						sortOrder={-1}
						loading={loading}
						loadingIcon={<Spinner />}
					>
						<Column field="trainingSessionId" header="Session ID" style={{ paddingLeft: 16 }} body={sessionIdTemplate}></Column>

						<Column field="trainingName" header="Training name" sortable ></Column>

						<Column field="sessionTime" header="Session time" body={durationTemplate}></Column>

						<Column field="activity" sortable header="Activity" body={activityTemplate}></Column>

						<Column field="rating" header="Score" body={scoreTemplate}></Column>

						<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Completion rate' body={complitionTemplate}></Column>

						<Column header="" body={buttonTemplate}></Column>
					</DataTable>
						: !sorted && loading ?
							<DataTable
								value={[{ name: '', id: '' }, { name: '', id: '' }, { name: '', id: '' }]}
								dataKey="id" tableStyle={{ minWidth: '45rem' }}
								selectionPageOnly
								emptyMessage={<EmptyTable icon='statistics' />}
							>

								<Column field="trainingSessionId" header="Session ID" style={{ paddingLeft: 16 }} body={<Skeleton />}></Column>

								<Column field="trainingName" header="Training name" sortable body={<Skeleton />}></Column>

								<Column field="sessionTime" header="Session time" body={<Skeleton />}></Column>

								<Column field="activity" sortable header="Activity" body={<Skeleton />}></Column>

								<Column field="rating" header="Score" body={<Skeleton />}></Column>

								<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Completion rate' body={<Skeleton />}></Column>

								<Column header="" body={<Skeleton />}></Column>
							</DataTable> : null
					}


				</>
				: null}
		</div>
	)
}

export default VrSessionsStatisticTableByUserPR