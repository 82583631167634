import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ClassroomsService } from '../../services/ClassroomsServices';

export const getClassrooms = createAsyncThunk(
	'classrooms/getClassrooms',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const response = await ClassroomsService.getClassrooms(token, data)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const createClassroom = createAsyncThunk(
	'classrooms/createClassroom',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		const { roomName, comID } = data
		try {
			const response = await ClassroomsService.createClassroom(token, roomName, comID, userId)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteClassroom = createAsyncThunk(
	'classrooms/deleteClassroom',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const response = await ClassroomsService.deleteClassroom(token, userId, data)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


const classroomsAdapter = createEntityAdapter({
	selectId: (room) => room.id,
})

const classroomsSlice = createSlice({
	name: 'classrooms',
	initialState: classroomsAdapter.getInitialState({
		loading: false,
		error: null,
		rooms: [],
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getClassrooms.pending, (state) => {
				state.loading = true
			})
			.addCase(getClassrooms.fulfilled, (state, action) => {
				state.loading = false
				state.rooms = action.payload.data
			})
			.addCase(getClassrooms.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(createClassroom.pending, (state) => {
				state.loading = true
			})
			.addCase(createClassroom.fulfilled, (state, action) => {
				state.loading = false
				//state.rooms.push(action.payload)
			})
			.addCase(createClassroom.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(deleteClassroom.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteClassroom.fulfilled, (state, action) => {
				state.loading = false
				//state.rooms.push(action.payload)
			})
			.addCase(deleteClassroom.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export default classroomsSlice.reducer