import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getPersonalDB } from '../../db/services/servicePersonalDB'
import { getVideoLink } from '../../redux/slices/VideosSlice'
import { VideosService } from '../../services/VideosServices'
import { TABLET_WIDTH, useWindowWidth } from './../../hooks/useWindowWidth';

function VideosOverlay({ videos, setVideos, downloading, setDownloading }) {
	const winWidth = useWindowWidth()
	const comID = useSelector((state) => state.userInfo?.info?.company)

	const dispatch = useDispatch()

	const downloadVideo = async (video) => {
		const controller = new AbortController();
		;
		const mobResolution = video.resolution.filter((res) => parseInt(res) < 2000)[0]
		let pcResolution = video.resolution.filter((res) => parseInt(res) > 1980)[0]
		if (!pcResolution) pcResolution = video.resolution[0]
		const resolution = winWidth < TABLET_WIDTH ? mobResolution : pcResolution
		if (!resolution) return toast.error(`Video ${video.videoName} has no resolution for your device`)
		const res = await dispatch(getVideoLink({ serverName: video.serverName, company: video.companyId, resolution }))
		let promiseResolve
		if (res.payload.status === 'success') {
			try {
				const data = await VideosService.downloadVideo(video.id, res.payload.data, controller, setDownloading)
				let info = {
					...video,
					blob: data.data,
				}
				await getPersonalDB('addVideo', { videoID: video.id, blob: info })
				promiseResolve = { [video.id]: 'success' }
				toast.success(`Video ${video.videoName} downloaded`)
				VideosService.sendStatusDownloadVideo()
			} catch (e) {
				promiseResolve = { [video.id]: e }
			}
		} else {
			promiseResolve = { [video.id]: res.payload.status }
		}
		return promiseResolve
	}
	const setNull = async () => {
		setVideos([])
		setDownloading({})
	}

	const downloadVideos = async () => {
		let statuses = []
		await Promise.allSettled(videos.map(async (video) => {
			const resolve = await downloadVideo(video)
			statuses.push(resolve)
		}))
		await setNull()
		//await dispatch(getDownloadedVideos())
	}


	useEffect(() => {
		if (videos.length === 0) return
		downloadVideos()
	}, [videos])


	return (
		<></>
	)
}

export default VideosOverlay