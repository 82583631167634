import React from 'react'
import { Caption, Header } from '../../UI/Typography/Typography'
import Logo from '../Logo'

import cl from '../../styles/components/login/LoginHeader.module.css'
import { useLocation, useNavigate } from 'react-router-dom';

import deam from '../../assets/deam.svg'


function LoginHeader({ header, children }) {
	const navigate = useNavigate()
	const location = useLocation();

	function getQueryParam(param) {
		return new URLSearchParams(location.search).get(param);
	}

	const referrer = getQueryParam('referrer');

	return (
		<div className={cl.page}>
			<div className={cl.container}>
				<div className={cl.logo} onClick={() => navigate('/')}>
					<Logo />
				</div>
				{referrer?.toLowerCase() === 'deam' && <div className={cl.powered} onClick={() => window.open('https://www.digitalengineeringmagic.com/', '', 'noopener,noreferrer')}>
					<Caption>Powered by</Caption>
					<img src={deam} alt='digital engineering and magic' />
				</div>}
				<Header style={{ marginTop: 9, fontWeight: 600 }}>{header}</Header>
				{children}
			</div>
		</div>
	)
}

export default LoginHeader