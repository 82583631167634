import { createSlice } from "@reduxjs/toolkit";

const purchaseNoticeSlice = createSlice({
	name: 'purchaseNotice',
	initialState: {
		purchaseNotice: null
	},
	reducers: {
		setPurchaseNotice: (state, action) => {
			state.purchaseNotice = action.payload;
			//localStorage.setItem(`${action.payload.comID}-purchase`, JSON.stringify(action.payload))
			localStorage.setItem(`purchase`, JSON.stringify(action.payload))
		},
		getPurchaseNotice: (state, action) => {
			//let notice = localStorage.getItem(`${action.payload}-purchase`)
			let notice = localStorage.getItem(`purchase`)
			if (notice) {
				state.purchaseNotice = JSON.parse(notice);
			}
		},
		deletePurchaseNotice: (state, action) => {
			state.purchaseNotice = null;
			//localStorage.removeItem(`${action.payload}-purchase`)
			localStorage.removeItem(`purchase`)
		}
	}
})

export const { setPurchaseNotice, getPurchaseNotice, deletePurchaseNotice } = purchaseNoticeSlice.actions;
export default purchaseNoticeSlice.reducer;