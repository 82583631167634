import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PermissionService } from '../../services/PermissionServices';
import { VideosService } from '../../services/VideosServices';

export const getVideos = createAsyncThunk(
	'videos/getVideos',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const res = await VideosService.getVideosByOwner(token, data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getVideosByUser = createAsyncThunk(
	'videos/getVideosByUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, userID } = data
		try {
			const res = await VideosService.getVideosByUser(token, comID, userID)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getVideosByGroup = createAsyncThunk(
	'videos/getVideosByGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, groupName, userId } = data
		try {
			const res = await VideosService.getVideosByGroup(token, comID, groupName, userId)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getAllAvailVideos = createAsyncThunk(
	'videos/getAllAvailVideos',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, userID } = data
		try {
			const res = await VideosService.getVideosByUserAndGroup(token, comID, userID)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getVideoLink = createAsyncThunk(
	'videos/getVideoLink',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { company, serverName, resolution } = data
		try {
			const res = await VideosService.getVideoLink(token, company, serverName, resolution)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const setVideoLinks = createAsyncThunk(
	'videos/setVideoLinks',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteVideosFromGroup = createAsyncThunk(
	'videos/deleteVideosFromGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userID = localStorage.getItem('id')
		const { groupName, contentIdArr } = data
		try {
			const res = await VideosService.deleteVideosFromGroup(token, userID, groupName, contentIdArr)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const setVideosToStore = createAsyncThunk(
	'videos/setVideosToStore',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getPermissionOrientation = createAsyncThunk(
	'videos/getPermissionOrientation',
	async (data, { rejectWithValue }) => {
		try {
			const res = await PermissionService.getPermission()
			return res
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteClientVideo = createAsyncThunk(
	'videos/deleteClientVideo',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const id = localStorage.getItem('id')
		const { videoId, comID } = data
		try {
			const res = await VideosService.deleteClientVideo(token, id, comID, videoId)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const videosAdapter = createEntityAdapter({
	selectId: (video) => video.id,
})

const videosSlice = createSlice({
	name: 'videos',
	initialState: videosAdapter.getInitialState({
		loading: false,
		error: null,
		videos: null,
		videosByCompany: null,
		errorLink: null,
		videoLink: null,
		videoLinks: null,
		orientation: null,
	}),
	extraReducers: (builder) => {
		builder.addCase(getVideos.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(getVideos.fulfilled, (state, action) => {
			state.loading = false
			let vids = [...action.payload.metaenga, ...action.payload.client]
			state.videos = vids
			state.videosByCompany = vids.map((vid) => (
				{
					id: vid.id,
					companyId: vid.companyId,
				}
			))
		})
		builder.addCase(getVideos.rejected, (state, action) => {
			state.loading = false
			state.error = action.payload
		})
		builder.addCase(getVideoLink.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(getVideoLink.fulfilled, (state, action) => {
			state.loading = false
			state.videoLink = action.payload.data
		})
		builder.addCase(getVideoLink.rejected, (state, action) => {
			state.loading = false
			state.errorLink = action.payload
		})

		builder.addCase(setVideoLinks.fulfilled, (state, action) => {
			state.videoLinks = action.payload
		})

		builder.addCase(getVideosByUser.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(getVideosByUser.fulfilled, (state, action) => {
			state.loading = false
			;
			//state.videos = action.payload.data
		})
		builder.addCase(getVideosByUser.rejected, (state, action) => {
			state.loading = false
		})
		builder.addCase(getVideosByGroup.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(getVideosByGroup.fulfilled, (state, action) => {
			state.loading = false
		})
		builder.addCase(getVideosByGroup.rejected, (state, action) => {
			state.loading = false
		})
		builder.addCase(getAllAvailVideos.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(getAllAvailVideos.fulfilled, (state, action) => {
			state.loading = false
			state.videos = [...action.payload.metaenga, ...action.payload.client]
		})
		builder.addCase(getAllAvailVideos.rejected, (state, action) => {
			state.loading = false
		})

		builder.addCase(deleteVideosFromGroup.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(deleteVideosFromGroup.fulfilled, (state, action) => {
			state.loading = false
		})
		builder.addCase(deleteVideosFromGroup.rejected, (state, action) => {
			state.loading = false
		})

		builder.addCase(setVideosToStore.fulfilled, (state, action) => {
			state.videos = action.payload
		})

		builder.addCase(getPermissionOrientation.fulfilled, (state, action) => {
			state.orientation = action.payload
		})
		builder.addCase(getPermissionOrientation.rejected, (state, action) => {
			state.orientation = null
		})

		builder.addCase(deleteClientVideo.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(deleteClientVideo.fulfilled, (state, action) => {
			state.loading = false
		})
		builder.addCase(deleteClientVideo.rejected, (state, action) => {
			state.loading = false
		})

	}
})

export default videosSlice.reducer