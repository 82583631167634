import moment from "moment";
import prettyMilliseconds from "pretty-ms";

export function getSessionDurationByDate(sessions) {
	const sessionDuration = {};

	for (const session of sessions) {
		const startDate = new Date(session.timeStart);
		//const dateKey = `${startDate.getDate()}${startDate.toLocaleString('default', { month: 'short' })}${startDate.getFullYear()}`;

		const dateKey = `${startDate.getDate()} ${startDate.toLocaleString('en-GB', { month: 'short' })}`;

		if (!sessionDuration[dateKey]) {
			sessionDuration[dateKey] = 0;
		}

		const duration = Math.round((session.duration) / (1000 * 60)); // in minutes
		sessionDuration[dateKey] += duration;
	}

	const result = [];

	for (const [dateKey, duration] of Object.entries(sessionDuration)) {
		result.push({ x: dateKey, y: duration });
	}

	return result;
}


export function prepareDataForChartByWeek(data) {
	let newArr = data.map(item => {
		const endIndex = item.period.lastIndexOf(' ');
		const dateEnd = moment(item.period.substring(endIndex + 1)).format('DD MMM');
		const dateStart = moment(item.period.substring(0, 10)).format('DD');
		const year = moment(item.period.substring(endIndex + 1)).format('YYYY');
		const time = item.duration ? prettyMilliseconds(item.duration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 }) : '0 min';
		return {
			x: dateEnd,
			y: Math.round(item.duration / (1000 * 60)) || item.count || 0,
			tooltip: `${dateStart} - ${dateEnd} ${year}`,
			time: time
		}

	})
	return newArr;
}

//prepareDataForChartByWeek([])


export function prepareDataForChartByDays(data) {
	let newArr = data.map(item => {
		const date = moment(item.date).format('DD MMM');
		const year = moment(item.date).format('YYYY');
		const time = item.duration ? prettyMilliseconds(item.duration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 }) : '0 min';
		return {
			x: date,
			y: Math.round(item.duration / (1000 * 60)) || item.count || 0,
			tooltip: `${date} ${year}`,
			time: time
		}
	})
	return newArr;
}

export function newPrepareDataForChartByDays(data) {
	let newArr365 = data.map(item => {
		const name = moment(item.date).format('MMM YY')

		const date = moment(item.date).format('DD MMM');
		const year = moment(item.date).format('YYYY');
		const time = item.totalDuration ? prettyMilliseconds(item.totalDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 }) : '0 min';
		return {
			name: name,
			uv: Math.round(item.totalDuration / (1000 * 60)) || item.count || 0,
			tooltip: `${date} ${year}`,
			time: time
		}
	})

	let newArrDays = data.map(item => {
		const date = moment(item.date).format('DD MMM');
		const year = moment(item.date).format('YYYY');
		const time = item.totalDuration ? prettyMilliseconds(item.totalDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 }) : '0 min';
		return {
			name: date,
			uv: Math.round(item.totalDuration / (1000 * 60)) || item.count || 0,
			tooltip: `${date} ${year}`,
			time: time
		}
	})

	let newArr90 = []
	let newArr30 = []
	let newArr7 = []

	newArrDays.reverse().forEach((item, index) => {
		if (index < 7) {
			newArr7.push(item)
			newArr30.push(item)
			newArr90.push(item)
		} else if (index < 30) {
			newArr30.push(item)
			newArr90.push(item)
		} else if (index < 90) {
			newArr90.push(item)
		}
	})


	const ticks = new Map();
	newArr365.forEach(item => {
		if (!ticks.has(item.name)) {
			ticks.set(item.name, item.name);
		}
	});

	const newTicks = Array.from(ticks.values());
	const newTicksMob = newTicks.filter((_, index) => index % 2 === 0)

	return {
		365: newArr365,
		90: newArr90.reverse(),
		30: newArr30.reverse(),
		7: newArr7.reverse(),
		ticks: newTicks,
		ticksMob: newTicksMob,
	};
}

//prepareDataForChartByDays([])


export function prepareDataForChartByMonth(data) {
	let newArr = data.map(item => {
		const month = moment(item.period.substring(0, 10), 'DD.MM.YYYY').format('MMM YYYY');
		const time = item.duration ? prettyMilliseconds(item.duration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 }) : '0 min';
		return {
			x: month,
			y: Math.round(item.duration / (1000 * 60)) || item.count || 0,
			tooltip: month,
			time: time
		}
	})
	return newArr;
}

export function getSessionDurationLastWeekByDays(data) {
	// Создаем объект для хранения данных о сессиях по дням
	const sessionsByDay = {};

	// Получаем текущую дату
	const currentDate = new Date();
	;
	// Проходимся по всем сессиям
	data.forEach(session => {
		// Получаем дату начала сессии
		const startDate = new Date(session.timeStart);

		// Получаем дату в формате гггг-мм-дд для использования как ключ в объекте sessionsByDay
		const dateKey = startDate.toISOString().slice(0, 10);

		// Если дата еще не была добавлена в объект sessionsByDay, создаем новый объект с данными по этой дате
		if (!sessionsByDay[dateKey]) {
			sessionsByDay[dateKey] = {
				count: 0,
				duration: 0
			};
		}

		// Увеличиваем количество сессий и длительность сессий в объекте sessionsByDay для данной даты
		sessionsByDay[dateKey].count++;
		sessionsByDay[dateKey].duration += Math.round(parseInt(session.duration) / 60000)
	});

	// Создаем массив объектов с данными о количестве и длительности сессий по дням
	const sessionsByDayArray = Object.keys(sessionsByDay).map(dateKey => {
		const date = new Date(dateKey);
		const x = `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'short' })}`;
		const y = sessionsByDay[dateKey].duration;
		return { x, y };
	});

	// Сортируем массив по датам в порядке возрастания
	sessionsByDayArray.sort((a, b) => new Date(a.x) - new Date(b.x));

	//// Разворачиваем массив в порядке убывания дат
	//sessionsByDayArray.reverse();

	// Получаем последние 7 дней
	const last7Days = [...Array(7)].map((_, i) => {
		const date = new Date();
		date.setDate(currentDate.getDate() - i);
		return date;
	});

	// Создаем массив объектов для вывода
	const outputArray = last7Days.map(date => {
		const dateKey = date.toISOString().slice(0, 10);
		const tooltip = moment(date).format('DD MMM YYYY');
		const x = `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'short' })}`;
		const y = sessionsByDay[dateKey] ? sessionsByDay[dateKey].duration : 0;
		return { x, y, tooltip };
	});

	outputArray.reverse();

	;

	return outputArray;

}



export function getSessionDurationByHour(data) {
	//рабочий вывод сессий по часам в минутах

	const hourData = Array.from({ length: 24 }, (v, i) => ({
		x: i > 11 ? `${i - 12} pm` : `${i} am`,
		y: 0
	}));

	data.forEach(session => {
		// Получаем час начала и конца сессии
		let startHour = new Date(session.timeStart).getHours();
		let endHour = new Date(session.timeEnd).getHours();

		// Корректируем час начала, если он больше 12
		if (startHour > 12) {
			startHour = startHour - 12;
		}

		// Корректируем час конца, если он больше 12
		if (endHour > 12) {
			endHour = endHour - 12;
		}

		// Определяем am или pm для начала и конца сессии
		const startPeriod = startHour >= 12 ? 'pm' : 'am';
		const endPeriod = endHour >= 12 ? 'pm' : 'am';

		// Добавляем длительность сессии к соответствующим объектам в массиве hourData
		if (startHour === endHour) {
			hourData[startHour].y += parseInt(session.duration / 60000); // in minutes
		} else {
			hourData[startHour].y += parseInt((new Date(session.timeStart).setMinutes(0, 0, 0) + 3600000 - new Date(session.timeStart)) / 60000);
			hourData[endHour].y += parseInt((new Date(session.timeEnd).setMinutes(0, 0, 0) - new Date(session.timeEnd)) / 60000);
			for (let i = startHour + 1; i < endHour; i++) {
				hourData[i].y += 60;
			}
		}
	});


	//// Удаляем объекты с нулевой длительностью
	//for (let i = hourData.length - 1; i >= 0; i--) {
	//	if (hourData[i].y === 0) {
	//		hourData.splice(i, 1);
	//	}
	//}
	;
	return hourData;
}
