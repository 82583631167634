import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../Typography/Typography';
import cl from './UserColumn.module.css';

import user from '../../assets/skeleton/user_skeleton.png';

interface UserColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  rowData: {
    id: number;
    trainingSessionId?: number;
    name?: string;
    userName?: string;
    email?: string;
    userEmail?: string;
    avatar?: string;
  };
  lite?: boolean;
}

const UserColumn: React.FC<UserColumnProps> = memo(function UserColumn({ rowData, lite = false }) {
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(user);

  const onPreventCheck = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`user-${rowData.id}`, {replace: true});
  };

  const checkImageExists = async (url: string): Promise<string | false> => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok ? url : false;
    } catch (error) {
      return false;
    }
  };

  const getAvatar = async () => {
    if (!rowData.avatar) {
      setAvatar(user);
      return;
    }
    const imageUrl = `${process.env.REACT_APP_MAIN_API_URL}/avatar/${rowData.avatar}`;
    const imageExists = await checkImageExists(imageUrl);
    setAvatar(imageExists || user);
  };

  useEffect(() => {
    getAvatar();
  }, [rowData.avatar]);

  return (
    <div className={cl.row} key={rowData.trainingSessionId || rowData.id}>
      <img
        src={avatar}
        alt={rowData.name || rowData.userName}
        style={{
          width: 35,
          height: 35,
          borderRadius: '50%',
          marginRight: 10,
        }}
      />
      <div className={cl.name} onClick={onPreventCheck}>
        <Paragraph>{rowData.name || rowData.userName}</Paragraph>
        <a
          className="link"
          style={{ position: 'relative', cursor: 'pointer' }}
        >
          {rowData.email || rowData.userEmail}
          {!lite && (
            <i
              className="icon-chevron-right"
              style={{
                fontSize: 20,
                position: 'absolute',
                bottom: '-2px',
                right: '-18px',
                color: 'var(--field-color)',
              }}
            />
          )}
        </a>
      </div>
    </div>
  );
});

export default UserColumn;
