import cl from './AccessDenialOverlay.module.css';
import { Paragraph } from '../Typography/Typography';
import img from '../../assets/icons/sad-email.svg';

interface AccessDenialOverlayProps {} 

const AccessDenialOverlay: React.FC<AccessDenialOverlayProps> = () => {
  return (
    <div className={cl.overlay}>
      <div className={cl.container}>
        <div className={cl.img}>
          <img src={img} alt="sad email" />
        </div>
        <Paragraph><span>Your email is not verified yet</span></Paragraph>
        <Paragraph>To unlock all features, <br /> please verify your email <br /> in Settings/Account</Paragraph>
      </div>
    </div>
  );
}

export default AccessDenialOverlay;
