import axios from "axios";


export class StatisticVideosService {
	// остров
	static getAvgViewingTimeVideo(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/average/viewing/time/video/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getComplitionViewingVideoRate(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/completion/rate/video/sessions/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}



	static getTotalCountVideoViewed(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/total/video/viewed/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	// chart


	static getVideosStatistic365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/video/sessions/count/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	// table
	static getVideosData(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/video/data/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	// send statistic
	static sendVideoStart(token, userId, videoId) {
		return axios.post(process.env.REACT_APP_MAIN_STATISTIC_URL + `/videoSessionStart`, {
			userId,
			videoId,
			webOrVr: 1
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendVideoEnd(token, userId, videoSessionId, watchedVideo) {
		return axios.post(process.env.REACT_APP_MAIN_STATISTIC_URL + `/videoSessionEnd`, {
			userId,
			videoSessionId,
			watchedVideo
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}