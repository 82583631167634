import React from 'react'
import { Paragraph } from '../../../UI/Typography/Typography'
import Button from '../../../UI/Button/Button'

import cl from './TourTooltipe.module.css'

function TourTooltipe({
	continuous,
	index,
	step,
	backProps,
	closeProps,
	skipProps ,
	primaryProps,
	tooltipProps,
	size,
}) {

	return (
		<div className={cl.tooltipe} {...tooltipProps}>
			{step.title && <Paragraph style={{ fontWeight: 600, color: '#fff', marginBottom: 8 }}>{step.title}</Paragraph>}
			<Paragraph>{step.content}</Paragraph>
			<div className={cl.footer}>
				<div className={cl.footer__steps}>
					<span>{index + 1}</span>/{size}
				</div>
				<div className={cl.buttons}>
					{index === 0 && (
						<Button texthover  {...skipProps}>
							<span id="close" >Skip</span>
						</Button>
					)}
					{index > 0 && (
						<Button texthover {...backProps}>
							<span id="back" >Back</span>
						</Button>
					)}
					{continuous && index !== size - 1 && (
						<Button {...primaryProps}>
							<span id="next" >Next</span>
						</Button>
					)}
					{index === size - 1 && (
						<Button {...closeProps}>
							<span id="close" >Done</span>
						</Button>
					)}
				</div>

			</div>
		</div>
	)
}

export default TourTooltipe