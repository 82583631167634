import axios from "axios"


export class CompanyInfoService {
	static getCompanyInfo(token, id) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getCompanyInfo/${id}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static editCompanyInfo(token, id, data) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/editCompanyInfo/${id}`, data, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static uploadCompanyLogo(token, id, data) {
		const formdata = new FormData();
		formdata.append("file", data);
		return axios({
			method: 'post', 
			url: process.env.REACT_APP_MAIN_API_URL + `/upload/logo/${id}`, 
			data: formdata,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}
		})
	}
	static deleteCompanyLogo(token, id) {
		return axios.delete(process.env.REACT_APP_MAIN_API_URL + `/deleteLogo/${id}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}