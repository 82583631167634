import React from 'react'
import { Caption, Paragraph } from '../../../UI/Typography/Typography'

import cl from './PlatformLinks.module.css'

function PlatformLinks({ lite = false, ...props }) {
	return (
		<div className={cl.platform} id='platform' {...props}>
			{!lite && <Paragraph>Download platform</Paragraph>}
			<div className={cl.platform__links}>
				<a href='https://www.oculus.com/experiences/quest/4950000178436188' target='_blank' className={cl.platform__link}>
					<i className='icon-logo_meta' />
					<div className={cl.text}>
						<Caption>Download for </Caption>
						<Paragraph>Quest</Paragraph>
					</div>
				</a>
				<a href='https://store-global.picoxr.com/global/detail/1/7234057402927333382' target='_blank' className={cl.platform__link}>
					<i className='icon-logo_Pico' />
					<div className={cl.text}>
						<Caption>Download for </Caption>
						<Paragraph>Pico</Paragraph>
					</div>
				</a>
			</div>
		</div>
	)
}

export default PlatformLinks