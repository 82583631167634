import React from 'react'
import { Caption, Paragraph } from '../../UI/Typography/Typography'

import cl from '../../styles/pages/Signup.module.css'

function UserInfo({info}) {
	return (
		<>
			<Paragraph style={{ marginTop: 17 }}>{info?.companyName}</Paragraph>
			<div className={cl.mail}>
				<i className='icon-email'></i>
				<Caption>{info?.email}</Caption>
			</div>
		</>
	)
}

export default UserInfo