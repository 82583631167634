import { useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useDispatch, useSelector } from 'react-redux'
import { sendPassRecovery, verifyCode } from '../../redux/slices/UserSettingsSlice'

import classNames from 'classnames'
import cl from '../../styles/components/forms/ChangePas.module.css'
import Button from '../../UI/Button/Button'
import Input from '../../UI/input/Input'
import { Paragraph } from '../../UI/Typography/Typography'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function SendCode({ setSendNewPass, setInfo }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const settingsState = useSelector((state) => state.settings)
	const { loadingSettings, errorSettings } = settingsState

	const [sent, setSent] = useState(false)
	const [email, setEmail] = useState('')
	const [code, setCode] = useState('')
	const [onError, setOnError] = useState('')

	const sendEmail = async () => {
		const res = await dispatch(sendPassRecovery(email))
		let status = res?.payload?.status
		if (res?.payload?.status === 'code updated' || res?.payload.status === 200) {
			setSent(true)
			toast.success('Code sent')
		} else if (res.payload === 404) {
			setOnError('Email not found')
		} else if (res.payload === 400) {
			setOnError('Something went wrong')
		}

	}

	const sendCode = async () => {
		const res = await dispatch(verifyCode({ email, code }))
		if (res?.payload.status === 200 || res?.payload.status === 'success') {
			setInfo({
				company: res.payload.company,
				email: res.payload.data.email,
				code: res.payload.data.code
			})
			setSendNewPass(true)
		} else if (res.payload === 400) {
			setOnError('Something went wrong')
			toast.error('Something went wrong')
		} else if (res.payload === 403) {
			setOnError('Wrong code')
			toast.error('Wrong code')
		} else if (res.payload === 404) {
			setOnError('Email not found')
			toast.error('Email not found')
		}
	}

	const onSend = () => {
		if (email) {
			sendEmail()
		} else {
			setOnError('Login is req')
		}
	}

	const onSendCode = () => {
		if (email && code.length === 6) {
			sendCode()
		}
		if (code.length !== 6) {
			setOnError('Code is not valid')
		}
	}
	const onChangeEmail = (e) => {
		setEmail(e.target.value)
		setOnError('')
	}

	const emailForm = <>
		<div className={cl.form}>
			<Paragraph>We will send you an email <br />
				with a 6-digit reset-password code</Paragraph>
			<div className={cl.input}>
				<Input
					autoComplete='email'
					err={onError}
					helperText={onError}
					label='Email'
					value={email}
					onChange={onChangeEmail} />
			</div>
			<div className={cl.button}>
				<Button secondary onClick={() => navigate('/')}>Back</Button>

				<Button onClick={onSend} loading={loadingSettings}>Send</Button>

			</div>
		</div>
	</>


	const styleCodeInput = {
		className: ReactCodeInput,
		inputStyle: {
			margin: '8px',
			MozAppearance: 'textfield',
			width: '40px',
			borderRadius: '6px',
			fontSize: '16px',
			height: '40px',
			paddingLeft: '15px',
			color: 'var(--textdark-color)',
			border: '1px solid var(--field-color)'
		},
		inputStyleInvalid: {
			margin: '8px',
			MozAppearance: 'textfield',
			width: '40px',
			borderRadius: '6px',
			fontSize: '16px',
			height: '40px',
			paddingLeft: '15px',
			color: 'var(--textdark-color)',
			border: '1px solid var(--error-color)'
		}
	}

	const onChangeCode = (e) => {
		setOnError('')
		setCode(e)
	}

	const codeForm = <>
		<div className={cl.form}>
			<Paragraph>A 6-digit reset-password code has been <br /> sent to your email</Paragraph>
			<div className={classNames(cl.input, cl.code)}>
				<ReactCodeInput isValid={!errorSettings && !onError} type='tel' fields={6} value={code} onChange={onChangeCode} {...styleCodeInput} />
			</div>
			<div className={cl.button}>
			<Button secondary onClick={() => setSent(false)}>Back</Button>
				<Button onClick={onSendCode} loading={errorSettings} disabled={code.length !== 6 || loadingSettings}>Send</Button>
			</div>
			<div className={cl.support}>
				<Paragraph>Haven't got an email in 5 minutes? Please, <br /> contact our support  <a href='support@metaenga.com'>support@metaenga.com</a></Paragraph>
			</div>
			<ToastContainer
				theme='colored'
				position="bottom-right"
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</div>
	</>
	return (
		sent
			? codeForm
			: emailForm

	)
}

export default SendCode