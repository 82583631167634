import axios from "axios"


export class StatisticOverviewService {
	// остров
	static getTotalUsers(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/total/users/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getTotalTrainings(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/total/trainings/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getTrainingSessions365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/completed/vr/training/session/count/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getActiveUsers365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/active/users/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getTrainingSessionsTime365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/app/session/duration/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

}