import axios from "axios";


export class PersonalVRStatisticService {

	//new chart 
	static getTrainingSessions365Personal(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/training/session/count/by/user/year/${comID}/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	//table 
	static getTrainingTablePersonal(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/vr/training/data/by/user/${comID}/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	//table training sessions
	static getTrainingSessionsTablePersonal(token, comID, userId, trainingId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/training/session/data/by/user/${comID}/${userId}/${trainingId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


}