export const getShortName = (name) => {
	const names = name.split(' ')
	if (names.length === 1) {
		const capital = names[0].split('').filter(a => a.match(/[A-Z]/)).join('')
		if (capital.length > 1) {
			return capital[0] + capital[1]
		} else {
			return names[0].charAt(0)
		}
	} else {
		const shortName = names[0][0] + names[1][0]
		return shortName
	}
}