import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { uid } from 'uid';

import cl from './Switch.module.css';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>; //скорочено від (е : React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, children, ...props }) => {
  const id = uid(8);

  return (
    <div className={cl.container}>
      <label className={cl.switch}>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} {...props} />
        <span className={classNames(cl.slider, cl.round)}></span>
      </label>
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default Switch;
