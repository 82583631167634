import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../Typography/Typography';
import cl from './BackButton.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../tooltip/Tooltip';
import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';

interface BackButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  nav?: string; 
  callback?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  title,
  nav,
  callback,
  className, 
  style, 
  ...props 
}) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const textRef = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const winWidth = useWindowWidth();

  const onClick = () => {
    if (nav) {
      navigate(nav);
    } else if (callback) {
      callback();
    } else {
      const lastIndex = path.lastIndexOf('/');
      const result = path.substring(0, lastIndex);
      navigate(result);
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current && hiddenTextRef.current) {
        const isOverflowing =
          hiddenTextRef.current.offsetWidth > textRef.current.offsetWidth;
        setIsOverflow(isOverflowing);
      }
    };

    if (winWidth > TABLET_WIDTH) {
      checkOverflow();
    } else {
      setIsOverflow(false);
    }

    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [title, winWidth]);

  return (
    <div
      className={classNames(cl.button, className)} // className додається з HTMLAttributes
      style={style} // style додається з HTMLAttributes
      onClick={props.onClick || onClick} // onClick також може прийти через props
      {...props} // розповсюджуємо всі інші пропси
    >
      <i className="icon-arrow-left" />
      <div data-tip data-for="header-name" ref={textRef} className={cl.buttonText__container}>
        <Header>{title}</Header>
        <div ref={hiddenTextRef} className={cl.hidden__text}>
          {title}
        </div>
      </div>
      {isOverflow && <Tooltip id="header-name">{title}</Tooltip>}
    </div>
  );
};

export default BackButton;
