import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { InvitesService } from './../../services/InvitesServices';

export const sendInvites = createAsyncThunk(
	'invites/sendInvites',
	async (data, { rejectWithValue }) => {
		const { email, role, companyName } = data
		const id = localStorage.getItem('id')
		const token = localStorage.getItem('token')
		try {
			const res = await InvitesService.sendInvite(token, id, email, role, companyName)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getInvites = createAsyncThunk(
	'invites/getInvites',
	async (data, { rejectWithValue }) => {
		const id = localStorage.getItem('id')
		const token = localStorage.getItem('token')
		try {
			const res = await InvitesService.getInvites(token, id)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteInvites = createAsyncThunk(
	'invites/deleteInvites',
	async (data, { rejectWithValue }) => {
		const { regtoken, role } = data
		const id = localStorage.getItem('id')
		const token = localStorage.getItem('token')
		try {
			const res = await InvitesService.deleteInvite(token, id, regtoken, role)	
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const invitesAdapter = createEntityAdapter({
	selectId: (info) => uid(8),
})

const invitesSlice = createSlice({
	name: 'invites',
	initialState: invitesAdapter.getInitialState({
		loading: false,
		error: null,
		invites: null
	}),
	extraReducers: (builder) => {
		builder
			.addCase(sendInvites.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(sendInvites.fulfilled, (state, action) => {

				state.loading = false
			})
			.addCase(sendInvites.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getInvites.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(getInvites.fulfilled, (state, action) => {
				state.loading = false
				const admins = action.payload?.ADMIN || [] // admin can't get admins invites
				const enhanced = action.payload?.ENHANCED || []
				const invites = [...admins, ...enhanced]
				state.invites = invites.map((invite) => ({...invite, role: invite.userrole, lastActivity: invite.date, id: uid(16)}))
			})
			.addCase(getInvites.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(deleteInvites.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(deleteInvites.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(deleteInvites.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export default invitesSlice.reducer