import React, { useEffect, useRef } from 'react'
import { toPng } from "html-to-image";

import cl from './PaymentReceipt.module.css'

function PaymentReceipt() {
	const urlParams = new URLSearchParams(window.location.search).get('data');
	const data = JSON.parse(decodeURIComponent(urlParams));
	const { payment_id, masked_card, order_id, order_time, currency, amount, response_status, payment_system, card_type } = data

	const receiptRef = useRef(null);

	const handleDownload = () => {
		if (receiptRef.current) {
			toPng(receiptRef.current, { cacheBust: false })
			.then((dataUrl) => {
			  const link = document.createElement("a");
			  link.download = "my-image-name.png";
			  link.href = dataUrl;
			  link.click();
			})
			.catch((err) => {
			
			});
		}
	};

	useEffect(() => {
		if (data) {
			handleDownload()
		}
	}, [data])

	return (
		<div className={cl.container}>
			<div ref={receiptRef} style={{
				width: '400px',
				height: '600px',
				backgroundColor: 'white',
				padding: '20px',
			}}>
				<h1>Payment Receipt</h1>
				<div style={{
					width: '100%',
					height: '1px',
					backgroundColor: 'black',
					margin: '20px 0'

				}}></div>
				<div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment ID
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{payment_id}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment Date
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{order_time}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment recipien
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							app.metaenga.com

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Order Number
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{order_id}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Card Number
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{masked_card}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment Amount
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{amount / 100} {currency}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment Status
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{response_status}

						</p>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0'
					}}>
						<p style={{
							fontWeight: 'bold',
							color: 'gray',
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'

						}}>
							Payment Sistem
						</p>
						<p style={{
							flexBasis: '50%',
							flexShrink: '0',
							flexGrow: '0'
						}}>
							{payment_system}, {card_type}

						</p>
					</div>
				</div>
				<div style={{
					width: '100%',
					height: '1px',
					backgroundColor: 'black',
					margin: '20px 0'

				}}></div>

			</div>
		</div>
	)
}

export default PaymentReceipt