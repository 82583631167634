import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import Overlay from '../overlay/Overlay';
import cl from './Modal.module.css';

import close from '../../assets/icons/close.svg';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
  propagination?: boolean;
  callback?: () => void;
  closeicon?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  modalIsOpen,
  setIsOpen,
  children,
  propagination = true,
  callback, 
  closeicon = false,
  className,
  style,
  ...props
}) => {
  const onCloseModal = () => {
    setIsOpen(false);
    if (callback) callback();
  };

  return (
    <Overlay callback={callback || undefined} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <div
        style={style}
        className={classNames(cl.modal, className)}
        onClick={(e) => propagination && e.stopPropagation()}
        {...props}
      >
        {children}
        {closeicon && (
          <img
            className={cl.close}
            src={close}
            alt="Close modal"
            onClick={onCloseModal}
          />
        )}
      </div>
    </Overlay>
  );
};

export default Modal;
