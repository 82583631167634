import React from 'react'
import { uid } from 'uid'
import Tooltip from '../tooltip/Tooltip'

import cl from './TooltipIcon.module.css'

function TooltipIcon({ tooltip, place = 'bottom', icon = 'icon-circle-info', ...props }) {
	const id = uid(8)
	return (
		<div className={cl.tooltip__info}>
			<i data-tip data-for={id} className={icon} />
			<Tooltip id={id} place={place} {...props}>{tooltip}</Tooltip>
		</div>
	)
}

export default TooltipIcon