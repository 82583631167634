import axios from "axios";


export class StatisticUsersService {

	// остров
	static getAvgVRTrainingSessionPerUser(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/average/vr/training/session/per/user/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getAvgVRCompletedTrainingSessionPerUser(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/completion/rate/sessions/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	// table
	static getUsersData(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/users/data/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	//chart

	static getWebSessionsDuration365(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/app/web/session/duration/year/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}