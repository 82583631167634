import React from "react";
import { Paragraph } from "../Typography/Typography";
import error_img from "../../assets/png/error.png";
import cl from './SomethingWentWrong.module.css';
import Button from "../Button/Button";

interface SomethingWentWrongProps {
  onGetSessions: () => void; // Типізація для функції
}

const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({ onGetSessions }) => {
  return (
    <div className={cl.container__row}>
      <div className={cl.img__wrapper}>
        <img src={error_img} alt="empty card" />
      </div>
      <div className={cl.title__wrapper}>
        <Paragraph>Something went wrong</Paragraph>
      </div>
      <div className={cl.text__wrapper}>
        <Paragraph>
          Please try again later <br/> or return to the homepage.
        </Paragraph>
      </div>
      <div className={cl.button_wrapper}>
        <Button onClick={onGetSessions}>Try again</Button>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
