import React from 'react'
import Button from '../../UI/Button/Button'
import { Paragraph, TextError } from '../../UI/Typography/Typography'

import cl from '../../styles/components/forms/InviteNotFound.module.css'

function InviteNotFound() {
	return (
		<>
			<TextError className={cl.err}>Your invitation link has expired</TextError>
			<Button className={cl.btn} disabled >Sign up</Button>
			<Paragraph className={cl.login}>Already have an account? <a href='/'>Log in</a></Paragraph>
		</>
	)
}

export default InviteNotFound