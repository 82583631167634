import { toast } from "react-toastify";

export class VideoListDB {
	static async addVideoList(db, list) {
		const tx = db.transaction('videoList', 'readwrite');
		const store = tx.objectStore('videoList');
		await store.put(list, 'list');
		await tx.done;
	}
	static async getVideoList(db) {
		let res
		try{
			res = await db.get('videoList', 'list').then(data => data)
			if(res === undefined) {
				throw new Error('Video list if undefined')
			}
		} catch (e) {
			toast.error(e.message)
			res = new Error(e)
		}
		return res
	}
}