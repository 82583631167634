import axios from 'axios';

export class TeamsService {
	static createTeam(token, userId, groupName) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/createGroup`, {
			userId,
			groupName
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static addToTeam(token, userId, groupName, userArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addToGroup`, {
			userId,
			groupName,
			"userArr": userArr
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getTeams(token, companyId) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getCompanyGroups/${companyId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static deleteTeam(token, userId, groupName) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteGroup`, {
			userId,
			groupName
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static addContentToTeam(token, userId, groupName, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addContentToGroup`, {
			userId,
			groupName,
			contentIdArr,
			"assign": 0
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static addMetaengaContentToTeam(token, userId, groupName, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addContentToGroup`, {
			userId,
			groupName,
			contentIdArr,
			"assign": 1
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

}