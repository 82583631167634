import React from 'react'

import icon from '../../assets/icons/notice.svg'

import cl from './NoticeBlock.module.css'

function NoticeBlock({message, buttons, type, props}) {
	return (
		<div className={cl.wrap}>
			<div className={cl.block}>
				{type === 'expired' && <img src={icon} alt="notice" />}
				{message}
			</div>
			<div className={cl.buttons}>
				{buttons}
			</div>
		</div>
	)
}

export default NoticeBlock