import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { UserInfoService } from '../../services/UserInfoServices'

export const fetchUserInfo = createAsyncThunk(
	'userInfo/fetchUserInfo',
	async (data, { rejectWithValue }) => {
		const { id } = data
		const token = localStorage.getItem('token')
		try {
			const response = await UserInfoService.getInfo(token, id)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.data)
		}
	}
)

export const fetchAnotherUserInfo = createAsyncThunk(
	'userInfo/fetchAnotherUserInfo',
	async (id, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const response = await UserInfoService.getInfo(token, id)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.data)
		}
	}
)

export const editUserInfo = createAsyncThunk(
	'userInfo/editUserInfo',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		//const id = localStorage.getItem('id')
		try {
			const response = await UserInfoService.editInfo(token, data)
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const uploadAvatar = createAsyncThunk(
	'userInfo/uploadAvatar',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const id = localStorage.getItem('id')
		try {
			const response = await UserInfoService.uploadAvatar(token, id, data)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteAvatar = createAsyncThunk(
	'userInfo/deleteAvatar',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const id = localStorage.getItem('id')
		try {
			const response = await UserInfoService.deleteAvatar(id, token)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const userInfoAdapter = createEntityAdapter({
	selectId: (userInfo) => userInfo.email,
})

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: userInfoAdapter.getInitialState({
		info: {},
		anotherUser: {},
		changingInfo: '',
		loadingInfo: false,
		errorInfo: null
	}),
	reducers: {
		setInfo: (state, action) => {
			userInfoAdapter.setMany(state, action.payload)
		},
		editSomeInfo: (state, action) => {
			userInfoAdapter.updateOne(state.info, action.payload)
		},
		setChangingInfo: (state, action) => {
			state.changingInfo = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserInfo.pending, (state, action) => {
				state.errorInfo = null
				state.loadingInfo = true
			})
			.addCase(fetchUserInfo.fulfilled, (state, action) => {
				state.loadingInfo = false
				state.info = action.payload.data
			})
			.addCase(fetchUserInfo.rejected, (state, action) => {
				state.loadingInfo = false
				if (action.payload === 400) {
					state.errorInfo = 400
				}
				if (action.payload === 403) {
					state.errorInfo = 403
				}
			})

			.addCase(fetchAnotherUserInfo.pending, (state, action) => {
				state.errorInfo = null
				state.loadingInfo = true
			})
			.addCase(fetchAnotherUserInfo.fulfilled, (state, action) => {
				state.loadingInfo = false
				state.anotherUser = action.payload.data
			})
			.addCase(fetchAnotherUserInfo.rejected, (state, action) => {
				state.loadingInfo = false
			})
			
			.addCase(editUserInfo.pending, (state, action) => {
				state.errorInfo = null
				state.loadingInfo = true
			})
			.addCase(editUserInfo.fulfilled, (state, action) => {
				if(action.payload.id === state.info.id) {
					state.info = { ...state.info, ...action.payload }
				} else {
					state.anotherUser = { ...state.anotherUser, ...action.payload }
				}
				//state.info = { ...state.info, ...action.payload }
				state.loadingInfo = false

			})
			.addCase(editUserInfo.rejected, (state, action) => {
				state.loadingInfo = false
				state.errorInfo = action.payload
			})

			.addCase(uploadAvatar.pending, (state, action) => {
				state.info.avatar = null
				state.errorInfo = null
				state.loadingInfo = true
			})
			.addCase(uploadAvatar.fulfilled, (state, action) => {
				if (action.payload.status === 'success') {
					const id = localStorage.getItem('id')
					state.info.avatar = `${id}.jpg`
				}
				state.loadingInfo = false
			})
			.addCase(uploadAvatar.rejected, (state, action) => {
				//get avatar
				state.loadingInfo = false
				state.errorInfo = action.payload
			})

			.addCase(deleteAvatar.pending, (state, action) => {
				state.errorInfo = null
				state.loadingInfo = true
			})
			.addCase(deleteAvatar.fulfilled, (state, action) => {
				state.info.avatar = null
				state.loadingInfo = false
			})
			.addCase(deleteAvatar.rejected, (state, action) => {
				state.loadingInfo = false
				state.errorInfo = action.payload
			})
	}
})

export const userInfoSelectors = userInfoAdapter.getSelectors((state) => state)

export const { setInfo } = userInfoSlice.actions

export default userInfoSlice.reducer