import React from 'react'

import classNames from 'classnames'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './MenuItem.module.css'

const MenuItemExpanded = React.memo(({ title, iconClass, active, location, pathArr, ...props }) => {
	const isStrHasSubstr = (str, pathArr) => {
		let includes = false
		pathArr.forEach(path => {
			if (str.includes(path)) includes = true
		})
		return includes
	}
	
	return (
		<div className={cl.container} {...props}>
			<i className={classNames(active ? 'icon-chevron-up' : 'icon-chevron-down', cl.chevron)} />
			<div className={classNames(cl.title, active && cl.active, isStrHasSubstr(location, pathArr) && cl.active)}>
				<i className={iconClass} />
				<Paragraph>{title}</Paragraph>
			</div>
		</div>
	)
})

export default MenuItemExpanded