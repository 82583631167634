import axios from "axios";

export class UsersService {
	static getUsers(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getCompanyUsers/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static deleteUser(adminId, userId, email, token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/delete/user`, {
			adminId,
			userId,
			email
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static activateUser(adminId, userId, email, token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/activate/user`, {
			adminId,
			userId,
			email
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static deactivateUser(adminId, userId, email, token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deactivate/user`, {
			adminId,
			userId,
			email
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static addContentToUser(token, userId, toUser, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addContentToUser`, {
			userId,
			toUser,
			contentIdArr
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static addMetaengaContentToUser(token, company, user, video) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/video/add/to/user`, {
			company,
			user,
			video
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static removeContentFromUser(token, userId, toUser, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteAccessFromUsers`, {
			userId,
			toUser,
			contentIdArr
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static removeMetaengaContentFromUser(token, company, video, user) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/video/remove/from/user`, {
			company,
			video,
			user
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static changeRole(token, adminId, changeId, role) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/changeRole`, {
			adminId,
			changeId,
			role
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

}
