import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../UI/Button/Button'
import RoleChips from '../../UI/roleChips/RoleChips'
import Switch from '../../UI/switch/Switch'
import { Caption, Header, Paragraph, TextError } from '../../UI/Typography/Typography'
import classNames from 'classnames'

import cl from './InviteModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { sendInvites } from './../../redux/slices/InvitesSlice';

function InviteModal({ setModalIsOpen, header, textButton = 'OK', loading = false, callback, ...props }) {
	const invitesState = useSelector((state) => state.invites)
	const iRole = useSelector((state) => state.userInfo.info.role)
	const companyName = useSelector((state) => state.company.info.companyName)
	const { error } = invitesState
	const dispatch = useDispatch()

	const [mails, setMails] = useState([])
	const [inputValue, setInputValue] = useState('')

	const onClick = () => {
		onInvite()
	}
	const onCancel = () => {
		setModalIsOpen(false)
		setMails([])
	}

	const onInputValueChange = (e) => {
		if (err) setErr('')
		if (e.target.value.includes(',') || e.target.value.includes(' ') || e.type === 'blur') {
			const newMails = e.target.value.split(/,| /).filter(item => item !== '' && item !== ' ')
			setMails([...mails, ...newMails])
			setInputValue('')
		} else {
			setInputValue(e.target.value)
		}
	}

	const onSwich = () => {
		if (iRole !== "OWNER") {
			toast.warn('The invitation of administrators is restricted to the owner only')
			return
		}
		setAdminOn(!adminOn)
	}

	const [adminOn, setAdminOn] = useState(false)
	const [sending, setSending] = useState(false)
	const [err, setErr] = useState('')

	const onSendInvites = async (email, role) => {
		const res = await dispatch(sendInvites({ email, role, companyName }))
		if (res.payload.status === 201 || res.payload.status === 'success') {
			const newInvitesList = mails.slice(0, mails.length - 1)
			setMails(newInvitesList)
			return true
		}
		if (res.payload === 400 || res.payload === 'error') {
			setErr('Something went wrong')
			return
		} else if (res.payload === 401) {
			setErr(`Email ${email} is already registered`)
			return
		} else if (res.payload === 404) {
			setErr('Admin not found')
			return
		} else if (res.payload === 402) {
			setErr("Upgrade your plan to add another user due to insufficient licenses.")
			return
		} else if (res.payload === 403 && res.payload.status === 'limit exceeded') {
			setErr(`You have run out of available licenses`)
			return
		} else if (res.payload === 403) {
			setErr(`Email ${email} is not valid`)
			return
		} else {
			setErr('Something went wrong')
			return
		}

	}

	const onInvite = () => {
		if (mails.length === 0) {
			toast.error('Please add at least one invite')
			return
		}
		setSending(true)
	}

	useEffect(() => {
		if (sending && mails.length === 0) {
			setModalIsOpen(false)
			setErr('')
			toast.success('Invites sent')
			setSending(false)
			callback()
		}
		if (err) {
			setSending(false)
		}
		if (err && mails.length === 0) {
			setErr('')
		}
		if (sending && mails.length > 0 && !err) {

			const lastInvite = mails[mails.length - 1]
			const role = adminOn ? 'ADMIN' : 'ENHANCED'
			onSendInvites(lastInvite, role)
		}

	}, [mails.length, sending, err])

	const onRemove = (index) => {
		if (err) setErr('')
		setMails(mails.filter((m, i) => i !== index))
	}

	return (
		<div className={cl.container} {...props}>
			<Header> {header} </Header>
			<Paragraph style={{ marginBottom: 8 }}>Emails</Paragraph>
			<div className={cl.emails}>
				<div className={cl.eContainer}>
					{mails.map((mail, index) => (
						<div key={index} className={cl.email}>
							<Caption>{mail}</Caption>
							<i className={classNames(cl.close, 'icon-close')} onClick={() => onRemove(index)} />
						</div>
					))}
					<div className={cl.input}>
						<input onBlur={onInputValueChange} value={inputValue} onChange={onInputValueChange} type='text' placeholder='Type or paste the email addresses' />
					</div>
				</div>
			</div>
			<div className={cl.role}>
				<Paragraph style={{ fontWeight: 500 }}>Role</Paragraph>
				<Switch checked={adminOn} onChange={onSwich}><RoleChips role='ADMIN' /></Switch>
			</div>
			{err && <TextError style={{ marginBottom: 8, color: 'var(--error-color)', textAlign: 'center' }}>{err}</TextError>}
			<div className={cl.buttons}>
				<Button secondary onClick={onCancel}>Cancel</Button>
				<Button loading={sending} onClick={onClick}>{textButton}</Button>
			</div>
		</div>
	)
}

export default InviteModal