import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { LoginService } from '../../services/LoginServices'


export const sendPassRecovery = createAsyncThunk(
	'userSettings/sendPassRecovery',
	async (email, { rejectWithValue }) => {
		try {
			const res = await LoginService.sendPassRecovery(email)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const verifyCode = createAsyncThunk(
	'userSettings/verifyCode',
	async (data, { rejectWithValue }) => {
		const { email, code } = data
		try {
			const res = await LoginService.verifyCode(email, code)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const changePass = createAsyncThunk(
	'userSettings/changePass',
	async (data, { rejectWithValue }) => {
		const { email, code, password } = data
		try {
			const res = await LoginService.changePass(email, code, password)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const userSettingsAdapter = createEntityAdapter({
	selectId: (userInfo) => userInfo?.id || userInfo,
})

export const userSettingsSlice = createSlice({
	name: 'userSettings',
	initialState: userSettingsAdapter.getInitialState({
		loadingSettings: false,
		errorSettings: null
	}),
	reducers: {
		setSettings: (state, action) => {
			userSettingsAdapter.setAll(state, action.payload)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendPassRecovery.pending, (state, action) => {
				state.errorSettings = null
				if (state.loadingSettings === false) {
					state.loadingSettings = true
				}
			})
			.addCase(sendPassRecovery.fulfilled, (state, action) => {
				state.loadingSettings = false
				userSettingsAdapter.setAll(state, action.payload)
			})
			.addCase(sendPassRecovery.rejected, (state, action) => {
				state.loadingSettings = false
				state.errorSettings = action.payload
			})
			.addCase(verifyCode.pending, (state, action) => {
				state.errorSettings = null
				if (state.loadingSettings === false) {
					state.loadingSettings = true
				}
			})
			.addCase(verifyCode.fulfilled, (state, action) => {
				state.loadingSettings = false
				userSettingsAdapter.setAll(state, action.payload)
			})
			.addCase(verifyCode.rejected, (state, action) => {
				state.loadingSettings = false
				state.errorSettings = action.payload
			})
			.addCase(changePass.pending, (state, action) => {
				state.errorSettings = null
				if (state.loadingSettings === false) {
					state.loadingSettings = true
				}
			})
			.addCase(changePass.fulfilled, (state, action) => {
				state.loadingSettings = false
				userSettingsAdapter.setAll(state, action.payload)
			})
			.addCase(changePass.rejected, (state, action) => {
				state.loadingSettings = false
				state.errorSettings = action.payload
			})
		}
})

export default userSettingsSlice.reducer