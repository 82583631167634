import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import cl from '../../styles/components/menu/MobMenu.module.css'
import { Caption } from '../../UI/Typography/Typography'

const tabs = [
	{ name: '360', link: 'videos', icon: 'icon-video_library' },
	{ name: 'VR', link: 'vr', icon: 'icon-vr' },
	{ name: 'Classrooms', link: 'classrooms', icon: 'icon-user_group' },
	{ name: 'Settings', link: 'settings', icon: 'icon-settings' },
	
]

const userTabs = [
	{ name: '360', link: 'videos', icon: 'icon-video_library' },
	{ name: 'VR', link: 'vr', icon: 'icon-vr' },
	{ name: 'Settings', link: 'settings', icon: 'icon-settings' },
]

function MobMenu() {
	const iRole = useSelector((state) => state.userInfo?.info.role)

	return (
		<div className={cl.mobMenu}>
			<div className={cl.tabs}>

				{iRole
					? iRole !== 'ENHANCED'
						? tabs.map(({ name, link, icon }) => (
							<NavLink key={name} to={link} className={({ isActive }) =>
								isActive ? classNames(cl.item, cl.active) : cl.item
							}>
								<i className={icon} />
								<Caption>{name}</Caption>
							</NavLink>
						))
						: userTabs.map(({ name, link, icon }) => (
							<NavLink key={name} to={link} className={({ isActive }) =>
								isActive ? classNames(cl.item, cl.big, cl.active) : classNames(cl.item, cl.big)
							}>
								<i className={icon} />
								<Caption>{name}</Caption>
							</NavLink>
						))
					: null}
			</div>
		</div>
	)
}

export default MobMenu