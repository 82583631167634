import React, { useState } from 'react'
import Button from '../../UI/Button/Button';
import Checkbox from '../../UI/checkbox/Checkbox';
import RadioBtn from '../../UI/radiobtn/RadioBtn'
import ScrollBlock from '../../UI/scrollBlock/ScrollBlock.tsx';
import { Paragraph } from '../../UI/Typography/Typography';
import cl from './Filter.module.css'

function Filter({ options, value, onChange, setOpen, header = 'Roles', ...props }) {
	const [checkedOptions, setCheckedOptions] = useState([])


	const onCheckboxChange = (value) => {
		if (!checkedOptions.includes(value)) {
			setCheckedOptions([...checkedOptions, value])
		} else {
			setCheckedOptions(old => old.filter(val => val !== value))
		}
	}

	const handleReset = () => {
		setCheckedOptions([])
	}

	const onApply = () => {
		onChange(checkedOptions)
		setOpen(false)
	}

	return (
		<div className={cl.container}>
			<Paragraph>{header}</Paragraph>
			<ScrollBlock height={'100%'} className={cl.options}>
				{options.map((option) => (
					<div className={cl.filter} key={option.value}>
						<Checkbox
							checked={checkedOptions.includes(option.value)}
							onChange={() => onCheckboxChange(option.value)}
						>{option.label}</Checkbox>
					</div>
				))}
			</ScrollBlock>
			<div className={cl.btns}>
				<Button
					disabled={checkedOptions.length === 0}
					secondary
					onClick={handleReset}
				>Reset</Button>
				<Button onClick={onApply}>Apply</Button>
			</div>
		</div>
	)
}

export default Filter