import axios from "axios"


export class StatisticPersonalService {
	//оcтрова 
	static getPlatformTimeUser(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/platform/time/user/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static getVideoTimeUser(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/video/time/user/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getVrTimeUser(token, comID, userId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/vr/time/user/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	//new chart

	static getPlatformSpentTime365(token, comID, userId) {
		
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/platform/time/spent/year/${comID}/${userId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}


	//send web session

	static sendWebSession(token, userId, comId, timeEnd, duration) {
		return axios.post(process.env.REACT_APP_MAIN_STATISTIC_URL + `/webSession`, {
			companyId: comId, duration, timeEnd, userId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	// vr sessions by training

	static getVRSessionsByTraining(token, comID, userId, trainingId) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/getTrainingSessionData/${comID}/${trainingId}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}