import React from 'react'
import logo from '../assets/main_logo.svg'

import cl from '../styles/components/Logo.module.css'

function Logo() {
  return (
	 <div className={cl.container}>
		<img src={logo} />
	 </div>
  )
}

export default Logo