import { useEffect, useState, lazy, Suspense } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";
import ReactGA from "react-ga4";
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { PrimeReactProvider } from "primereact/api";

import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';
import { TABLET_WIDTH, useWindowWidth } from './hooks/useWindowWidth';

import ChangePassword from './pages/ChangePassword';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';

import PaymentReceipt from './UI/paymentRecipt/PaymentReceipt';
import UsersPage from './pages/team/UsersPage';
import TeamsPage from './pages/team/TeamsPage';

import { Paragraph } from './UI/Typography/Typography';
import SpinnerBlock from './UI/spinner/SpinnerBlock';

const VRLibrary = lazy(() => import('./pages/VRLibraryPage'));
const Test = lazy(() => import('./Test'));
const CompanyProfile = lazy(() => import('./pages/settings/CompanyProfile'));
const Account = lazy(() => import('./pages/settings/Account'));
const Team = lazy(() => import('./pages/Team'));
const TeamSettings = lazy(() => import('./pages/team/teams/TeamSettings'));
const ErrorPage = lazy(() => import('./pages/errorPages/ErrorPage'));
const Insights = lazy(() => import('./pages/Insights'));
const VideoInfo = lazy(() => import('./pages/VideoInfo'));
const ClassroomContainer = lazy(() => import('./pages/ClassroomContainer'));
const SelfRegistration = lazy(() => import('./pages/SelfRegistration'));
const SubscriptionPage = lazy(() => import('./pages/settings/SubscriptionPage'));
const PersonalInsights = lazy(() => import('./pages/PersonalInsights'));
const InsightsMob = lazy(() => import('./pages/InsightsMob'));
const VRSessionsListPage = lazy(() => import('./pages/VRSessionsListPage'));
const PersonalVRSessionsListPage = lazy(() => import('./pages/PersonalVRSessionsListPage'));
const VRSessionDetails = lazy(() => import('./pages/vrSessionsList/VRSessionDetails'));
const ServerOffline = lazy(() => import('./pages/errorPages/ServerOffline'));
const LoginWithCode = lazy(() => import('./pages/LoginWithCode'));
const VerificationSuccess = lazy(() => import('./pages/VerificationSuccess'));
const TestTwo = lazy(() => import('./TestTwo'));
const BuildFlex = lazy(() => import('./pages/settings/subscribtionPage/BuildFlex'));
const RedirectWithParams = lazy(() => import('./utils/RedirectWithParams'));
const SubscrPayment = lazy(() => import('./pages/settings/subscribtionPage/payment/SubscrPayment'));
const SubscriptionUpdate = lazy(() => import('./pages/settings/subscribtionPage/update/SubscriptionUpdate'));
const ContactSales = lazy(() => import('./pages/settings/subscribtionPage/ContactSales'));
const UserDeactivated = lazy(() => import('./pages/UserDeactivated'));
const UpdateFlex = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlex'));
const SubscrProductInfo = lazy(() => import('./pages/settings/subscribtionPage/editPlan/SubscrProductInfo'));
const UpdateFlexModule = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlexModule'));
const UpdateFlexModuleDecrease = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlexModuleDecrease'));
const Classrooms = lazy(() => import('./pages/Classrooms'));
const VideosLibrary = lazy(() => import('./pages/VideosLibraryPage'));
const TrainingInfo = lazy(() => import('./pages/TrainingInfo'));
const Settings = lazy(() => import('./pages/Settings'));
const DevicesPage = lazy(() => import('./pages/team/DevicesPage'));
const Device = lazy(() => import('./pages/team/devices/Device'));

export const routesConfig = [
	{
		path: '/',
		element: <Login />,
	},
	{
		path: '/login-with-code',
		element: <LoginWithCode />
	},
	{
		path: '/registration',
		element: <Signup />
	},
	{
		path: '/verification-success',
		element: <VerificationSuccess />
	},
	{
		path: '/user-deactivated',
		element: <UserDeactivated />
	},
	{
		path: '/user-deleted',
		element: <UserDeactivated unit='company' />
	},
	{
		path: '/self-registration',
		element: <SelfRegistration />
	},
	{
		path: '/changepassword',
		element: <ChangePassword />
	},
	{
		path: '/payment-receipt',
		element: <PaymentReceipt />
	},

	{
		path: '/home',
		element: <Home />,
		children: [
			{
				path: '',
				element: <Navigate to="/home/vr" replace/>
			},
			{
				path: 'vr',
				element: <VRLibrary />
			},
			{
				path: 'vr/:id',
				element: <TrainingInfo />
			},
			{
				path: 'videos',
				element: <VideosLibrary />
			},
			{
				path: 'videos/:id',
				element: <VideoInfo />
			},
			{
				path: 'classrooms',
				element: <Classrooms />
			},
			{
				path: 'classrooms/:id',
				element: <ClassroomContainer />
			},
			{
				path: ':accountId',
				element: <Account />, //убрали /settings
			},
			{
				path: 'company',
				element: <CompanyProfile />, //убрали /settings
			},
			{
				path: 'insights',
				element: <Insights />,
			},
			{
				path: 'insights/:trainingId',
				element: <VRSessionsListPage />
			},
			{
				path: 'insights/:trainingId/:sessionId',
				element: <VRSessionDetails />,
			},
			{
				path: 'my-insights',
				element: <PersonalInsights />
			},
			{
				path: 'my-insights/:trainingId',
				element: <PersonalVRSessionsListPage />
			},
			{
				path: 'my-insights/:trainingId/:sessionId',
				element: <VRSessionDetails />,
			},
			{
				path: 'subscription',
				element: <SubscriptionPage />,
			},
			{
				path: 'subscription/payment',
				element: <SubscrPayment />,
			},
			{
				path: 'subscription/flex',
				element: <BuildFlex />,
			},
			{
				path: 'subscription/update',
				element: <SubscriptionUpdate />,
			},
			{
				path: 'subscription/sales',
				element: <ContactSales />,
			},
			{
				path: 'subscription/:moduleId',
				element: <SubscrProductInfo />,
			},
			{
				path: 'subscription/update-flex',
				element: <UpdateFlex />,
			},
			{
				path: 'subscription/update-flex/:moduleId',
				element: <UpdateFlexModule />,
			},
			{
				path: 'subscription/remove-licenses/:moduleId',
				element: <UpdateFlexModuleDecrease />,
			},
			{
				path: 'users',
				element: <UsersPage /> //убрали team
			},
			{
				path: 'teams',
				element: <TeamsPage />, //убрали team
			},
			{
				path: 'teams/:teamName',
				element: <TeamSettings />,
			},
			{
				path: 'devices',
				element: <DevicesPage />, //убрали team
			},
			{
				path: 'devices/:id',
				element: <Device />,
			},
			{
				path: 'settings',
				element: <Settings />,
				children: [
					{
						path: 'team',
						element: <Team />,
						children: [
							{
								path: 'users',
								element: <UsersPage />,
							},
							{
								path: 'teams',
								element: <TeamsPage />,
								children: [
									{
										path: ':teamName',
										element: <TeamSettings />,
									}
								]
							},
							{
								path: 'devices',
								element: <DevicesPage />,
								children: [
									{
										path: ':id',
										element: <Device />,
									}
								]
							}
						]
					},
					{
						path: 'insights',
						element: <InsightsMob />,
						children: [
							{
								path: 'personal',
								element: <PersonalInsights />,
								children: [
									{
										path: ':trainingId',
										element: <PersonalVRSessionsListPage />,
										children: [
											{
												path: ':sessionId',
												element: <PersonalVRSessionsListPage />,
											},
										],
									},
								],
							},
							{
								path: 'company',
								element: <Insights />,
							}
						]
					},
					{
						path: 'subscription',
						element: <SubscriptionPage />,
						children: [
							{
								path: 'payment',
								element: <SubscrPayment />,
							},
							{
								path: 'flex',
								element: <BuildFlex />,
							},
							{
								path: 'update',
								element: <SubscriptionUpdate />,
							},
							{
								path: 'sales',
								element: <ContactSales />,
							},
							{
								path: ':moduleId',
								element: <SubscrProductInfo />,
							},
							{
								path: 'update-flex',
								element: <UpdateFlex />,
								children: [
									{
										path: ':moduleId',
										element: <UpdateFlexModule />,
									},
								],
							},
							{
								path: 'remove-licenses/:moduleId',
								element: <UpdateFlexModuleDecrease />
							}
						]
					}
				]
			},



			{
				path: 'test',
				element: <Test />
			},
			{
				path: 'test2',
				element: <TestTwo />
			}

			// Добавьте остальные маршруты, используя структуру иерархии
		],
	},
	{
		path: '/502',
		element:
			<ServerOffline />
	},
	{
		path: '*',
		element: <ErrorPage />
	},
	// Добавьте остальные маршруты верхнего уровня
];



