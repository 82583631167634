import React from 'react';
import { Caption } from '../Typography/Typography';

import cl from './RoleChips.module.css';

interface RoleChipsProps extends React.HTMLAttributes<HTMLDivElement> {
  role?: 'Owner' | 'Admin' | 'Enhanced';
}

const RoleChips: React.FC<RoleChipsProps> = ({ role = 'Owner', ...props }) => {
  const styleChips =
    role.toLowerCase() === 'admin'
      ? { backgroundColor: '#FEF4D1' }
      : role.toLowerCase() === 'enhanced'
      ? { backgroundColor: '#DAFFF6' }
	: {};

  const styleText =
    role.toLowerCase() === 'admin'
      ? { color: '#DEB82E' }
      : role.toLowerCase() === 'enhanced'
      ? { color: '#4BC0A3' }
      : {};

  return (
    <div style={{ ...styleChips, ...props.style }} className={cl.chips} {...props}>
      <Caption style={styleText}>{role}</Caption>
    </div>
  );
};

export default RoleChips;
