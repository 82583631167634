import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import cl from './Typography.module.css';

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

export const HeaderBig: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <h1 {...props} className={classNames(cl.big, props.className)}>
      {children}
    </h1>
  );
};

export const HeaderMid: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <h2 {...props} className={classNames(cl.mid, props.className)}>
      {children}
    </h2>
  );
};

export const Header: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <h3 {...props} className={classNames(cl.header, props.className)}>
      {children}
    </h3>
  );
};

export const Subheader: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <h4 {...props} className={classNames(cl.subheader, props.className)}>
      {children}
    </h4>
  );
};

export const Paragraph: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <p {...props} className={classNames(cl.paragraph, props.className)}>
      {children}
    </p>
  );
};

export const Caption: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <p {...props} className={classNames(cl.caption, props.className)}>
      {children}
    </p>
  );
};

export const TextError: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <p {...props} className={classNames(cl.texterror, props.className)}>
      {children}
    </p>
  );
};
