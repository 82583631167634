import React from 'react';
import Spinner from './Spinner';
import cl from './SpinnerBlock.module.css';

interface SpinnerBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: number | string;
  width?: number | string;
  spinnerWith?: number;
}

const SpinnerBlock: React.FC<SpinnerBlockProps> = ({
  height = 50,
  width = '100%',
  spinnerWith = 50,
  ...props
}) => {
  return (
    <div style={{ width, height }} className={cl.block} {...props}>
      <Spinner width={spinnerWith} />
    </div>
  );
};

export default SpinnerBlock;
