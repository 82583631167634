import React from 'react';
import classNames from 'classnames';

import cl from './SimpleAvatar.module.css';

interface SimpleAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar?: string | null;
}

const SimpleAvatar: React.FC<SimpleAvatarProps> = ({ avatar, ...props }) => {
  return (
    <div
      className={classNames(cl.avatar, cl.avatar__wrap, props.className)}
      style={{ ...props.style }}
    >
      {avatar ? (
        <img src={`${process.env.REACT_APP_MAIN_API_URL}/avatar/${avatar}`} alt="avatar" />
      ) : (
        <div className={classNames(cl.avatar__icon, 'icon-User')}></div>
      )}
    </div>
  );
};

export default SimpleAvatar;
