import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { StatisticOverviewService } from '../../services/StatisticOverviewServices';
import { newPrepareDataForChartByDays, prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from '../../utils/DataForStatistic';



export const getTotalUsers = createAsyncThunk(
	'statisticOverview/getTotalUsers',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getTotalUsers(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTotalTrainings = createAsyncThunk(
	'statisticOverview/getTotalTrainings',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getTotalTrainings(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVRAppTimeSpentDaily = createAsyncThunk(
	'statisticOverview/getVRAppTimeSpentDaily',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getVRAppTimeSpentDaily(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVRAppTimeSpentWeekly = createAsyncThunk(
	'statisticOverview/getVRAppTimeSpentWeekly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getVRAppTimeSpentWeekly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVRAppTimeSpentMonthly = createAsyncThunk(
	'statisticOverview/getVRAppTimeSpentMonthly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getVRAppTimeSpentMonthly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getCompletedVRTrainingsDaily = createAsyncThunk(
	'statisticOverview/getCompletedVRTrainingsDaily',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getCompletedVrTrainingSessionDaily(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getCompletedVRTrainingsWeekly = createAsyncThunk(
	'statisticOverview/getCompletedVRTrainingsWeekly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getCompletedVrTrainingSessionWeekly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getCompletedVRTrainingsMonthly = createAsyncThunk(
	'statisticOverview/getCompletedVRTrainingsMonthly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getCompletedVrTrainingSessionMonthly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getActiveUsersDaily = createAsyncThunk(
	'statisticOverview/getActiveUsersDaily',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getActiveUsersDaily(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getActiveUsersWeekly = createAsyncThunk(
	'statisticOverview/getActiveUsersWeekly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getActiveUsersWeekly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getActiveUsersMonthly = createAsyncThunk(
	'statisticOverview/getActiveUsersMonthly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getActiveUsersMonthly(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTrainingSessions365 = createAsyncThunk(
	'statisticOverview/getTrainingSessions365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticOverviewService.getTrainingSessions365(token, comID);
			return res.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getActiveUsers365 = createAsyncThunk(
	'statisticOverview/getActiveUsers365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getActiveUsers365(token, comID);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTrainingSessionsTime365 = createAsyncThunk(
	'statisticOverview/getTrainingSessionsTime365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticOverviewService.getTrainingSessionsTime365(token, comID);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)



const statisticOverviewAdapter = createEntityAdapter({
	selectId: (info) => uid(8),
})

const statisticOverviewSlice = createSlice({
	name: 'statisticOverview',
	initialState: statisticOverviewAdapter.getInitialState({
		loading: false,
		error: null,
		dailyStatistic: [],
		weeklyStatistic: [],
		monthlyStatistic: [],
		dailyVrTrainingsStatistic: [],
		weeklyVrTrainingsStatistic: [],
		monthlyVrTrainingsStatistic: [],
		dailyActiveUsersStatistic: [],
		weeklyActiveUsersStatistic: [],
		monthlyActiveUsersStatistic: [],
		trainingSessions: {
			title: 'Completed VR sessions',
			color: "#FFCE3B",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
		activeUsers: {
			title: 'Active users',
			color: "#89A6FB",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
		vrSessionsTime: {
			title: 'Total VR sessions time',
			color: "#4BC0A3",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
		totalUsers: null,
		totalTrainings: null,
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getTotalUsers.pending, (state) => {
				state.loading = true
			})
			.addCase(getTotalUsers.fulfilled, (state, action) => {
				state.loading = false
				state.totalUsers = action.payload
			})
			.addCase(getTotalUsers.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getTotalTrainings.pending, (state) => {
				state.loading = true
			})
			.addCase(getTotalTrainings.fulfilled, (state, action) => {
				state.loading = false
				state.totalTrainings = action.payload
			})
			.addCase(getTotalTrainings.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getVRAppTimeSpentDaily.pending, (state) => {
				state.loading = true
			})
			.addCase(getVRAppTimeSpentDaily.fulfilled, (state, action) => {
				state.loading = false
				state.dailyStatistic = prepareDataForChartByDays(action.payload.data)
			})
			.addCase(getVRAppTimeSpentDaily.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getVRAppTimeSpentWeekly.pending, (state) => {
				state.loading = true
			})
			.addCase(getVRAppTimeSpentWeekly.fulfilled, (state, action) => {
				state.loading = false
				state.weeklyStatistic = prepareDataForChartByWeek(action.payload.data)
			})
			.addCase(getVRAppTimeSpentWeekly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getVRAppTimeSpentMonthly.pending, (state) => {
				state.loading = true
			})
			.addCase(getVRAppTimeSpentMonthly.fulfilled, (state, action) => {
				state.loading = false
				state.monthlyStatistic = prepareDataForChartByMonth(action.payload.data)
			})
			.addCase(getVRAppTimeSpentMonthly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getCompletedVRTrainingsDaily.pending, (state) => {
				state.loading = true
			})
			.addCase(getCompletedVRTrainingsDaily.fulfilled, (state, action) => {
				state.loading = false
				state.dailyVrTrainingsStatistic = prepareDataForChartByDays(action.payload.data)
			})
			.addCase(getCompletedVRTrainingsDaily.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getCompletedVRTrainingsWeekly.pending, (state) => {
				state.loading = true
			})
			.addCase(getCompletedVRTrainingsWeekly.fulfilled, (state, action) => {
				state.loading = false
				state.weeklyVrTrainingsStatistic = prepareDataForChartByWeek(action.payload.data)
			})
			.addCase(getCompletedVRTrainingsWeekly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getCompletedVRTrainingsMonthly.pending, (state) => {
				state.loading = true
			})
			.addCase(getCompletedVRTrainingsMonthly.fulfilled, (state, action) => {
				state.loading = false
				state.monthlyVrTrainingsStatistic = prepareDataForChartByMonth(action.payload.data)
			})
			.addCase(getCompletedVRTrainingsMonthly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getActiveUsersDaily.pending, (state) => {
				state.loading = true
			})
			.addCase(getActiveUsersDaily.fulfilled, (state, action) => {
				state.loading = false
				state.dailyActiveUsersStatistic = prepareDataForChartByDays(action.payload.data)
			})
			.addCase(getActiveUsersDaily.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getActiveUsersWeekly.pending, (state) => {
				state.loading = true
			})
			.addCase(getActiveUsersWeekly.fulfilled, (state, action) => {
				state.loading = false
				state.weeklyActiveUsersStatistic = prepareDataForChartByWeek(action.payload.data)
			})
			.addCase(getActiveUsersWeekly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getActiveUsersMonthly.pending, (state) => {
				state.loading = true
			})
			.addCase(getActiveUsersMonthly.fulfilled, (state, action) => {
				state.loading = false
				state.monthlyActiveUsersStatistic = prepareDataForChartByMonth(action.payload.data)
			})
			.addCase(getActiveUsersMonthly.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getActiveUsers365.pending, (state) => {
				state.activeUsers.loading = true
			})
			.addCase(getActiveUsers365.fulfilled, (state, action) => {
				state.activeUsers.loading = false
				let obj = newPrepareDataForChartByDays(action.payload)
				if (!obj) return
				state.activeUsers[365] = obj[365]
				state.activeUsers[90] = obj[90]
				state.activeUsers[30] = obj[30]
				state.activeUsers[7] = obj[7]
				state.activeUsers.error = null
				state.activeUsers.ticks = obj.ticks
				state.activeUsers.ticksMob = obj.ticksMob
			})
			.addCase(getActiveUsers365.rejected, (state, action) => {
				state.activeUsers.loading = false
				state.activeUsers.error = action.payload
			})

			.addCase(getTrainingSessionsTime365.pending, (state) => {
				state.vrSessionsTime.loading = true
			})
			.addCase(getTrainingSessionsTime365.fulfilled, (state, action) => {
				state.vrSessionsTime.loading = false
				let obj = newPrepareDataForChartByDays(action.payload)
				if (!obj) return
				state.vrSessionsTime[365] = obj[365]
				state.vrSessionsTime[90] = obj[90]
				state.vrSessionsTime[30] = obj[30]
				state.vrSessionsTime[7] = obj[7]
				state.vrSessionsTime.error = null
				state.vrSessionsTime.ticks = obj.ticks
				state.vrSessionsTime.ticksMob = obj.ticksMob
			})
			.addCase(getTrainingSessionsTime365.rejected, (state, action) => {
				state.vrSessionsTime.loading = false
				state.vrSessionsTime.error = action.payload
			})

			.addCase(getTrainingSessions365.pending, (state) => {
				state.trainingSessions.loading = true
			})
			.addCase(getTrainingSessions365.fulfilled, (state, action) => {
				state.trainingSessions.loading = false
				let obj = newPrepareDataForChartByDays(action.payload)
				if (!obj) return
				state.trainingSessions[365] = obj[365]
				state.trainingSessions[90] = obj[90]
				state.trainingSessions[30] = obj[30]
				state.trainingSessions[7] = obj[7]
				state.trainingSessions.error = null
				state.trainingSessions.ticks = obj.ticks
				state.trainingSessions.ticksMob = obj.ticksMob
			})
			.addCase(getTrainingSessions365.rejected, (state, action) => {
				state.trainingSessions.loading = false
				state.trainingSessions.error = action.payload
			})

	}
})

export default statisticOverviewSlice.reducer;