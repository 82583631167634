import React from 'react'
import SearchInput from '../../../UI/searchInput/SearchInput'
import MainSelect from '../../../UI/select/Select'

import cl from './InputsRow.module.css'

function InputsRow({ searchInput, onSearchChange, setSearchInput, onChangeSelectFilter, selectOptions, ...props }) {
	return (
		<div className={cl.inputs} {...props}>
			<div className={cl.searchInput}>
				<SearchInput value={searchInput} setValue={setSearchInput} onChange={onSearchChange} placeholder='Search by name or email' />
			</div>
			<div className={cl.select}>
				<MainSelect options={selectOptions} onChange={onChangeSelectFilter} />
			</div>
		</div>
	)
}

export default InputsRow