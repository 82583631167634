import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { StatisticUsersService } from "../../services/StatisticUsersServices";
import { newPrepareDataForChartByDays, prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from "../../utils/DataForStatistic";

export const getAvgSessionsPerUser = createAsyncThunk(
	'statisticUsers/getAvgSessionsPerUser',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getAvgVRTrainingSessionPerUser(token, comID);
			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getAvgVRCompletedTrainingSessionPerUser = createAsyncThunk(
	'statisticUsers/getAvgVRCompletedTrainingSessionPerUser',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getAvgVRCompletedTrainingSessionPerUser(token, comID);
			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getUsersTableData = createAsyncThunk(
	'statisticUsers/getUsersTableData',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getUsersData(token, comID);
			;
			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPlatformSpentTimeDaily = createAsyncThunk(
	'statisticUsers/getPlatformSpentTimeDaily',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getDailyDurationStatistic(token, comID);

			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPlatformSpentTimeWeekly = createAsyncThunk(
	'statisticUsers/getPlatformSpentTimeWeekly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getWeeklyDurationStatistic(token, comID);
			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPlatformSpentTimeMonthly = createAsyncThunk(
	'statisticUsers/getPlatformSpentTimeMonthly',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getMonthlyDurationStatistic(token, comID);

			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getWebSessionsDuration365 = createAsyncThunk(
	'statisticUsers/getWebSessionsDuration365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const res = await StatisticUsersService.getWebSessionsDuration365(token, comID);
			return res.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

const statisticUsersAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const statisticUsersSlice = createSlice({
	name: 'statisticUsers',
	initialState: statisticUsersAdapter.getInitialState({
		loading: false,
		error: null,
		dailyStatistic: [],
		monthlyStatistic: [],
		weeklyStatistic: [],
		avgSessionsPerUser: null,
		avgVRCompletedTrainingSessionPerUser: null,
		usersTableData: null,
		webSessions: {
			title: 'Platform time spent',
			color: "#FE5F55",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getAvgSessionsPerUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAvgSessionsPerUser.fulfilled, (state, action) => {
				state.loading = false;
				state.avgSessionsPerUser = action.payload.data;
			})
			.addCase(getAvgSessionsPerUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getAvgVRCompletedTrainingSessionPerUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAvgVRCompletedTrainingSessionPerUser.fulfilled, (state, action) => {
				state.loading = false;
				state.avgVRCompletedTrainingSessionPerUser = action.payload.data;
			})
			.addCase(getAvgVRCompletedTrainingSessionPerUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getUsersTableData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getUsersTableData.fulfilled, (state, action) => {
				state.loading = false;
				state.usersTableData = action.payload.data;
			})
			.addCase(getUsersTableData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPlatformSpentTimeDaily.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPlatformSpentTimeDaily.fulfilled, (state, action) => {
				state.loading = false;
				state.dailyStatistic = prepareDataForChartByDays(action.payload.data);
			})
			.addCase(getPlatformSpentTimeDaily.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPlatformSpentTimeWeekly.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPlatformSpentTimeWeekly.fulfilled, (state, action) => {
				state.loading = false;
				state.weeklyStatistic = prepareDataForChartByWeek(action.payload.data);
			})
			.addCase(getPlatformSpentTimeWeekly.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPlatformSpentTimeMonthly.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPlatformSpentTimeMonthly.fulfilled, (state, action) => {
				state.loading = false;
				state.monthlyStatistic = prepareDataForChartByMonth(action.payload.data);
			})
			.addCase(getPlatformSpentTimeMonthly.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getWebSessionsDuration365.pending, (state) => {
				state.webSessions.loading = true;
			})
			.addCase(getWebSessionsDuration365.fulfilled, (state, action) => {
				state.webSessions.loading = false;
				let obj = newPrepareDataForChartByDays(action.payload)
				if(!obj) return
				state.webSessions[365] = obj[365]
				state.webSessions[90] = obj[90]
				state.webSessions[30] = obj[30]
				state.webSessions[7] = obj[7]
				state.webSessions.error = null
				state.webSessions.ticks = obj.ticks
				state.webSessions.ticksMob = obj.ticksMob
			})
			.addCase(getWebSessionsDuration365.rejected, (state, action) => {
				state.webSessions.loading = false;
				state.webSessions.error = action.payload
			})
	}
})

export default statisticUsersSlice.reducer