import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TeamSettingsService } from '../../services/TeamSettingsServices';

export const getTeamUsers = createAsyncThunk(
	'teamSettings/getTeamUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const res = await TeamSettingsService.getTeamUsers(token, data.companyId, data.groupName)
			;
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addToTeam = createAsyncThunk(
	'teamSettings/addToTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		try {
			const res = await TeamSettingsService.addToTeam(token, data.groupName, userId, data.userArr)
			;

			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const removeFromTeam = createAsyncThunk(
	'teamSettings/removeFromTeam',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const adminId = localStorage.getItem('id')
		try {
			const res = await TeamSettingsService.removeFromTeam(token, adminId, data.groupName, data.userId)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const teamSettingsAdapter = createEntityAdapter({
	selectId: (user) => user.id
})

const teamSettingsSlice = createSlice({
	name: 'teams',
	initialState: teamSettingsAdapter.getInitialState({
		loading: false,
		error: null,
		teams: [],
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getTeamUsers.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getTeamUsers.fulfilled, (state, action) => {
				state.loading = false

				const oldTeam = state.teams.filter(item => item.groupName.includes(action.meta.arg.groupName))

				if (oldTeam.length > 0) {
					const newTeamsArr = state.teams.map(team => {
						if (team.groupName === action.meta.arg.groupName) {
							return { ...team, users: action.payload.groupUsers }
						}
						return team
					})
					state.teams = newTeamsArr
					return
				} else {
					state.teams = [...state.teams, {
						...action.meta.arg,
						users: action.payload.groupUsers
					}]
				}

				//state.teams = [...state.teams, {
				//	...action.meta.arg,
				//	users: action.payload.groupUsers
				//}]
			})
			.addCase(getTeamUsers.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(addToTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(addToTeam.fulfilled, (state, action) => {
				state.loading = false
				//const newArr = action.meta.arg.userArr.map(user => user.id)
				//state.teams = state.teams.map(team => {
				//	if (team.groupName === action.meta.arg.groupName) {
				//		return {...team, users: [...team.users, ...newArr]}
				//	}
				//	return team
				//})
			})
			.addCase(addToTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(removeFromTeam.pending, (state, action) => {
				state.loading = true
			})
			.addCase(removeFromTeam.fulfilled, (state, action) => {
				state.loading = false
				state.teams = state.teams.map(team => {
					if (team.groupName === action.meta.arg.groupName) {
						return { ...team, users: team.users.filter(user => user !== action.meta.arg.userId) }
					}
					return team
				})
			})
			.addCase(removeFromTeam.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export default teamSettingsSlice.reducer

