import React from 'react';
import { Paragraph } from '../Typography/Typography';
import classNames from 'classnames';

import cl from './UserColumn.module.css';
import user from '../../assets/skeleton/user_skeleton.png';

interface InviteUserColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  rowData: {
    name?: string;
    userName?: string;
    email?: string;
    userEmail?: string;
  };
}

const InviteUserColumn: React.FC<InviteUserColumnProps> = ({ rowData, className, style, ...props }) => {
  return (
    <div className={classNames(cl.row, className)} style={style} {...props}>
      <img
        src={user}
        alt={rowData.name || rowData.userName}
        style={{
          width: 35,
          height: 35,
          borderRadius: '50%',
          marginRight: 10,
        }}
      />
      <div className={cl.name}>
        <Paragraph style={{ color: 'var(--fieldhover-color)' }}>
          {rowData.email || rowData.userEmail}
        </Paragraph>
      </div>
    </div>
  );
};

export default InviteUserColumn;
