import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getPersonalDB } from '../../db/services/servicePersonalDB';

export const getDownloadedVideos = createAsyncThunk(
	'downloadedVideos/getDownloadedVideos',
	async (data, { rejectWithValue }) => {
		try {
			const videos = await getPersonalDB('getAllVideos')
			const newVideos = videos.map((video) => {
				return {
					...video,
					blobUrl: URL.createObjectURL(video.blob),
					blob: null
				}
			})
			return newVideos
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteDownloadedVideo = createAsyncThunk(
	'downloadedVideos/deleteDownloadedVideo',
	async (data, { rejectWithValue }) => {
		const id = data
		try {
			const response = await getPersonalDB('removeVideo', id)
			return response
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const downloadedVideosAdapter = createEntityAdapter({
	selectId: (video) => video.id,
})

const downloadedVideosSlice = createSlice({
	name: 'downloadedVideos',
	initialState: downloadedVideosAdapter.getInitialState({
		loading: false,
		videos: [],
		videosIds: [],
		error: null,
	}),
	extraReducers: (builder) => {
		builder.addCase(getDownloadedVideos.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getDownloadedVideos.fulfilled, (state, action) => {
			state.loading = false
			state.videos = action.payload
			state.videosIds = action.payload.map((video) => video.id)
		})
		builder.addCase(getDownloadedVideos.rejected, (state, action) => {
			state.loading = false
			state.error = action.payload
		})
		builder.addCase(deleteDownloadedVideo.pending, (state) => {
			state.loading = true
		})
		builder.addCase(deleteDownloadedVideo.fulfilled, (state, action) => {
			state.loading = false
			state.videos = state.videos.filter((video) => video.id !== action.meta.arg)
		})
		builder.addCase(deleteDownloadedVideo.rejected, (state, action) => {
			state.loading = false
			state.error = action.payload
		})
	}
})

export default downloadedVideosSlice.reducer