import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { addHours, addMinutes } from 'date-fns';

import { getCompanyInfo } from '../../redux/slices/CompanyInfoSlice'
import { newRegistrationConfirmationResendMail } from '../../redux/slices/LoginSlice'
import { getSubscriptionInfo } from '../../redux/slices/SubscriptionSlice'
import { cleanNotice, setNotice, setSentFromNotice } from '../../redux/slices/NoticeSlice'

import NoticeBlock from './NoticeBlock'

import { Paragraph } from '../../UI/Typography/Typography'
import Button from '../../UI/Button/Button'
import NoticePurchaseModal from '../../components/modal/noticePurchase/NoticePurchaseModal.js';
import { deletePurchaseNotice, getPurchaseNotice, setPurchaseNotice } from '../../redux/slices/PurchaseNoticeSlice';

function NoticeOverlay() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const comID = useSelector((state) => state.userInfo.info?.company || undefined)
	const userID = useSelector((state) => state.userInfo.info?.id || undefined)
	const email = useSelector((state) => state.userInfo.info?.email || undefined)
	const comInfo = useSelector((state) => state.company.info)
	const subscrInfo = useSelector(state => state.subscription.info)
	const purchaseNotice = useSelector(state => state.purchaseNotice.purchaseNotice)

	const notice = useSelector(state => state.notice)

	const [loading, setLoading] = useState(false)
	const [purchaseModal, setPurchaseModal] = useState(false)

	const getSubscrInfo = async () => {
		await dispatch(getSubscriptionInfo(userID))
		checkPurchaseData()
	}

	const checkPurchaseData = () => {
		if (subscrInfo?.plan === 'Free') {
			dispatch(getPurchaseNotice(comID))
		}
	}

	useEffect(() => {
		if (!comID) return
		if (!subscrInfo) {
			getSubscrInfo()
		} else {
			checkPurchaseData()
		}
		checkActivationAccountNotice()
	}, [comInfo])

	useEffect(() => {
		if (!purchaseNotice || !subscrInfo || subscrInfo.plan !== 'Free') return
		onShowPurchaseModal()
	}, [purchaseNotice, subscrInfo])

	const onSetNotice = (noticeType, noticeHeader, noticeMessage, noticeConfirmBtn, notice, cancelBtn) => {
		dispatch(setNotice({ noticeType, noticeHeader, noticeMessage, noticeConfirmBtn, notice, cancelBtn }))
	}

	const onCleanNotice = () => {
		dispatch(cleanNotice())
	}

	const calculateDays = (date) => {
		const today = new Date()
		const endDate = new Date(date)
		const diffTime = Math.abs(endDate - today);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays
	}

	const checkSubscrNotice = () => {
		let lsNotice = localStorage.getItem(comID)
		JSON.parse(lsNotice)
		if (lsNotice) {
			if (lsNotice.status === 'expired' && lsNotice.date !== moment(new Date()).format('DD-MM-YYYY')) {
				onSetNotice('expired', 'Your subscription is expired. Please renew your subscription to continue benefiting from our service.')
				if (calculateDays(subscrInfo.subscriptionEnd) > 7) {
					onCleanNotice()
				}
			}
		} else
			if (subscrInfo && (subscrInfo.plan === 'Standard' || subscrInfo.plan === 'Premium')) {
				if (calculateDays(subscrInfo.subscriptionEnd) > 7) {
					onSetNotice('expired', 'Your subscription is expired. Please renew your subscription to continue benefiting from our service.')
				}
			}

	}

	const calcResendTimer = (notice) => {
		let newTimer = new Date(); // Начинаем с текущего времени

		// Проверяем количество отправленных сообщений
		if (notice.timerCount < 5) {
			switch (notice.timerCount) {
				case 0:
					newTimer = addMinutes(newTimer, 1); // Добавляем 1 минуту
					break;
				case 1:
					newTimer = addMinutes(newTimer, 30); // Добавляем 30 минут
					break;
				case 2:
					newTimer = addHours(newTimer, 1); // Добавляем 1 час
					break;
				case 3:
					newTimer = addHours(newTimer, 2); // Добавляем 2 часа
					break;
				case 4:
					newTimer = addHours(newTimer, 12); // Добавляем 12 часов
					break;
			}
		} else {
			// Если это шестая отправка, сбрасываем таймер на текущее время
			newTimer = addMinutes(newTimer, 1);
		}
		return newTimer
	}

	const checkActivationAccountNotice = () => {
		let lsNoticeJson = localStorage.getItem(`${comID}-activation`)


		if (lsNoticeJson && comInfo.confirmed_email === 1) {
			onCleanNotice()
			localStorage.removeItem(`${comID}-activation`)
			return

		} else if (lsNoticeJson) {
			const lsNotice = JSON.parse(lsNoticeJson)

			switch (lsNotice.type) {
				case 'activation':
					if (lsNotice.day === 1 && moment(lsNotice.date).format('DD-MM-YYYY') !== moment(new Date()).format('DD-MM-YYYY')) {
						onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', { ...lsNotice, day: 2, date: new Date(), status: 'null' }, 'Do not show again')
						localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...lsNotice, day: 2, date: new Date(), status: 'null' }))
					} else if (lsNotice.day === 2 && moment(lsNotice.date).format('DD-MM-YYYY') !== moment(new Date()).format('DD-MM-YYYY')) {
						localStorage.setItem(`${comID}-activation`, JSON.stringify({ type: 'activation', status: 'hide', day: 3, date: new Date(), timer: 'null', timerCount: 0 }))
					} else if (lsNotice.day === 1 && lsNotice.status === 'null') {
						onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', lsNotice)
					} else if (lsNotice.day === 2 && lsNotice.status === 'null') {
						onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', lsNotice)
						onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', { ...lsNotice }, 'Do not show again')
					} else if (lsNotice.day === 1 && lsNotice.status === 'sent') {
						onSetNotice('activation', '', 'Confirmation email resent! Please check your inbox and follow the instructions.', 'Got it', lsNotice)
					} else if (lsNotice.day === 2 && lsNotice.status === 'sent') {
						onSetNotice('activation', '', 'Confirmation email resent! Please check your inbox and follow the instructions.', 'Got it', lsNotice)
					}
					break
				default:
					break

			}



			if (lsNotice.status === 'activation' && lsNotice.date !== moment(new Date()).format('DD-MM-YYYY')) {
				if (comInfo.confirmed_email === 1) {
					onCleanNotice()
					localStorage.removeItem(`${comID}-activation`)
					return
				} else if (comInfo.confirmed_email !== 1) {
					onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', lsNotice)
				}
			}
		} else {
			if (comInfo.confirmed_email === 1) {
				onCleanNotice()
				localStorage.removeItem(`${comID}-activation`)
				return
			} else if (comInfo.confirmed_email !== 1) {
				onSetNotice('activation', '', 'To unlock all features, please verify your email. Resend confirmation if needed.', 'Resend email', { type: 'activation', status: 'null', day: 1, date: new Date(), timer: 'null', timerCount: 0 })
				localStorage.setItem(`${comID}-activation`, JSON.stringify({ type: 'activation', status: 'null', day: 1, date: new Date(), timer: 'null', timerCount: 0 }))
			}
		}
	}


	const onDismissActivation = (notice) => {
		switch (notice.notice.day) {
			case 1:
				onCleanNotice()
				localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice.notice, status: 'canceled', date: new Date() }))
				break
			case 2:
				onCleanNotice()
				localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice.notice, status: 'hide', day: 2, date: new Date() }))
				break
			default:
				onCleanNotice()
				localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice.notice, status: 'hide', day: 3, date: new Date() }))
				break
		}

	}
	const onConfirmActivation = async (notice) => {
		switch (notice.day) {
			case 1:
				if (notice.status === 'sent') {
					onCleanNotice()
					localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice, status: 'confirmed' }))
				} else if (notice.status === 'verified') {
					localStorage.removeItem(`${comID}-activation`)
					onCleanNotice()
				} else if (notice.status === 'got it') {
					localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice, status: 'canceled' }))
					onCleanNotice()
				} else {
					setLoading(true)
					const res = await dispatch(newRegistrationConfirmationResendMail(email))

					if (res.payload === 400) {
						onSetNotice('sent-confirmation', '', 'Your account has already been verified', 'Got it', { ...notice, status: 'verified', date: new Date(), timer: 'null' })
						setTimeout(() => {
							dispatch(getCompanyInfo(comID))
						}, 2000)
						setLoading(false)
					} else if (res.payload?.status === 'success') {
						let newTimer = calcResendTimer(notice)
						onSetNotice('activation', '', 'Confirmation email resent! Please check your inbox and follow the instructions.', 'Got it', { ...notice, status: 'sent', date: new Date(), timer: newTimer, timerCount: notice.timerCount + 1 })
						localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice, status: 'sent', date: new Date(), timer: newTimer, timerCount: notice.timerCount + 1 }))
						dispatch(setSentFromNotice(true))
						setLoading(false)
					} else {
						cleanNotice()
						setLoading(false)
					}

				}
				break
			case 2:
				if (notice.status === 'sent') {
					onCleanNotice()
					localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice, status: 'hide' }))
				} else if (notice.status === 'verified') {
					localStorage.removeItem(`${comID}-activation`)
					onCleanNotice()
				} else {
					setLoading(true)
					const res = await dispatch(newRegistrationConfirmationResendMail(email))

					if (res.payload === 400) {
						onSetNotice('sent-confirmation', '', 'Your account has already been verified', 'Got it', { ...notice, status: 'verified', date: new Date(), timer: new Date() })
						setTimeout(() => {
							dispatch(getCompanyInfo(comID))
						}, 2000)
						setLoading(false)
					} else if (res.payload?.status === 'success') {
						let newTimer = calcResendTimer(notice)
						onSetNotice('activation', '', 'Confirmation email resent! Please check your inbox and follow the instructions.', 'Got it', { ...notice, status: 'sent', date: new Date(), timer: newTimer, timerCount: notice.timerCount + 1 }, 'Do not show again')
						localStorage.setItem(`${comID}-activation`, JSON.stringify({ ...notice, status: 'sent', day: 2, date: new Date(), timer: newTimer, timerCount: notice.timerCount + 1 }))
						dispatch(setSentFromNotice(true))
						setLoading(false)
					} else {
						cleanNotice()
						setLoading(false)
					}

				}
				break
			default: break
		}
	}

	const onConfirm = async () => {
		if (notice.noticeType === 'expired') {
			navigate('/subscription')
		} else if (notice.noticeType === 'activation') {
			onConfirmActivation(notice.notice)
		} else {
			onCleanNotice()
		}
	}

	const onDismiss = () => {
		if (notice.noticeType === 'activation') {
			onDismissActivation(notice)
		}
	}


	const onShowPurchaseModal = () => {
		const now = new Date().getDay()
		const noticeDate = new Date(purchaseNotice.date).getDay()
		if (now !== noticeDate) {
			setPurchaseModal(true)
		} else if (now === noticeDate && !purchaseNotice.showed) {
			setPurchaseModal(true)
		}
	}

	const onConfirmPurchaseModal = () => {
		dispatch(setPurchaseNotice({ ...purchaseNotice, showed: true, comID }))
		if (purchaseNotice.type === 'flex') {
			navigate('/home/subscription/flex')
		}
		setPurchaseModal(false)
	}

	const onClosePurchaseModal = () => {
		dispatch(deletePurchaseNotice(comID))
		setPurchaseModal(false)
	}



	return (
		<>{
			notice.noticeType && <NoticeBlock
				type={notice.noticeType}
				message={<Paragraph><span>{notice?.noticeHeader}</span> {notice?.noticeMessage}</Paragraph>}
				buttons={
					<>
						{notice?.noticeType !== 'sent-confirmation' && notice?.notice?.status !== 'sent' && <Button style={notice?.noticeConfirmBtn ? { background: 'transparent', color: 'var(--primary-color-light)', borderColor: 'var(--primary-color-light)' } : { background: 'var(--primary-color-light)', color: '#fff', borderColor: 'var(--primary-color-light)' }} onClick={onDismiss} secondary={notice?.noticeConfirmBtn}>{notice?.cancelBtn || 'Dismiss'} </Button>}
						{notice?.noticeConfirmBtn && <Button onClick={onConfirm} loading={loading} disabled={loading}>{notice?.noticeConfirmBtn}</Button>}
					</>
				}
			/>}
			{purchaseModal && <NoticePurchaseModal
				setModalIsOpen={setPurchaseModal}
				modalIsOpen={purchaseModal}
				onConfirm={onConfirmPurchaseModal}
				onClose={onClosePurchaseModal} />}
		</>
	)
}

export default NoticeOverlay