import classNames from 'classnames'
import SimpleAvatar from '../../../UI/simpleAvatar/SimpleAvatar'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './UserItem.module.css'

function UserItem({ user, onDelete, ...props }) {
	if(!user) return null
	return (
		<div key={user.id} className={classNames(cl.user)} {...props}>
			<div className={cl.info}>
				<SimpleAvatar avatar={user?.avatar} />
				<div className={cl.text}>
					<Paragraph className={cl.name}>{user?.name}</Paragraph>
					<Paragraph className={cl.email}>{user?.email}</Paragraph>
				</div>
			</div>
			<i className='icon-close' onClick={() => onDelete(user)} />
		</div>
	)
}

export default UserItem