import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Overlay from '../overlay/Overlay';
import { Header } from '../Typography/Typography';
import cl from './Sidebar.module.css';

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  sidebarIsOpen: boolean; // Булевий тип, оскільки він вказує, чи відкрито Sidebar
  setIsOpen: (isOpen: boolean) => void; // Функція для зміни стану Sidebar
  children: ReactNode; // ReactNode, оскільки children можуть містити будь-який контент
  title: string; // Строковий тип, оскільки це заголовок Sidebar
}

const Sidebar: React.FC<SidebarProps> = ({ 
  sidebarIsOpen, 
  setIsOpen, 
  children, 
  title, 
  ...props 
}) => {
  const sidebarClose = () => {
    setIsOpen(false);
  };

  return (
    <Overlay modalIsOpen={sidebarIsOpen} setIsOpen={setIsOpen} style={{ justifyContent: 'flex-end' }}>
      <div className={classNames(cl.sidebar, sidebarIsOpen && cl.active)} onClick={(e) => e.stopPropagation()} {...props}>
        <div className={cl.header}>
          <i className='icon-close' onClick={sidebarClose} />
          <Header>{title}</Header>
        </div>
        {children}
      </div>
    </Overlay>
  );
};

export default Sidebar;
