import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import cl from './VideosUploadOverlay.module.css'
import { Paragraph } from './../../UI/Typography/Typography';
import { updateProgress } from '../../redux/slices/UploadVideosSlice';
import axios from 'axios';
import VideoItem from './videosUploadOverlay/VideoItem';
import { getAllAvailVideos, getVideos } from '../../redux/slices/VideosSlice';

function VideosUploadOverlay({ videos, setVideos, onUpload, setOnUpload }) {
	const comID = useSelector((state) => state.userInfo?.info?.company)
	const userID = useSelector((state) => state.userInfo.info?.id)
	const iRole = useSelector((state) => state.userInfo.info?.role)
	const progress = useSelector((state) => state.uploadVideos.progress)
	const [controller, setController] = useState(null)

	const [indexUploadingVideo, setIndexUploadingVideo] = useState('')

	const dispatch = useDispatch()

	const getVideosForEnhanced = async () => {
		await dispatch(getAllAvailVideos({ comID, userID }))
	}
	const onGetVideos = async () => {
		if (iRole === 'ENHANCED') {
			await getVideosForEnhanced()
		} else {
			await dispatch(getVideos(comID))
		}
	}

	const onUploadVideo = async (video) => {

		const formData = new FormData()
		formData.append('videoName', video.videoName)
		formData.append('name', video.name)
		formData.append('label', video.name)
		formData.append('videoTheme', video.videoTheme)
		formData.append('videoDescription', video.videoDescription)
		formData.append('file', video.file)
		formData.append('id', video.id)
		dispatch(updateProgress(0))
		setIndexUploadingVideo(0)

		const contr = new AbortController()
		setController(contr)
		const token = localStorage.getItem('token')

		try {
			await axios.post(`${process.env.REACT_APP_MAIN_API_URL}/uploadBunny/${comID}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${token}`
				},
				signal: contr.signal,
				onUploadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					dispatch(updateProgress(percentCompleted))
				}
			})
			setVideos((prevVideos) => prevVideos.filter((v) => v.videoName !== video.videoName));
			setIndexUploadingVideo('');
			toast.success('Video uploaded successfully.')
			onGetVideos()
		} catch (e) {
			if (axios.isCancel(e)) {
				toast.warn('Uploading video is canceled')
			} else if (e.response.data.status === 'limit exceeded') {
				toast.error('Upload limit reached. Delete older videos to free up space or upgrade your plan for additional capacity.')
				setVideos([])
			} else if ((e.code === "ERR_NETWORK" || e.message === 'Network Error') && progress === 100) {
				toast.warn('Video received and in conversion queue. If not visible within a few hours, please re-upload or reach out to support')
				onGetVideos()
				setVideos((prevVideos) => prevVideos.filter((v) => v.videoName !== video.videoName));
			} else if (e.code === "ERR_NETWORK" || e.message === 'Network Error') {
				toast.error("Internet connection lost. Video upload failed. Please retry when you're back online.")
				onGetVideos()
				setVideos((prevVideos) => prevVideos.filter((v) => v.videoName !== video.videoName));
			} else {
				toast.error('Unexpected error occurred during video upload. Please try again. If the issue persists, contact support for assistance.')
				setVideos((prevVideos) => prevVideos.filter((v) => v.videoName !== video.videoName));
				onGetVideos()
			}
			setIndexUploadingVideo('');
		}
	}



	useEffect(() => {
		;
		if (indexUploadingVideo === '' && videos.length > 0) {
			// Если нет текущего загружающегося видео и есть видео в очереди, начинаем загрузку следующего видео
			onUploadVideo(videos[0]);
		}
	}, [indexUploadingVideo, videos]);

	const handleCancelUpload = () => {
		;
		setVideos(videos.filter((video, i) => i !== 0))
		controller.abort()

	};

	const handleRemoveVideo = (index) => {
		;
		if (index === 0) {
			handleCancelUpload()
		} else {
			setVideos(videos.filter((video, i) => i !== index))
		}
	}

	return (
		videos.length === 0 ? null :
			<div className={cl.container}>
				{videos && videos.map((video, index) => {
					return (
						index <= 1 &&
						<VideoItem
							video={video}
							index={index}
							key={index}
							progress={index === 0 ? progress : 0}
							handleRemoveVideo={handleRemoveVideo}
						/>
					)
				})}

				{videos?.length > 2 && <div className={cl.queue}>
					<Paragraph>
						Queue
					</Paragraph>
					<Paragraph>
						{videos?.length - 2}
					</Paragraph>
				</div>}
			</div>
	)
}

export default VideosUploadOverlay