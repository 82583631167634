import React, { useEffect, useState } from 'react';

import cl from './EmptyTable.module.css';

import video from '../../assets/icons/empty/video_empty.png';
import training from '../../assets/icons/empty/training_empty.png';
import saved from '../../assets/icons/empty/trainings_empty.png';
import classrooms from '../../assets/icons/empty/classrooms_empty.png';
import devices from '../../assets/icons/empty/devices_empty.png';
import teams from '../../assets/icons/empty/teams_empty.png';
import statistics from '../../assets/icons/empty/statistics_empty.png';
import update_soon from '../../assets/icons/empty/update_coming_soon.png';
import { Paragraph } from '../Typography/Typography';

interface EmptyTableProps extends React.HTMLAttributes<HTMLDivElement>{
  icon?: string; 
  style?: React.CSSProperties; 
}

const EmptyTable: React.FC<EmptyTableProps> = ({ icon = 'users', style = {} }) => {
  const [img, setImg] = useState<string>(''); 
  const [descr, setDescr] = useState<JSX.Element | string>(''); 

  useEffect(() => {
    if (icon === 'video') {
      setImg(video);
      setDescr('There are no available videos yet');
    } else if (icon === 'training') {
      setImg(training);
      setDescr('There are no available trainings yet');
    } else if (icon === 'saved') {
      setImg(saved);
      setDescr(
        <>
          <span>It's empty here</span> <br /> Download the videos and <br /> they will appear here
        </>
      );
    } else if (icon === 'classrooms') {
      setImg(classrooms);
      setDescr('There are no available classrooms yet');
    } else if (icon === 'devices') {
      setImg(devices);
      setDescr('There are no available devices yet');
    } else if (icon === 'teams') {
      setImg(teams);
      setDescr('There are no available teams yet');
    } else if (icon === 'statistics') {
      setImg(statistics);
      setDescr('No statistics');
    } else if (icon === 'vrSessionsStatistic') {
      setImg(statistics);
      setDescr('No sessions yet');
    } else if (icon === 'users') {
      setImg(teams);
      setDescr('There are no available users yet');
    } else if (icon === 'soon') {
      setImg(update_soon);
      setDescr(
        <>
          <span>Upgrade coming soon</span> <br /> Upgrade to a Standard Plan <br />
          on desktop for now. <br />
          Mobile option arriving soon!
        </>
      );
    }
  }, [icon]);

  return (
    <div className={cl.card} style={style}>
      <img src={img} alt="empty card" />
      <Paragraph>{descr}</Paragraph>
    </div>
  );
};

export default EmptyTable;
