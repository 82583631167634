import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'

import Input from '../../UI/input/Input'
import Checkbox from '../../UI/checkbox/Checkbox'
import { Caption, TextError } from '../../UI/Typography/Typography'
import Button from '../../UI/Button/Button'

import cl from '../../styles/components/forms/PasswordForm.module.css'
import { validatePassword } from '../../utils/Validation'
import classNames from 'classnames'


function PasswordForm({ policy = false, regInfo, password, setPassword, onSend, sending, button = 'Sign up', backBtn = null, error = false, ...props }) {
	const [err, setErr] = useState(false)
	const [repeatPass, setRepeatPass] = useState(password)
	const [privacyChecked, setPrivacyChecked] = useState(false)

	const checkPass = () => {
		return password === repeatPass ? setErr(false) : setErr(true)
	}
	const cleanPass = () => {
		setErr('')
		setPassword('')
		setRepeatPass('')
	}

	useEffect(() => {
		if (repeatPass !== '') {
			checkPass()
		} else {
			setErr(false)
		}
	}, [repeatPass, password])

	const equal = password === repeatPass && password !== ''

	return (
		<Formik
			initialValues={{
				pass: password,
				repeatPass: password,
			}}
			initialErrors={{
				pass: '',
				repeatPass: '',
				upper: true,
				len: true,
				num: true,
				symb: false,
			}}

			validate={values => {
				const errors = {};

				if (!values.pass) {
					errors.pass = 'Required';
				}
				if (/[A-Z]/.test(values.pass)) {
					errors.upper = false;
				} else {
					errors.upper = true;
				}

				if (values.pass.length >= 8) {
					errors.len = false
				} else {
					errors.len = true
				}

				if (/[0-9]/.test(values.pass)) {
					errors.num = false
				} else {
					errors.num = true
				}

				if (/[&=+<>,_'-\s]/.test(values.pass)) {
					errors.symb = true
				} else {
					errors.symb = false
				}

				if (values.pass !== values.repeatPass) {
					errors.repeatPass = 'Passwords is not equal';
				} else {
					errors.repeatPass = ''
				}


				setPassword(values.pass)

				return errors;
			}}
			onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
				onSend()
			}}

		>
			{({ isSubmitting, errors, dirty, values, setErrors, setSubmitting, resetForm, validateForm }) => (
				<Form className={cl.form}>
					<Field name="pass">
						{({
							field, // { name, value, onChange, onBlur }
							form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							meta,
						}) => (
							<div className={cl.input}>
								<Input
									//value={password}
									//onChange={(e) => setPassword(e.target.value)}
									className={cl.input}
									type='password'
									label='Password'
									helperText={errors.symb && "Invalid character (& = + < > , _ ' - space)"}
									{...field}
									err={(meta.touched && (errors.pass || errors.upper || errors.len || errors.symb || errors.num))}
									disabled={sending}
								/>
								<div className={cl.conditions}>
									<div className={classNames(cl.condition, !errors.len && cl.checked)}>
										<i className={classNames(errors.len ? 'icon-correct' : 'icon-correct_green')} />
										<Caption>Min 8 characters</Caption>
									</div>
									<div className={classNames(cl.condition, !errors.upper && cl.checked)}>
										<i className={classNames(errors.upper ? 'icon-correct' : 'icon-correct_green')} />
										<Caption>Uppercase</Caption>
									</div>
									<div className={classNames(cl.condition, !errors.num && cl.checked)}>
										<i className={classNames(errors.num ? 'icon-correct' : 'icon-correct_green')} />
										<Caption>1 number</Caption>
									</div>

								</div>
							</div>
						)}
					</Field>
					<Field name="repeatPass">
						{({
							field, // { name, value, onChange, onBlur }
							form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							meta,
						}) => (
							<div className={cl.input}>
								<Input
									err={(meta.touched && errors.repeatPass)}
									helperText={(meta.touched && errors.repeatPass)}
									{...field}
									type='password'
									label='Repeat password'
									disabled={sending} />
							</div>
						)}
					</Field>

					{policy &&
						<div className={cl.policy}>
							<Checkbox checked={privacyChecked} onChange={() => {
								setPrivacyChecked(!privacyChecked)
								validateForm()
							}} >
								<Caption>
									I agree to the <a href='https://metaenga.com/terms-of-service' target='_blank'>MetaEnga’s Terms of Service</a> and <a href='https://metaenga.com/privacy-policy' target='_blank'>Privacy Policy</a>
								</Caption>
							</Checkbox>
						</div>
					}
					{error && <div className="a-form__error">
						<TextError>{error}</TextError>
					</div>
					}

					<div className={cl.button}>
						{backBtn && <Button type='button' secondary onClick={backBtn}>Back</Button>}
						<Button type='button' disabled={errors.pass || errors.repeatPass || errors.len || errors.upper || errors.num || errors.symb || (policy && !privacyChecked) || sending} onClick={() => onSend({ setErrors, resetForm })} loading={sending}>{button}</Button>

					</div>

				</Form>
			)}
		</Formik>
	)
}

export default PasswordForm