import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth';

import TableControl from '../../../components/table/tableControl/TableControl';
import TeamItemPR from '../../../components/table/tableItem/TeamItemPR';

import EmptyTable from '../../../UI/emptyTable/EmptyTable';
import Spinner from '../../../UI/spinner/Spinner';
import TeamNameColumn from '../../../UI/table/TeamNameColumn';
import SpinnerOverlay from '../../../UI/spinner/SpinnerOverlay';

function TeamsTablePR({ data, checked, setChecked, searchFilter, activeActions, setSelectAction, loading }) {
	const winWidth = useWindowWidth();
	const navigate = useNavigate()
	const verification = useSelector((state) => state.company?.info?.confirmed_email)

	const sortedTeams = useMemo(() => {
		if (!data) return null
		let sortedTeams = [...data]

		if (searchFilter) {
			return sortedTeams.filter((item) => item?.groupName?.toUpperCase().includes(searchFilter.toUpperCase()))
		}

		return sortedTeams
	}, [data, searchFilter])


	const nameTemplate = (rowData) => {
		return <TeamNameColumn info={rowData} />
	};

	const checkChecked = (item) => {
		return checked.some(checkedItem => checkedItem.groupName === item.groupName)
	}

	const [action, setAction] = useState();
	const timerRef = useRef();
	const isLongPress = useRef(false);
	const checking = useRef(false)

	const setCheckedId = (item, checked) => {
		if (checked === 'uncheck') {
			setChecked([])
			return
		}
		if (checked === undefined || checked.length === 0) {
			setChecked([item])
			return
		}

		const isChecked = checked.some(checkedItem => checkedItem.groupName === item.groupName);

		if (isChecked) {
			setChecked(checked.filter(checkedItem => checkedItem.groupName !== item.groupName));
		} else {
			setChecked([...checked, item]);
		}
	}

	useEffect(() => {
		return () => {
			// Очистка таймера при размонтировании компонента
			clearTimeout(timerRef.current);
		};
	}, []);

	function startPressTimer(data) {
		isLongPress.current = false;
		timerRef.current = setTimeout(() => {
			isLongPress.current = true;
			setAction('longpress');
			checking.current = !checking.current
			if (!checking.current) {
				setCheckedId('', 'uncheck')
				isLongPress.current = false
				return
			}
			handleOnClick(data) // эмулячия клика на тач скрин устройствах
			//setChecked(data.id, checked)
		}, 750);
	}

	function handleOnClick(data) {
		if (isLongPress.current) {
			if (checked.length > 0) {
				setCheckedId('', 'uncheck')
				setAction('')
				return
			}
			setCheckedId(data, checked)
			return;
		}
		if (checking.current) {
			//if (checked.length === 1 && checked.includes(data.groupName)) {
			//	setCheckedId('', 'uncheck')
			//	setAction('click');
			//	checking.current = false
			//	return
			//}
			setCheckedId(data, checked)
			setAction('click');
		} else {
			setCheckedId('', 'uncheck')
			setAction('click');
			resetAction(); // Сброс состояния после клика
			navigate(`/home/settings/team/teams/${data.groupName}`)
		}
	}

	function handleOnMouseUp(e) {
		clearTimeout(timerRef.current);
		if (isLongPress.current) {
			resetAction(); // Сброс состояния после долгого нажатия
		}
		e.target.removeEventListener('contextmenu', preventContextMenu);
	}

	function handleOnTouchStart(e, data) {
		startPressTimer(data);
		e.target.removeEventListener('contextmenu', preventContextMenu);
	}

	function preventContextMenu(e) {
		e.preventDefault();
	}

	function resetAction() {
		setTimeout(() => setAction(null), 100); // Небольшая задержка перед сбросом состояния
	}

	useEffect(() => {
		if (checked.length === 0) {
			checking.current = false
			isLongPress.current = false
		}
	}, [checked])


	const mobTable = (sortedTeams) => {
		return (
			<div>
				<div style={{position: 'relative'}}>
					<TableControl
						checked={checked}
						setChecked={setChecked}
						icons={activeActions}
						setSelectAction={setSelectAction}
						data={data}
						verification={verification} />

					{
						(sortedTeams && sortedTeams.length > 0)
							&& sortedTeams.map(team => <TeamItemPR
								key={team.groupName}
								checked={checkChecked(team)}
								info={team}
								onClick={() => handleOnClick(team)}
								onMouseDown={(e) => handleOnTouchStart(e, team)}
								onMouseUp={handleOnMouseUp}
								onTouchStart={(e) => handleOnTouchStart(e, team)}
								onTouchEnd={handleOnMouseUp} />
							)
							
					}
					{(!sortedTeams || sortedTeams.length === 0) && !loading && <p key={Math.random()}><EmptyTable icon='teams' /></p>}
					{loading && sortedTeams && <SpinnerOverlay />}
				</div>
				{loading && !sortedTeams && <div style={{ padding: '20px 0' }}>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
					<div style={{ marginBottom: 2 }}>
						<Skeleton width='100%' height='50px' />
					</div>
				</div>}

			</div>
		);
	};

	const onSelectionChange = (e) => {
		setChecked(e.value);
	}

	return (

		winWidth > TABLET_WIDTH ?
			<div style={{ overflowX: 'hidden' }}>
				<TableControl
					checked={checked}
					setChecked={setChecked}
					icons={activeActions}
					setSelectAction={setSelectAction}
					data={data}
					verification={verification} />

				{sortedTeams ? <DataTable
					value={sortedTeams}
					selectionMode={true}
					selection={checked}
					onSelectionChange={onSelectionChange}
					dataKey="groupName"
					tableStyle={{ minWidth: '45rem' }}
					selectionPageOnly
					emptyMessage={<EmptyTable icon='teams' />}
					loading={loading}
					loadingIcon={<Spinner />}
				>
					<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
					<Column field="groupName" header="Team name" sortable sortField='groupName' body={nameTemplate}></Column>
					<Column field="usersCount" header="Users" headerTooltip="The number of users in the group" headerTooltipOptions={{ position: 'top' }} sortable sortField='usersCount' ></Column>
					<Column field="lessons" sortable header="Lessons" headerTooltipOptions={{ position: 'top' }} headerTooltip='Number of lessons available'></Column>
				</DataTable>
					: !sortedTeams && loading ?
						<DataTable
							value={[{ name: '', id: '' }]}
							selectionMode={true}
							selection={checked}
							onSelectionChange={onSelectionChange}
							dataKey="id" tableStyle={{ minWidth: '45rem' }}
							selectionPageOnly
							emptyMessage={<EmptyTable icon='users' />}
						>
							<Column selectionMode="multiple" headerStyle={{ width: 50 }} headerClassName='pr-table-header-checkbox'></Column>
							<Column field="groupName" header="Team name" sortable sortField='groupName' body={<Skeleton />}></Column>
							<Column field="usersCount" header="Users" headerTooltip="The number of users in the group" headerTooltipOptions={{ position: 'top' }} sortable sortField='usersCount' body={<Skeleton />}></Column>
							<Column field="lessons" sortable header="Lessons" headerTooltip='Number of lessons available' body={<Skeleton />}></Column>
						</DataTable> : null
				}


			</div>
			: <>
				{mobTable(sortedTeams)}
			</>
	)
}

export default TeamsTablePR