import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortArray } from '../../utils/SortArrayByAnotherArr';

export const getPlaylist = createAsyncThunk(
	'socketRoom/getPlaylist',
	async (data, { rejectWithValue }) => {
		const { socket, classId } = data
		const userId = localStorage.getItem('id')
		try {
			socket.emit('getPlaylist', { classId, userId })
			return 'joined'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addVideoToCR = createAsyncThunk(
	'socketRoom/addVideoToCR',
	async (data, { rejectWithValue }) => {
		const { socket, classId, videoArr, userId } = data
		
		try {
			await socket.emit('addVideo', {
				classId,
				videoArr,
				userId
			})
			return 'video added'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


export const startVideo = createAsyncThunk(
	'socketRoom/startVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId, userId } = data
		try {
			socket.emit('startVideo', {
				classId,
				userId
			})
			return 'video started'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const pauseVideo = createAsyncThunk(
	'socketRoom/pauseVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId, userId } = data
		try {
			socket.emit('pauseVideo', {
				classId,
				userId
			})
			return 'video paused'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const nextVideo = createAsyncThunk(
	'socketRoom/nextVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId } = data
		const userId = localStorage.getItem('id')
		try {
			await socket.emit('nextVideo', {
				classId,
				userId
			})
			return 'video next'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const prevVideo = createAsyncThunk(
	'socketRoom/prevVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId } = data
		const userId = localStorage.getItem('id')
		try {
			await socket.emit('previousVideo', {
				classId,
				userId
			})
			return 'video prev'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const endSession = createAsyncThunk(
	'socketRoom/endSession',
	async (data, { rejectWithValue }) => {
		const { socket, classId } = data
		const userId = localStorage.getItem('id')
		try {
			await socket.emit('endSession', {
				classId,
				userId
			})
			return 'Session end'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const pushVideosToCR = createAsyncThunk(
	'socketRoom/pushVideosToCR',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const setVideosToCR = createAsyncThunk(
	'socketRoom/setVideosToCR',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const reorderVideos = createAsyncThunk(
	'socketRoom/reorderVideos',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


export const moveVideo = createAsyncThunk(
	'socketRoom/moveVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId, userId, videoId, whereToMove } = data
		try {
			socket.emit('moveVideo', {
				classId,
				userId,
				videoId,
				whereToMove
			})
			return 'video moved'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const removeVideo = createAsyncThunk(
	'socketRoom/removeVideo',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const sendRemoveVideo = createAsyncThunk(
	'socketRoom/sendRemoveVideo',
	async (data, { rejectWithValue }) => {
		const { socket, classId, userId, videoId } = data
		;
		try {
			socket.emit('removeVideo', {
				classId,
				userId,
				videoId
			})
			return 'video removed'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const socketRoomAdapter = createEntityAdapter({
	selectId: (user) => 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6InN0b3Jtb2ZlQGdtYWlsLmNvbSIsImlhdCI6MTY3NDA0NDUwMCwiZXhwIjoxNjc0MzAzNzAwfQ.ijXRAR60ziSok51Wi20GnkMqJckbtl648YjW_7T_LQk'
})

const socketRoomVideosSlice = createSlice({
	name: 'roomVideos',
	initialState: socketRoomAdapter.getInitialState({
		loading: false,
		error: null,
		playlist: [],
		dndplaylist: [],
		currentVideo: null,
		indexVideoPlaying: null,
	}),
	reducers: {
		setIndexVideoPlaying: (state, action) => {
			state.indexVideoPlaying = action.payload
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(startVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(startVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(startVideo.rejected, (state, action) => {
				state.loading = false
			})
			.addCase(pauseVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(pauseVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(pauseVideo.rejected, (state, action) => {
				state.loading = false
			})
			.addCase(nextVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(nextVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(nextVideo.rejected, (state, action) => {
				state.loading = false
			})
			.addCase(prevVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(prevVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(prevVideo.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(endSession.pending, (state, action) => {
				state.loading = true
			})
			.addCase(endSession.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(endSession.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(getPlaylist.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getPlaylist.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(getPlaylist.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(addVideoToCR.pending, (state, action) => {
				state.loading = true
			})
			.addCase(addVideoToCR.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(addVideoToCR.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(pushVideosToCR.pending, (state, action) => {
				state.loading = true
			})
			.addCase(pushVideosToCR.fulfilled, (state, action) => {
				state.loading = false
				state.dndplaylist = [...state.dndplaylist, ...action.payload]
			})
			.addCase(pushVideosToCR.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(setVideosToCR.pending, (state, action) => {
				state.loading = true
			})
			.addCase(setVideosToCR.fulfilled, (state, action) => {
				state.loading = false
				state.dndplaylist = action.payload
				if (action.payload.length === 0) {
					//state.playlist = []
					state.currentVideo = null
					return
				}
				//state.playlist = action.payload.map(video => ({
				//	src: video.url,
				//	type: "video/mp4",
				//	title: video.videoName,
				//}))
				state.currentVideo = {
					src: action.payload[0].url,
					type: "video/mp4",
					title: action.payload[0].videoName,
				}
			})
			.addCase(setVideosToCR.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(reorderVideos.pending, (state, action) => {
				state.loading = true
			})
			.addCase(reorderVideos.fulfilled, (state, action) => {
				state.loading = false
				const sortedPlaylist = sortArray(state.dndplaylist, action.payload, 'id')
				state.dndplaylist = sortedPlaylist
				//state.playlist = sortedPlaylist.map(video => ({
				//	src: video.url,
				//	type: "video/mp4",
				//	title: video.videoName,
				//}))
				state.currentVideo = {
					src: state.dndplaylist[0].url,
					type: "video/mp4",
					title: state.dndplaylist[0].videoName,
				}
			})
			.addCase(reorderVideos.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(moveVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(moveVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(moveVideo.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(removeVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(removeVideo.fulfilled, (state, action) => {
				state.loading = false
				state.dndplaylist = state.dndplaylist.filter(video => video.id !== action.payload)
			})
			.addCase(removeVideo.rejected, (state, action) => {
				state.loading = false
			})
			.addCase(sendRemoveVideo.pending, (state, action) => {
				state.loading = true
			})
			.addCase(sendRemoveVideo.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(sendRemoveVideo.rejected, (state, action) => {
				state.loading = false
			})

	}
})


export const { setIndexVideoPlaying } = socketRoomVideosSlice.actions

export default socketRoomVideosSlice.reducer
