import axios from 'axios';

export class TeamSettingsService {
	static getTeamUsers(token, companyId, groupName) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getGroupUsers/${companyId}/${groupName}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static addToTeam(token, groupName, userId, userArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/addToGroup`, {
			userId,
			groupName,
			userArr
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static removeFromTeam(token, adminId, groupName, userId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/delete/group/user`, {
			adminId,
			groupName,
			userId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}