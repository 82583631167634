import React from 'react';
import { TabPanel, TabView } from 'primereact/tabview';

import './TabsPrime.css';

interface TabsPrimeProps extends React.HTMLAttributes<HTMLDivElement> {
  links: string[]; 
  tabs: React.ReactNode[]; 
  settedTab?: number; 
  activeTab?: number; 
  onSetTab?: (index: number) => void; 
}

const TabsPrime: React.FC<TabsPrimeProps> = ({
  links,
  tabs,
  settedTab,
  activeTab = 0,
  onSetTab,
  ...props
}) => {
  const onTabChange = (e: { index: number }) => {
    if (onSetTab) onSetTab(e.index);
  };

  return (
    <TabView onBeforeTabChange={onTabChange} activeIndex={activeTab} {...props}>
      {tabs.map((tab, index) => (
        <TabPanel header={links[index]} key={index}>
          {tab}
        </TabPanel>
      ))}
    </TabView>
  );
};

export default TabsPrime;
