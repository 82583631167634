import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { PersonalVRStatisticService } from './../../services/PersonalVRStatisticServices';


function calculateRating(finalScore, maxScore) {
	if (finalScore === 'n/a') return 'n/a'
	const percentage = (finalScore / maxScore) * 100;
	const rating = (percentage / 100) * 5;
	return rating.toFixed(1) || 0;
}

const personalVRSessionsStatisticAdapter = createEntityAdapter({
	selectId: (entity) => uid(8),
});

export const getTrainingSessionsTablePersonal = createAsyncThunk(
	"personalVRSessionsStatistic/getTrainingSessionsTablePersonal",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("token");
		const { comID, trainingId, userId } = data;
		try {
			const response = await PersonalVRStatisticService.getTrainingSessionsTablePersonal(
				token,
				comID,
				userId,
				trainingId
			);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const personalVRSessionsStatisticSlice = createSlice({
	name: "personalVRSessionsStatistic",
	initialState: personalVRSessionsStatisticAdapter.getInitialState({
		loading: true,
		error: "",
		sessions: null,
	}),
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setSessions: (state, action) => {
			state.sessions = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getTrainingSessionsTablePersonal.pending, (state) => {
			state.loading = true;
			state.error = "";
		})
			.addCase(getTrainingSessionsTablePersonal.fulfilled, (state, action) => {
				state.loading = false;


				let sessions = action.payload.data.sessions.map(session => {
					if (session.finalScore === 'n/a') {
						return {
							...session,
							rating: 'n/a'
						}
					} else {
						return {
							...session,
							rating: calculateRating(session.finalScore, session.maxScore)
						}

					}
				})


				let trainingData = { ...action.payload.data, sessions }

				if (action.meta.arg.trainingId == 101) {
					trainingData.name = "Feeder Protection Relay Training for Operators";
				}
				state.sessions = {
					...state.sessions,
					[action.meta.arg.trainingId]: trainingData
				};

			})
			.addCase(getTrainingSessionsTablePersonal.rejected, (state, action) => {
				state.error = "Session not found";
				state.loading = false;
			});
	},
});

export const { setError, setLoading, setSessions } = personalVRSessionsStatisticSlice.actions;
export default personalVRSessionsStatisticSlice.reducer;
