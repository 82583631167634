import axios from "axios";


export class LoginService {

	static registration(data) {
		const { password, regInfo } = data
		const { company, email, regtoken, userrole } = regInfo
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/regLink`, {
			password,
			"token": regtoken,
			company,
			email,
			'role': userrole
		})
	} 

	static getRegInfo(token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/getDataFromLink`, {
			token
		})
		//{company: 'Comp', email: 'Huny'}
	}

	static login(login, password) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/authenticate/user `, {
			login,
			password
		})
	}

	static async checkToken(token) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/checkUser`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendPassRecovery(email) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/sendPassRecovery`, {
			email
		})
	}

	static verifyCode(email, code) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/verifyCode/${email}/${code}`)
	}

	static changePass(email, code, newPass) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/acceptPassRecovery`, {
			email,
			code,
			newPass
		})
	}

	static changePassFromInfo(oldPass, newPass) {
		const id = localStorage.getItem('id')
		const token = localStorage.getItem('token')
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/changePass`, {
			id,
			oldPass,
			newPass
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static selfRegistr(email, firstName, companyName) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/register/owner/free`, {
			email,
			firstName,
			companyName,
			lastName: ' ',
		})
	} // OLD FOR DELETE


	static selfRegistrConfirm(token, password) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/register/owner/confirmation`, {
			token,
			password
		})
	} // OLD FOR DELETE


	static getLoginCode(email) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/six/code/generation`, {
			email
		})
	}

	static verifyLoginCode(email, code) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/generateToken`, {
			email,
			code
		})
	}

	static loginByGoogle(token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/register/company/google`, {
			token
		})
	}

	static newRegisterWithoutConfirm(FullName, Email, Company, Password) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/register/company/metaenga`, {
			FullName, Email, Company, Password, ConfirmPassword: Password, typeRegistration: false
		})
	}

	static newRegistrationConfirmation(token) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/confirmation/company/metaenga`, {
			token
		})
	}
	static newRegistrationConfirmationResendMail(Email) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/resend`, {
			Email
		})
	}
}