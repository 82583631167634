import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DeviceService } from '../../services/DeviceServices';

export const getDevices = createAsyncThunk(
	'devices/getDevices',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const response = await DeviceService.getDevices(token, comID)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getDevice = createAsyncThunk(
	'devices/getDevice',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, deviceID } = data
		try {
			const response = await DeviceService.getDevice(token, comID, deviceID)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteDevice = createAsyncThunk(
	'devices/deleteDevice',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, deviceID } = data
		try {
			const response = await DeviceService.deleteDevice(token, comID, deviceID)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const renameDevice = createAsyncThunk(
	'devices/renameDevice',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, deviceID, name } = data
		try {
			const response = await DeviceService.renameDevice(token, comID, deviceID, name)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getDeviceTrainings = createAsyncThunk(
	'devices/getDeviceTrainings',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, deviceID } = data
		try {
			const response = await DeviceService.getDeviceTrainings(token, comID, deviceID)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteDeviceTraining = createAsyncThunk(
	'devices/deleteDeviceTraining',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, deviceID, trainingID } = data
		try {
			const response = await DeviceService.deleteDeviceTraining(token, comID, deviceID, trainingID)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


const devicesAdapter = createEntityAdapter({
	selectId: (device) => device.id,
})

const devicesSlice = createSlice({
	name: 'devices',
	initialState: devicesAdapter.getInitialState({
		loading: false,
		error: null,
		devices: null,
		loadingDevices: false,
		device: null,
		deviceTrainingLoadings: false
	}),
	reducers: {
		setDevice: (state, action) => {
			state.device = action.payload
		},
		editDevice: (state, action) => {
			state.device = {
				...state.device,
				...action.payload
			}
		},
		cleanDevice: (state) => {
			state.device = null
		},
		cleanDevices: (state) => {
			state.devices = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDevices.pending, (state) => {
				state.loading = true
			})
			.addCase(getDevices.fulfilled, (state, action) => {
				state.loading = false
				state.devices = action.payload.data
			})
			.addCase(getDevices.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(getDevice.pending, (state) => {
				state.loading = true
			})
			.addCase(getDevice.fulfilled, (state, action) => {
				state.loading = false
				state.device = action.payload.data[0]
			})
			.addCase(getDevice.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(deleteDevice.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteDevice.fulfilled, (state, action) => {
				state.loading = false
				state.device = null
				let devices = state.devices.filter(item => item.id !== action.meta.arg.deviceID)
				state.devices = devices
			})
			.addCase(deleteDevice.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(renameDevice.pending, (state) => {
				state.loading = true
			})
			.addCase(renameDevice.fulfilled, (state, action) => {
				state.loading = false
				let device
				if (state.devices.length > 0) {
					device = state.devices.find(item => item.id === action.meta.arg.deviceID)
				} else {
					device = state.device
				}

				device.name = action.meta.arg.name
				state.device = device

				if (state.devices.length > 0) {
					let devices = state.devices.map(item => {
						if (item.id === action.meta.arg.deviceID) {
							item.name = action.meta.arg.name
						}
						return item
					})
					state.devices = devices
				}
			})
			.addCase(renameDevice.rejected, (state, action) => {
				state.loading = false
				state.device = state.devices.find(item => item.id === action.meta.arg.deviceID)
				state.error = action.payload
			})

			.addCase(getDeviceTrainings.pending, (state) => {
				state.deviceTrainingLoadings = true
			})
			.addCase(getDeviceTrainings.fulfilled, (state, action) => {
				state.deviceTrainingLoadings = false
				state.device = {
					...state.device,
					trainings: action.payload
				}
			})
			.addCase(getDeviceTrainings.rejected, (state, action) => {
				state.deviceTrainingLoadings = false
				state.device.trainings = []
				state.error = action.payload
			})

			.addCase(deleteDeviceTraining.pending, (state) => {
				state.deviceTrainingLoadings = true
			})
			.addCase(deleteDeviceTraining.fulfilled, (state, action) => {
				state.deviceTrainingLoadings = false
			})
			.addCase(deleteDeviceTraining.rejected, (state, action) => {
				state.deviceTrainingLoadings = false
				state.error = action.payload
			})
	}
})

export const { setDevice, editDevice, cleanDevice, cleanDevices } = devicesSlice.actions;

export default devicesSlice.reducer