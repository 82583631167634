import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import ReactTooltip, { TooltipProps } from "react-tooltip";

import cl from './Tooltip.module.css';

interface TooltipPropsExtended extends TooltipProps {
  id: string; // обов'язковий ідентифікатор для прив'язки підказки
  black?: boolean; // налаштування для чорної теми
  children: React.ReactNode; // контент, який буде вказано як підказка
}

const Tooltip: React.FC<TooltipPropsExtended> = ({ id, black = false, children, ...props }) => {
  const content = useMemo(() => {
    if (typeof children === 'string') {
      const sanitizedContent = DOMPurify.sanitize(children);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
    } else {
      return children;
    }
  }, [children]);

  return (
    black ? (
      <ReactTooltip
        className={cl.tooltip__black}
        scrollHide
        id={id}
        place="bottom"
        effect="solid"
        offset={{ bottom: -8 }}
        arrowColor="transparent"
        backgroundColor="#2E3A4B"
        {...props}
      >
        {content}
      </ReactTooltip>
    ) : (
      <ReactTooltip
        className={cl.tooltip}
        id={id}
        scrollHide
        place="right"
        effect="solid"
        arrowColor="#2E3A4B"
        backgroundColor="#2E3A4B"
        {...props}
      >
        {content}
      </ReactTooltip>
    )
  );
};

export default Tooltip;
