import React from 'react';
import { HeaderMid } from '../Typography/Typography';
import cl from './HeaderPage.module.css';


interface HeaderPageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode; 
}

const HeaderPage: React.FC<HeaderPageProps> = ({ children, ...props}) => {
  return (
    <HeaderMid className={cl.header} {...props}>
      {children}
    </HeaderMid>
  );
}

export default HeaderPage;
