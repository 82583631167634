import axios from "axios";

export class StatisticVRSessionsServices{
    static getVRTrainingSessions(token, comID, trainingId){
        return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/training/session/data/${comID}/${trainingId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    

}