import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import cl from './Button.module.css';
import Spinner from '../spinner/Spinner';


interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	left?: boolean;
	right?: boolean;
	icon?: string;
	textbtn?: boolean;
	linkbtn?: boolean;
	secondary?: boolean;
	texthover?: boolean;
	loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	left = false,
	right = false,
	icon = '',
	textbtn = false,
	linkbtn = false,
	secondary = false,
	texthover = false,
	className = '',
	loading = false,
	...props
}) => {
	const [svg, setSvg] = useState('');


	useEffect(() => {
		switch (icon) {
			case 'plus':
				setSvg('plus');
				break;
			case 'upload':
				setSvg('upload');
				break;
			case 'download':
				setSvg('file-download');
				break;
			case 'downloaded':
				setSvg('offline_pin');
				break;
			case 'start':
				setSvg('start_session');
				break;
			case 'pause':
				setSvg('pause_circle');
				break;
			case 'play':
				setSvg('play_circle');
				break;
			case 'down':
				setSvg('chevron-down');
				break;
			case 'up':
				setSvg('chevron-up');
				break;
			case 'right':
				setSvg('chevron-right');
				break;
			case 'left':
				setSvg('chevron-left');
				break;
			case 'end':
				setSvg('stop_session');
				break;
			case 'edit':
				setSvg('edit');
				break;
			case 'addCR':
				setSvg('user_group');
				break;
			case 'assign':
				setSvg('user-add');
				break;
			case 'insights':
				setSvg('view-insigts');
				break;
			default:
				setSvg('');
		}
	}, [icon]);


	const styleGuide = secondary ? cl.secondary : textbtn ? cl.textbtn : linkbtn ? cl.linkbtn : texthover ? cl.texthover : cl.primary;


	const buttonDisabled = (
		<button
			onClick={onClick}
			className={classNames(cl.btn, styleGuide, className)}
			disabled
			{...props}
		>
			{left && <span className={classNames(cl.icon, cl.left)}><i className={`icon-${svg}`}></i></span>}
			{children}
			{right && <span className={classNames(cl.icon, cl.right)}><i className={`icon-${svg}`}></i></span>}
			{loading && <div className={cl.spinner}><Spinner width={30} /></div>}
		</button>
	);


	const buttonEnabled = (
		<button
			onClick={onClick}
			className={classNames(cl.btn, styleGuide, className)}
			{...props}
		>
			{left && icon && <span className={classNames(cl.icon, cl.left)}><i className={`icon-${svg}`}></i></span>}
			{children}
			{right && icon && <span className={classNames(cl.icon, cl.right)}><i className={`icon-${svg}`}></i></span>}
		</button>
	);

	return loading || props.disabled ? buttonDisabled : buttonEnabled;
};

export default Button;
