import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UsersService } from '../../services/UsersServices';

export const getUsers = createAsyncThunk(
	'users/getUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const res = await UsersService.getUsers(token, data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deactivateUsers = createAsyncThunk(
	'users/deactivateUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const adminId = localStorage.getItem('id')
		const {userId, email} = data
		try {
			const res = await UsersService.deactivateUser(adminId, userId, email, token)
			return { data: res.data, userId }
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const activateUsers = createAsyncThunk(
	'users/activateUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const adminId = localStorage.getItem('id')
		const {userId, email} = data
		try {
			const res = await UsersService.activateUser(adminId, userId, email, token)
			return { data: res.data, userId }
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteUser = createAsyncThunk(
	'users/deleteUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const adminId = localStorage.getItem('id')
		const {userId, email} = data
		try {
			const res = await UsersService.deleteUser(adminId, userId, email, token)
			return { data: res.data, userId }
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


export const addContentToUser = createAsyncThunk(
	'users/addContentToUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		const toUser = data.toUser
		const contentArr = data.contentArr
		try {
			const res = await UsersService.addContentToUser(token, userId, toUser, contentArr)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const addMetaengaContentToUser = createAsyncThunk(
	'users/addMetaengaContentToUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { company, user, video } = data
		try {
			const res = await UsersService.addMetaengaContentToUser(token, company, user, video)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const removeContentFromUser = createAsyncThunk(
	'users/removeContentFromUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		const toUser = data.toUser
		const contentArr = data.contentArr
		try {
			const res = await UsersService.removeContentFromUser(token, userId, toUser, contentArr)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const removeMetaengaContentFromUser = createAsyncThunk(
	'users/removeMetaengaContentFromUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { company, user, video } = data
		try {
			const res = await UsersService.removeMetaengaContentFromUser(token, company, video, user)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const changeUserRole = createAsyncThunk(
	'users/changeUserRole',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const id = localStorage.getItem('id')
		const { changeId, role } = data
		try {
			await UsersService.changeRole(token, id, changeId, role)
			return 'success'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


const usersAdapter = createEntityAdapter({
	selectId: (user) => user.id,
})



const usersSlice = createSlice({
	name: 'users',
	initialState: usersAdapter.getInitialState({
		loading: false,
		error: null,
		users: null
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.loading = false
				state.users = action.payload.data
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(deactivateUsers.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(deactivateUsers.fulfilled, (state, action) => {
				state.loading = false
				state.users = state.users?.map(user => {
					if (user.id === action.payload.userId) {
						user.status = 'DEACTIVATED'
					}
					return user
				})
			})
			.addCase(deactivateUsers.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(activateUsers.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(activateUsers.fulfilled, (state, action) => {
				state.loading = false
				state.loading = false
				state.users = state.users?.map(user => {
					if (user.id === action.payload.userId) {
						user.status = 'ACTIVE'
					}
					return user
				})
			})
			.addCase(activateUsers.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(deleteUser.pending, (state) => {
				state.error = null
				state.loading = true

			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.loading = false
				state.users = state.users?.filter(user => user.id !== action.payload.userId)
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(addContentToUser.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(addContentToUser.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(addContentToUser.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(addMetaengaContentToUser.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(addMetaengaContentToUser.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(addMetaengaContentToUser.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})


			.addCase(removeContentFromUser.pending, (state) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(removeContentFromUser.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(removeContentFromUser.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(changeUserRole.pending, (state) => {
				state.error = null
				state.loading = true
			})
			.addCase(changeUserRole.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(changeUserRole.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export const selectors = usersAdapter.getSelectors((state) => state.users);

export default usersSlice.reducer