import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginService } from './../../services/LoginServices';

export const fetchRegistrationInfo = createAsyncThunk(
	'login/fetchRegistrationInfo',
	async (token, { rejectWithValue }) => {
		try {
			const response = await LoginService.getRegInfo(token)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.data)
		}
	}
)

export const logining = createAsyncThunk(
	'login/logining',
	async (data, { rejectWithValue }) => {
		const { login, password } = data
		try {
			const res = await LoginService.login(login, password)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const registration = createAsyncThunk(
	'login/registration',
	async (data, { rejectWithValue }) => {
		try {
			const res = await LoginService.registration(data)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const selfRegistration = createAsyncThunk(
	'login/selfRegistration',
	async (data, { rejectWithValue }) => {
		const { email, firstName, companyName } = data
		try {
			const res = await LoginService.selfRegistr(email, firstName, companyName)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const selfRegistrationConfirm = createAsyncThunk(
	'login/selfRegistrationConfirm',
	async (data, { rejectWithValue }) => {
		const { token, password } = data
		try {
			const res = await LoginService.selfRegistrConfirm(token, password)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const checkToken = createAsyncThunk(
	'login/checkToken',
	async (token, { rejectWithValue }) => {
		try {
			const res = await LoginService.checkToken(token)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const changePass = createAsyncThunk(
	'login/changePass',
	async (data, { rejectWithValue }) => {
		const { oldPass, newPass } = data
		try {
			const res = await LoginService.changePassFromInfo(oldPass, newPass)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getLoginCode = createAsyncThunk(
	'login/getLoginCode',
	async (email, { rejectWithValue }) => {
		try {
			const res = await LoginService.getLoginCode(email)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const verifyLoginCode = createAsyncThunk(
	'login/verifyLoginCode',
	async (data, { rejectWithValue }) => {
		const { email, code } = data
		try {
			const res = await LoginService.verifyLoginCode(email, code)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const onLoginByGoogle = createAsyncThunk(
	'login/onLoginByGoogle',
	async (token, { rejectWithValue }) => {
		try {
			const res = await LoginService.loginByGoogle(token)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const newRegistrationWithoutConfirm = createAsyncThunk(
	'login/newRegistrationWithoutConfirm',
	async (data, { rejectWithValue }) => {
		const { email, firstName, companyName, password } = data
		try {
			const res = await LoginService.newRegisterWithoutConfirm(firstName, email, companyName, password)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const newRegistrationConfirmation = createAsyncThunk(
	'login/newRegistrationConfirmation',
	async (token, { rejectWithValue }) => {
		try {
			const res = await LoginService.newRegistrationConfirmation(token)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const newRegistrationConfirmationResendMail = createAsyncThunk(
	'login/newRegistrationConfirmationResendMail',
	async (email, { rejectWithValue }) => {
		try {
			const res = await LoginService.newRegistrationConfirmationResendMail(email)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const loginAdapter = createEntityAdapter({
	selectId: (login) => login.id,
})

const loginSlice = createSlice({
	name: 'login',
	initialState: loginAdapter.getInitialState({
		loading: false,
		error: null
	}),
	reducers: {
		logout: (state, action) => {

			state.loading = false
			state.error = null
		},
		cleanError: (state, action) => {
			state.error = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(logining.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(logining.fulfilled, (state, action) => {
				localStorage.setItem('token', action.payload.token)
				localStorage.setItem('email', action.payload.data.email)
				localStorage.setItem('id', action.payload.data.id)
				state.error = null
				state.loading = false
			})
			.addCase(logining.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(checkToken.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(checkToken.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(checkToken.rejected, (state, action) => {
				state.loading = false
				if (action.payload === 400) {
					state.error = '400'
				}
				if (action.payload === 403) {
					state.error = '403'
				}
			})
			.addCase(registration.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(registration.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(registration.rejected, (state, action) => {
				state.loading = false
				if (action.payload === 402) {
					state.error = 'The company has run out of available licenses. Please contact the administrator.'
				} else {
					state.error = 'Registration error'
				}
			})

			.addCase(selfRegistration.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(selfRegistration.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(selfRegistration.rejected, (state, action) => {
				state.loading = false
				state.error = 'Registration error'
			})

			.addCase(selfRegistrationConfirm.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(selfRegistrationConfirm.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(selfRegistrationConfirm.rejected, (state, action) => {
				state.loading = false
				state.error = 'Registration error'
			})

			.addCase(fetchRegistrationInfo.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(fetchRegistrationInfo.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(fetchRegistrationInfo.rejected, (state, action) => {
				state.loading = false
				state.error = 'expired'
			})
			.addCase(changePass.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(changePass.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(changePass.rejected, (state, action) => {
				state.loading = false
				if (action.payload === 403) {
					state.error = '403'
				}
				if (action.payload === 400) {
					state.error = '400'
				}
				if (action.payload === 404) {
					state.error = '404'
				}
			})

			.addCase(getLoginCode.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(getLoginCode.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(getLoginCode.rejected, (state, action) => {
				state.loading = false
				if (action.payload === 403) {
					state.error = '403'
				}
				if (action.payload === 400) {
					state.error = '400'
				}
				if (action.payload === 404) {
					state.error = '404'
				}
			})

			.addCase(verifyLoginCode.pending, (state, action) => {
				state.error = null
				if (state.loading === false) {
					state.loading = true
				}
			})
			.addCase(verifyLoginCode.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(verifyLoginCode.rejected, (state, action) => {
				state.loading = false
				if (action.payload === 403) {
					state.error = '403'
				}
				if (action.payload === 400) {
					state.error = '400'
				}
				if (action.payload === 404) {
					state.error = '404'
				}
			})

			.addCase(onLoginByGoogle.pending, (state, action) => {
				state.error = null
				state.loading = true
			})
			.addCase(onLoginByGoogle.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(onLoginByGoogle.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(newRegistrationWithoutConfirm.pending, (state, action) => {
				state.error = null
				state.loading = true
			})
			.addCase(newRegistrationWithoutConfirm.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(newRegistrationWithoutConfirm.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})

			.addCase(newRegistrationConfirmation.pending, (state, action) => {
				state.error = null
				state.loading = true
			})
			.addCase(newRegistrationConfirmation.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(newRegistrationConfirmation.rejected, (state, action) => {
				state.loading = false
				state.error = null
			})

			.addCase(newRegistrationConfirmationResendMail.pending, (state, action) => {
				state.error = null
				state.loading = true
			})
			.addCase(newRegistrationConfirmationResendMail.fulfilled, (state, action) => {
				state.error = null
				state.loading = false
			})
			.addCase(newRegistrationConfirmationResendMail.rejected, (state, action) => {
				state.loading = false
				state.error = null
			})
	}

})

export const { logout, cleanError } = loginSlice.actions;

export default loginSlice.reducer