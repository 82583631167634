import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'uid';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { addToTeam, createTeam, deleteTeam, getTeams } from '../../redux/slices/TeamsSlice';
import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';

import Modals from './users/Modals';
import TeamsTablePR from './teams/TeamsTablePR';

import ContentModal from '../../UI/modal/ContentModal.tsx';
import AccessDenialOverlay from '../../UI/accessDenialOverlay/AccessDenialOverlay';
import Modal from '../../UI/modal/Modal';
import BackButton from '../../UI/backButton/BackButton';
import Button from '../../UI/Button/Button';
import HeaderPage from '../../UI/headerPage/HeaderPage';
import SearchInput from '../../UI/searchInput/SearchInput';
import Input from '../../UI/input/Input';

import cl from '../../styles/pages/team/Teams.module.css'
import MobileSearchInputWithClose from '../../UI/searchInput/MobileSearchInputWithClose';

const activeActions = [
	{ 'id': uid(8), 'icon': 'icon-delete', tooltip: 'Delete', 'action': 'deleteTeam', 'header': 'Delete' }
]

function TeamsPage() {
	const dispatch = useDispatch()
	const winWidth = useWindowWidth();
	const comID = useSelector(state => state.userInfo.info?.company)
	const verification = useSelector((state) => state.company?.info?.confirmed_email)
	const { teams, loading, error } = useSelector(state => state.teams)
	const [teamsInfo, setTeamsInfo] = useState([]);
	const [searchInput, setSearchInput] = useState('')
	const [createModal, setCreateModal] = useState(false)
	const [createInput, setCreateInput] = useState('')
	const [createInputError, setCreateInputError] = useState('')
	const [modals, setModals] = useState('')

	const onGetTeams = async () => {
		dispatch(getTeams(comID))
	}

	useEffect(() => {
		if (comID) {
			onGetTeams()
		}

	}, [comID])

	useEffect(() => {
		setTeamsInfo(teams)
	}, [teams])

	const onSearchChange = (e) => {
		setSearchInput(e.target.value)
	}
	//mobile

	const [searchOpen, setSearchOpen] = useState(false)
	const [filterOpen, setFilterOpen] = useState(false)
	const [checkedTeams, setCheckedTeams] = useState([])

	const closeSearchInp = () => {
		setSearchOpen(false)
		setSearchInput('')
	}
	const setSelectAction = async (action) => {
		switch (action) {
			case 'deleteTeam':
				setModals('deleteTeam')
				break;
			default:
				break;
		}
	}
	const onModalClose = () => {
		setModals('')
	}

	const onConfirm = () => {
		if (modals === 'deleteTeam') {
			onDeleteTeam()
		}
		setModals('')
	}

	const onDeleteTeam = async () => {
		let success = []
		let error = []
		const checkedTeamsNames = checkedTeams.map(item => item.groupName)
		for (let i = 0; i < checkedTeamsNames.length; i++) {
			const res = await dispatch(deleteTeam(checkedTeamsNames[i]))
			if (res.payload?.status === 'success') {
				success.push(checkedTeamsNames[i])
				toast.success(`Team ${checkedTeamsNames[i]} was deleted`)
			} else {
				error.push(checkedTeamsNames[i])
				toast.error(`Team ${checkedTeamsNames[i]} was not deleted`)
			}
		}

		const notDeletedTeams = teamsInfo.filter(item => error.includes(item.groupName)) || []

		setCheckedTeams(notDeletedTeams)
	}

	const onChangeCreateInput = (e) => {
		setCreateInput(e.target.value)
		setCreateInputError('')
	}

	const onCreateTeam = async () => {
		if (createInput.includes('/')) {
			setCreateInputError('Team name must not contain "/"')
			return
		}

		if (createInput) {
			const res = await dispatch(createTeam(createInput))
			if (res.payload.status === 'success') {
				const id = localStorage.getItem('id')
				const userArr = [id]
				await onAddToTeam(res?.meta?.arg, userArr)
			} else if (res.payload === 401) {
				setCreateInputError('Team already exist')
			} else if (res.payload === 402) {
				setCreateInputError('Permission denied')
			} else {
				setCreateInputError('Something went wrong')
			}
		} else if (createInput.length < 2) {
			setCreateInputError('Team name must be at least 2 characters')
		}
	}

	const onAddToTeam = async (groupName, userArr) => {
		const res = await dispatch(addToTeam({ groupName, userArr }))
		if (res.payload.status === 'success') {
			toast.success('Team created')
			setCreateInput('')
			setCreateModal(false)
		} else if (res.payload === 401) {
			toast.error('Team already exist') //!проверить коды в доке
		} else if (res.payload === 402) {
			toast.error('Permission denied')
		} else {
			toast.error('Team created, but user not added')
		}
	}


	const commonComponents = <>
		<TeamsTablePR
			activeActions={activeActions}
			loading={loading}
			checked={checkedTeams}
			setChecked={setCheckedTeams}
			setSelectAction={setSelectAction}
			data={teamsInfo}
			searchFilter={searchInput}
		/>

		<Modals
			ids={checkedTeams}
			actions={modals}
			onClose={onModalClose}
			onConfirm={onConfirm} />
		<Modal
			style={{ maxWidth: '90%', width: 440 }}
			modalIsOpen={createModal}
			setIsOpen={setCreateModal}>
			<ContentModal loading={loading} setModalIsOpen={setCreateModal} header='Create team' onConfirm={onCreateTeam} closeOnConfirm={false} textButton='Create' disabled={createInput.length < 2}>
				<div style={{ width: '100%', marginBottom: 37 }}>
					<Input label='Team name' value={createInput} onChange={onChangeCreateInput} err={createInputError} helperText={createInputError} />
				</div>
			</ContentModal>
		</Modal>
	</>

	return (
		winWidth > TABLET_WIDTH
			? <div className='page__container'>
				<div className={cl.header}>
					<div className={cl.create}>
						<HeaderPage style={{ margin: 0 }}>Teams</HeaderPage>
						<Button
							left
							icon='plus'
							onClick={() => setCreateModal(true)}
							disabled={!verification}>Create team</Button>
					</div>
				</div>
				{verification ? <>
					<div className={cl.searchInput}>
						<SearchInput value={searchInput} setValue={setSearchInput} onChange={onSearchChange} placeholder='Search team' />
					</div>
					{commonComponents}
				</>
					: <AccessDenialOverlay />}

			</div>
			: <div className='page__container'>
				<div className={cl.header}>
					{searchOpen
						? <MobileSearchInputWithClose value={searchInput} setValue={setSearchInput} onClose={closeSearchInp} onSearchChange={onSearchChange} placeholder='Search team' />
						: <>
							<div><BackButton title='Teams' /></div>
							<div className={cl.actions}>
								<i className={classNames('icon-plus', cl.create__btn)} onClick={() => setCreateModal(true)} />
								<i className='icon-search' onClick={() => setSearchOpen(true)} />
							</div>
						</>
					}
				</div>

				{verification ?
					commonComponents
					: <AccessDenialOverlay />}
			</div>
	)
}

export default TeamsPage