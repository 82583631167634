import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { VRService } from '../../services/vrServices';

export const getTrainings = createAsyncThunk(
	'vr/getTrainings',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, userID } = data
		try {
			const res = await VRService.getTrainings(token, comID, userID)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTrainingsAvailableForCompany = createAsyncThunk(
	'vr/getTrainingsAvailableForCompany',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID } = data
		try {
			const res = await VRService.getTrainingsAvailableForCompany(token, comID)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTrainingsByGroup = createAsyncThunk(
	'vr/getTrainingsByGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, groupName } = data
		try {
			const res = await VRService.getTrainingsListByGroup(token, comID, groupName)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const addTrainingsToGroup = createAsyncThunk(
	'vr/addTrainingsToGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		const { groupName, contentIdArr } = data
		try {
			const res = await VRService.addTrainingsToGroup(token, userId, groupName, contentIdArr)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const addTrainingsToUser = createAsyncThunk(
	'vr/addTrainingsToGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, contentIdArr, userId } = data
		try {
			const res = await VRService.addTrainingsToUser(token, comID, userId, contentIdArr)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteTrainingsFromGroup = createAsyncThunk(
	'vr/deleteTrainingsFromGroup',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const userId = localStorage.getItem('id')
		const { groupName, contentIdArr } = data
		try {
			const res = await VRService.deleteTrainingsFromGroup(token, userId, groupName, contentIdArr)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteTrainingFromUser = createAsyncThunk(
	'vr/deleteTrainingFromUser',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		const { comID, userID, vrTraining } = data
		try {
			const res = await VRService.deleteTrainingFromUser(token, comID, userID, vrTraining)
				;
			return res.data
		} catch (e) {
			;
			return rejectWithValue(e.response.status)
		}
	}
)

export const getTrainingsByPlan = createAsyncThunk(
	'vr/getTrainingsByPlan',
	async (data, { rejectWithValue }) => {
		try {
			const res = await VRService.getTrainingsByPlans()
			return res.data
		} catch (e) {

			return rejectWithValue(e.response.status)
		}
	}
)

export const getTrainingInfo = createAsyncThunk(
	'vr/getTrainingInfo',
	async (trainingId, { rejectWithValue }) => {
		const token = localStorage.getItem('token')
		try {
			const res = await VRService.getTrainingInfo(token, trainingId)
			return res.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const vrAdapter = createEntityAdapter({
	selectId: (vr) => vr.id,
});

const vrSlice = createSlice({
	name: 'vr',
	initialState: vrAdapter.getInitialState({
		loading: false,
		error: null,
		trainings: null,
		trainingsByPlan: null,
		flexTrainings: null,
		sortedTrainings: null,
		trainingInfo: null,
	}),
	reducers: {
		sortTrainings: (state, action) => {
			let newTrainingsByPlan = state.trainingsByPlan.map((item) => {
				if (item.plan === 'Free' || item.plan === 'Exclusive' || action.payload === item.plan) {
					return {
						...item,
						check: true
					}
				} else {
					return {
						...item,
						check: false
					}
				}
			})

			let mergedTrainings = [...newTrainingsByPlan];
			let updatedArray

			if (action.payload === 'Flex') {
				let paidTrain = state.trainings?.map((obj) => {
					return {
						...obj,
						plan: obj.plan.charAt(0).toUpperCase() + obj.plan.slice(1) || 'Flex'
					}
				})

				const combinedArray = [...paidTrain, ...mergedTrainings].reduce((acc, current) => {
					const x = acc.find(item => item.id === current.id);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, []);

				updatedArray = combinedArray

			} else if (action.payload === 'Standard') {
				const excl = state.trainings?.filter(obj => obj.plan === 'exclusive')
					.map(obj => ({
						...obj,
						plan: 'Exclusive'
					})) || [];
				const combinedArray = [...excl, ...mergedTrainings].reduce((acc, current) => {
					const x = acc.find(item => item.id === current.id);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, []);
				//const idCountMap = new Map();

				//combinedArray.forEach(obj => {
				//	idCountMap.set(obj.id, (idCountMap.get(obj.id) || 0) + 1);
				//});

				//const uniqueObjectsArray = combinedArray.filter(obj => idCountMap.get(obj.id) === 1 && obj.id !== '101').map(obj => (
				//	{...obj,
				//	plan: 'Exclusive'}
				//));
				//updatedArray = [...mergedTrainings, ...uniqueObjectsArray]
				updatedArray = combinedArray

			} else {
				let exclusives = state.trainings?.map(obj => (
					{
						...obj,
						plan: 'Exclusive'
					}
				)) || []

				const combinedArray = [...exclusives, ...mergedTrainings];

				const idCountMap = new Map();

				combinedArray.forEach(obj => {
					idCountMap.set(obj.id, (idCountMap.get(obj.id) || 0) + 1);
				});

				const uniqueObjectsArray = combinedArray.reduce((acc, current) => {
					const x = acc.find(item => item.id === current.id);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, [])

				updatedArray = uniqueObjectsArray
			}


			mergedTrainings = updatedArray.map(obj => (
				((action.payload === 'Free' && obj.plan === 'Standard') || (action.payload === 'Flex' && obj.plan === 'Standard')) ? {
					...obj,
					check: false
				} : {
					...obj,
					check: true,
					plan: obj.plan || 'Flex'
				}
			))

			state.sortedTrainings = mergedTrainings;
		},
		setTrainingInfo: (state, action) => {
			state.trainingInfo = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTrainings.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainings.fulfilled, (state, action) => {
				state.loading = false;
				state.trainings = action.payload.data;
			})
			.addCase(getTrainings.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getTrainingsByGroup.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingsByGroup.fulfilled, (state, action) => {
				state.loading = false;
				state.trainings = action.payload.data;
			})
			.addCase(getTrainingsByGroup.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(addTrainingsToGroup.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(addTrainingsToGroup.fulfilled, (state, action) => {
				state.loading = false;
				;
				//state.trainings = action.payload.data;
			})
			.addCase(addTrainingsToGroup.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(deleteTrainingsFromGroup.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(deleteTrainingsFromGroup.fulfilled, (state, action) => {
				state.loading = false;
				;
				//state.trainings = action.payload.data;
			})
			.addCase(deleteTrainingsFromGroup.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(deleteTrainingFromUser.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(deleteTrainingFromUser.fulfilled, (state, action) => {
				state.loading = false;
				;
				//state.trainings = action.payload.data;
			})
			.addCase(deleteTrainingFromUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingsByPlan.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingsByPlan.fulfilled, (state, action) => {
				state.loading = false;

				let feeder = {
					"poster": "",
					"id": "101",
					"name": "Feeder Protection Relay Training",
					"company": "metaenga",
					"size": "",
					"fullname": "",
					"description": "Through this VR training, attendees will be given functionality descriptions, input, and output devices and indicators, setting parameters, and technical data sorted per function. Attendees can explore the entire menu tree, configure the primary current value.",
					"duration": "15 min",
					"version": "01",
					"features": "Single-player, Voiceover guidance",
					"audience": "High School Students, Vocational School and College Students, Entry-Level Job Seekers, Career Changers, Employee Onboarding, Existing Warehouse Workers, Workforce Development Programs",
					"modes": "Single user guided tour",
					"assessment": "No assessment",
					"available": "Quest 1, Quest 2, Quest 3, Quest Pro, Pico 4",
					"lang": "English",
					"area": "5500",
					"createdAt": "",
					"plan": 'Free'
				}
				let free = action.payload.data[0].training.map((item) => (
					{
						...item,
						plan: 'Free'
					}
				))
				let standard = action.payload.data[1]?.training.map((item) => (
					{
						...item,
						plan: 'Standard'
					}
				))
				let premium = action.payload.data[2]?.training.map((item) => (
					{
						...item,
						plan: 'Premium'
					}
				))

				let all = [feeder, ...free, ...standard, ...premium]


				state.trainingsByPlan = all;
			})
			.addCase(getTrainingsByPlan.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingInfo.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.trainingInfo = action.payload.data;
			})
			.addCase(getTrainingInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingsAvailableForCompany.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingsAvailableForCompany.fulfilled, (state, action) => {
				state.loading = false;
				const data = action.payload.data.filter(item => item.type === 'Flex');
				state.flexTrainings = data.map(item => item.name)
			})
			.addCase(getTrainingsAvailableForCompany.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
	}
});

export const { sortTrainings, setTrainingInfo } = vrSlice.actions;

export default vrSlice.reducer