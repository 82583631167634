import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { uid } from 'uid';

import cl from './RadioBtn.module.css';

interface RadioBtnProps extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  value: string | number;
  children: React.ReactNode;
}

const RadioBtn: React.FC<RadioBtnProps> = ({
  checked,
  onChange,
  disabled = false,
  error = false,
  children,
  value,
  className,
  ...props
}) => {
  const id = uid(8);

  return (
    <div className={classNames(cl.row, className)} {...props}>
      <div className={cl.box}>
        <input
          value={value}
          className={classNames(cl.checkbox, error && cl.error)}
          id={id}
          type="radio"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <label className={classNames(cl.label, { [cl.disabled]: disabled })} htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

export default RadioBtn;
