import React, { useEffect, useState } from 'react';
import cl from './SimpleCompanyLogo.module.css';
import { Paragraph } from '../Typography/Typography';
import Spinner from '../spinner/Spinner';

interface SimpleCompanyLogoProps {
  companyName?: string;
  logo?: string;
}

const SimpleCompanyLogo: React.FC<SimpleCompanyLogoProps> = ({ companyName = '', logo = '' }) => {
  const [shortName, setShortName] = useState<string>('');

  useEffect(() => {
    if (!logo) {
      const initials = companyName.split(' ').map(word => word[0]); // Масив перших літер
      const short = initials.length > 2 
        ? initials[0] + initials[initials.length - 1] 
        : initials.join('');
      setShortName(short.toUpperCase());
    }
  }, [companyName, logo]);

  const logoLink = logo ? `${process.env.REACT_APP_MAIN_API_URL}/logo/${logo}` : '';

  return (
    <div className={cl.logo__wrapper}>
      <div className={cl.logo__container}>
        <div className={cl.logo__img}>
          {logo ? (
            logoLink ? (
              <img src={logoLink} alt="logo" />
            ) : (
              <Spinner />
            )
          ) : shortName ? (
            <Paragraph>{shortName}</Paragraph>
          ) : (
            <Spinner width={25} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleCompanyLogo;
