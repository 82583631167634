import axios from "axios";

export class VideosService {

	static getVideosByUser(token, comID, userID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/video/list/byUser/${comID}/${userID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getVideosByOwner(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/video/metaenga/byOwner/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getVideosByGroup(token, comID, groupName) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/video/list/byGroup/${comID}/${groupName}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static getVideosByUserAndGroup(token, comID, userID, groupName) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/video/list/combined/${comID}/${userID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static getVideoLink(token, company, serverName, resolution) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/download/${company}/${serverName}/${resolution}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static downloadVideo(videoId, url, controller, setProgressBar) {
		return axios.get(url, {
			onDownloadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				setProgressBar(old => ({
					...old,
					[videoId]: percentCompleted,
				}))
			},
			responseType: 'blob',
			signal: controller.signal
		})
	}

	static deleteVideosFromGroup(token, userId, groupName, contentIdArr) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteAccessFromGroup`, {
			userId,
			groupName,
			contentIdArr
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static uploadVideo(comID, formData, onProgress) {
		const token = localStorage.getItem('token')
			;
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/uploadBunny/${comID}`, formData, {
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				onProgress(percentCompleted)
			}
		})
	}

	static deleteClientVideo(token, adminId, companyId, videoId) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteVideo`, {
			videoId,
			adminId,
			companyId
		}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static sendStatusDownloadVideo() {
		const token = localStorage.getItem('token')

		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/stats/download/web`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})


	}
}