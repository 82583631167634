import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchRegistrationInfo, logining, registration } from '../redux/slices/LoginSlice';

import PasswordForm from '../components/forms/PasswordForm';
import InviteNotFound from '../components/forms/InviteNotFound';
import LoginHeader from '../components/login/LoginHeader';
import UserInfo from '../components/login/UserInfo';

import { TextError } from '../UI/Typography/Typography'
import SpinnerBlock from '../UI/spinner/SpinnerBlock';

import cl from '../styles/pages/Signup.module.css'
import { openMainDB } from '../db/mainDB';
import { setInfo } from '../redux/slices/UserInfoSlice';

function Signup() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const loginState = useSelector((state) => state.login)
	const { loading, error } = loginState

	const [regInfo, setRegInfo] = useState(null)
	const [overlayLoading, setOverlayLoading] = useState(false)
	const [notfound, setNotfound] = useState(false)
	const [searchParams] = useSearchParams()

	useEffect(() => {
		localStorage.removeItem('token')
		localStorage.removeItem('id')
		localStorage.removeItem('email')
	}, [])


	const getRegInfo = async (token) => {

		const res = await dispatch(fetchRegistrationInfo(token))
		if (res?.payload?.data) {
			setRegInfo({ ...res?.payload?.data, companyName: res?.payload?.companyName })
		} else {
			setNotfound(true)
		}
		setOverlayLoading(false)
	}

	useEffect(() => {
		setOverlayLoading(true)
		const token = searchParams.get('token')
		if (token) {
			getRegInfo(token)
		} else {
			navigate('/')
			setOverlayLoading(false)
		}
	}, [])

	const [password, setPassword] = useState('')
	const [repeatPass, setRepeatPass] = useState('')

	const sendLogin = async () => {
		const res = await dispatch(logining({ login: regInfo.email, password }))
		if (res?.payload?.status === 200 || res?.payload?.status === 'success') {
			;
			toast.success('Registration success')
			await openMainDB()
			await dispatch(setInfo(res.payload))
			setTimeout(() => {
				setOverlayLoading(false)
				navigate('/home/vr?hello=true')
			}, 1000)
		} else {
			navigate('/')
			setOverlayLoading(false)
		}
	}

	const sendRegInfo = async (data) => {
		const { setErrors, resetForm } = data
		const res = await dispatch(registration({ regInfo, password }))
		if (res?.payload?.status === 'success') {
			setOverlayLoading(true)
			sendLogin()
		} else {
			if (res?.payload === 400) {
				setErrors({ pass: 'Something went wrong', repeatPass: 'Something went wrong' })
				toast.error('Something went wrong')
				return
			} else if (res?.payload === 402) {
				return
			} else {
				setErrors({ pass: 'Something went wrong', repeatPass: 'Something went wrong' })
				toast.error('Something went wrong')
			}
		}
	}

	const regForm = <>
		<UserInfo info={regInfo} />
		<PasswordForm
			policy
			regInfo={regInfo}
			password={password}
			setPassword={setPassword}
			repeatPass={repeatPass}
			setRepeatPass={setRepeatPass}
			onSend={sendRegInfo}
			sending={loading}
		/>
	</>

	const notFound = <InviteNotFound />

	const loadingBlock = <SpinnerBlock height={250} />

	return (
		<LoginHeader header='Sign up'>
			{(loading || overlayLoading) && loadingBlock}
			{regInfo && !loading && !notfound && !overlayLoading && regForm}
			{error && error === 'expired'
				? notFound
				: <div className={cl.error}>
					<TextError>{error}</TextError>
				</div>
			}
		</LoginHeader>
	)
}

export default Signup