import axios from "axios";

export class UserInfoService {
	static getInfo(token, id) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getUserInfo/${id}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static editInfo(token, data) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/editUsers`,
			{
				...data
			}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static uploadAvatar(token, id, data) {
		const formdata = new FormData();
		formdata.append("file", data);
		return axios({
			method: 'post',
			url: process.env.REACT_APP_MAIN_API_URL + `/upload/avatar/${id}`,
			data: formdata,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}
		})
	}
	
	static deleteAvatar(id, token) {
		return axios.delete(process.env.REACT_APP_MAIN_API_URL + `/deleteAvatar/${id}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}