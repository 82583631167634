export const sortArray = (itemsArray, sortingArr, sortValue) => {
	const sortedArray = itemsArray.sort((a, b) => {
		if (sortValue) {
			 let res =  sortingArr.indexOf(a[sortValue]) - sortingArr.indexOf(b[sortValue]);
			 return res;
		}
		return sortingArr.indexOf(a) - sortingArr.indexOf(b);
	});
	return sortedArray;
};
